import React from 'react';
import {toggleMedia, setGlobal, getGlobal, setMeta, getMeta, applyMeta, getHistory} from 'launchpad';
import { Input } from 'widgets'


export class Meta extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: '',
      initialized: false
    }
  }

  componentDidMount() {
    this.checkValue()
  }

  componentDidUpdate() {
    this.checkValue()
  }

  location = null

  checkValue() {
    if(getGlobal('pageContext') != this.location){
      this.location = getGlobal('pageContext')
      this.setState({initialized: false, value: ''}, this.checkValue())
    }
    let meta = getMeta(getGlobal('pageContext'), this.props.name)
    if(!this.state.value && meta.value && !this.state.initialized){
      this.setState({value: meta.value, initialized: true})
    }
  }

  update(val) {
    this.setState({value: val}, () => {
      setMeta(getGlobal('pageContext'), this.props.name, this.state.value)
    });
  }

  render() {
    return (
      <div className='setting'>
        <Input label={this.props.name}
          onChange={(e, val) => this.update(val)}
          value={this.state.value} />
      </div>
    )
  }
}

export class MetaEditor extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    applyMeta()
    let history = getHistory()
    if(history){
      history.listen((location, action) => {
        this.forceUpdate()
      });
    }
  }

  render() {
    return(
      <div className='meta-editor' key={window.location.pathname}>
        <p>Meta info for {window.location.pathname}</p>
        <Meta page={window.location.pathname} name='title'/>
        <Meta page={window.location.pathname} name='description'/>
      </div>
    )
  }
}
