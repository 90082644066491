import React from 'react';
import {firebase, getGlobal, setGlobal, loadGlobal, createDoc, Spinner,
  updateDoc, deleteDoc, Modal, Collapsible, apiPost, helpers } from 'launchpad';
import _ from 'lodash';
import styled from 'styled-components';
import Pica from 'pica/dist/pica.js'


let loaders = []
let loadIndicator = null

export const startLoad = () => {
  const ts = (new Date).getTime()
  loaders.push(ts)
  if(loadIndicator) loadIndicator.forceUpdate()
  return ts
}

export const stopLoad = (ts) => {
  loaders = loaders.filter(x => x != ts)
  if(loadIndicator) loadIndicator.forceUpdate()
}

const LoadContainer = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 300px;
  width: 300px;
  color: white;
  transition: opacity .3s;
  background: linear-gradient(215deg, rgba(240,249,255,0) 37%, rgba(203,235,255,0.22) 56%, rgba(2,1,1,0.7) 97%);
  .fa-spinner {
    position: absolute;
    bottom: 30px;
    left: 30px;
    font-size: 40px;
  }
`

export class LoadIndicator extends React.Component {
  componentDidMount = () => loadIndicator = this
  render = () => <LoadContainer className='load-container' style={{opacity: loaders.length ? 1 : 0}}><Spinner /></LoadContainer>
}




//TODO: some of these sections are getting big enough to justify their own files

/***************************************************
  UPLOAD HELPERS
****************************************************/

const pica = Pica();


export function getThumbUrl(src){
  let urlparts = src.split('.');
  let ext = urlparts.slice(-1)[0];
  if(ext == 'svg'){
    return src;
  }
  return urlparts.slice(0, -1).join('.') + '.thumbnail.' + ext
}

export function titleCase(string){
  return _.startCase(_.toLower(string));
}
const DEFAULT_FILTER_FOR_FILE_UPLOADER = '.png,.jpg,.jpeg,.svg';
const PPI_UPLOADING_IMAGE_RESTRICTION = 75; //75 pixels per inch
export class ImageUploader extends React.Component {

  constructor() {
    super();
    this.state = {options: {}, fileFilter: DEFAULT_FILTER_FOR_FILE_UPLOADER};
  }

  componentDidMount() {
    setGlobal({ImageUploaderComponent: this})
  }

  upload = (cb, options, fileFilter) => {
    options = options || {};
    this.maxSize = options.maxSize;
    this.cb = cb;
    const {ppiConstraints, ...newOptions} = options;
    let newStateValue = {options: newOptions, ppiConstraints: ppiConstraints};
    if (fileFilter)
      newStateValue['fileFilter'] = fileFilter;
    else
      newStateValue['fileFilter'] = DEFAULT_FILTER_FOR_FILE_UPLOADER;
    this.setState(newStateValue, () => this.fileInput.click());
  }

  submit = (extension, base64, metaData = {}) => {
    const options = this.state.options || {}
    if(options.skipUpload) {
      this.cb(base64)
      return
    }
    const l = startLoad()
    apiPost('/upload', {extension, base64, options: this.state.options}).then(data => {
      this.fileInput.value = '';
      data.height = metaData.height;
      data.width = metaData.width;
      this.cb(data);
    }).finally(()=>stopLoad(l))
  }

  readImage = e => {
    let extension = 'png'
    if(this.cb) {
      const file = e.target.files[0]
      extension = file.name.split('.').pop().toLowerCase()
      if (!this.state.fileFilter.split(',').includes('.' + extension)){
        this.cb({error: `You can upload ${this.state.fileFilter.split(',').join(', ')} files only!`})
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if(!this.canvas) this.canvas = document.createElement('canvas');
        this.img.onload = () => {
          const {height, width} = this.img;
          if (this.maxSize) {
            const maxSize = this.maxSize;
            if (height > maxSize || width > maxSize) {
              const landscape = width > height;
              const ratio = landscape ? height / width : width / height;
              this.canvas.width = landscape ? maxSize : maxSize * ratio;
              this.canvas.height = landscape ? maxSize * ratio : maxSize;
            } else {
              this.canvas.width = width;
              this.canvas.height = height;
            }
            pica.resize(this.img, this.canvas).then(() => {
              const selectedImg = this.canvas.toDataURL('image/' + extension);
              //this.preview.src = selectedImg
              this.submit(extension, selectedImg);
            }).catch(err => {
              this.cb({error: err});
              console.log(`Upload Error`, err);
            })
          }
          if (this.state.ppiConstraints){
            const {imageHeight, imageWidth} = this.state.ppiConstraints;
            const ppiHeight = height/imageHeight;
            const ppiWidth = width/imageWidth;
            if (ppiHeight < PPI_UPLOADING_IMAGE_RESTRICTION || ppiWidth < PPI_UPLOADING_IMAGE_RESTRICTION) {
              this.fileInput.value = '';
              this.cb({error: `Please upload a higher resolution photo. The photo should be at least ${PPI_UPLOADING_IMAGE_RESTRICTION}ppi.`});
            } else
              this.submit(extension, this.img.src, {height, width});
          }
        }
        if(this.maxSize || this.state.ppiConstraints){
          this.img.src = reader.result;
        } else {
          this.submit(extension, reader.result);
        }
      }, false)

      if(file){
        reader.readAsDataURL(file)
      }
    }
  }

  render() {
    return <div className='image-processor' style={{display: 'none'}}>
      <div className='image'>
        <input ref={i => this.fileInput = i}
          type='file'
          onChange={this.readImage}
          onBlur={()=>this.cb({error: 'Upload canceled'})}
          accept={this.state.fileFilter}
        />
        <img style={{display: 'none'}} ref={img=>this.img = img} />
      </div>
    </div>
  }
}

export const getUpload = (cb, options, fileFilter) => {
  const iu = getGlobal("ImageUploaderComponent")
  if(!iu) {
    console.log('no uploader found, please include the <ImageUploader /> component')
    return
  }
  iu.upload(cb, options, fileFilter)
}

export const asyncUpload = (options) => {
  return new Promise((resolve, reject) => {
    getUpload(data => resolve(data.url), options)
  })
}

export const asyncUploadWithThrow = (options, fileFilter) => {
  return new Promise((resolve, reject) => {
    getUpload(data => {
      if (data.error) {
        reject(data.error);
      } else {
        resolve(data);
      }
    }, options, fileFilter)
  })
}

export const getBase64 = (options) => {
  return new Promise((resolve, reject) => {
    getUpload((data) => resolve(data), Object.assign({}, options, {skipUpload: true}))
  })
}


/***************************************************
  END UPLOAD HELPERS
****************************************************/



/***************************************************
  CONFIRM MODAL
****************************************************/

export const ConfirmModal = (props) => {
  return(
    <Modal id='confirm' open={getGlobal('confirmModal')} onClose={() => setGlobal({confirmAcceptText: '', confirmModal: false})} className='confirm'>
      {getGlobal('confirmMessage')}
      <div className='confirm'>
        <button onClick={() => setGlobal({confirmModal: false})}>Cancel</button>
        <button className='danger'
          onClick={() => {getGlobal('confirmCallback')(); setGlobal({confirmModal: false})}}
          > {getGlobal('confirmAcceptText') || 'Accept'} </button>
      </div>
    </Modal>
  )
}

export function confirmDelete(collection, id, cb) {
  confirm('Are you sure you want to delete this?', () => {
    deleteDoc(collection, id).then(() => {
      if(typeof cb === 'function'){
        cb();
      }
      loadGlobal(collection)
    });
  }, 'Delete')
}

export function confirm(message, callback, accept){
  setGlobal({
    confirmModal: true,
    confirmMessage: message,
    confirmCallback: callback,
    confirmAcceptText: accept
  });
}

/***************************************************
  END CONFIRM MODAL
****************************************************/



/***************************************************
  NOTIFICATIONS
****************************************************/

const Notifications = styled.div`
  position: fixed;
  bottom: 30px;
  right: 0;
  z-index: 999999;
  ${'' /* pointer-events: none; */}
  max-height: calc(100vh - 30px);
  overflow: hidden;
  pointer-events: none;

  .notification {
    position: relative;
    width: 600px;
    padding-left: 300px;
    right: -600px;
    transition: all .5s;
    overflow: hidden;

    &.active {
      right: 30px;
      .notification{
        right: 30px;
      }
    }
    .notification-wrapper {
      background: rgba(255,255,255,.95);
      border: 1px solid #eee;
      border-radius: 3px;
      box-shadow: 2px 2px 5px -1px rgba(0,0,0,.2);
      padding-left: 0px;
      width: 300px;
      margin-bottom: 10px;
      position: relative;
      .message {
        padding: 20px;
        padding-left: 50px;
      }
      .icon {
        position: absolute;
        top:0;
        left:0;
        width: 30px;
        font-size: 18px;
        height: 100%;

        opacity: .15;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.success-type {
        background: rgba(30, 210, 100, .9);
        border: 1px solid rgba(30, 210, 100, .9);
        font-weight: bold;
        color: white;
      }
      &.warning-type {
        background: rgba(255, 230, 90, .9);
        border: 1px solid rgba(255, 230, 90, .9);
        font-weight: bold;
      }
      &.error-type {
        background: rgba(215, 50, 10, .9);
        border: 1px solid rgba(215, 50, 10, .9);
        color: white;
        font-weight: bold;
      }
    }
  }
`


let notificationCenter = null;

export class NotificationContainer extends React.Component {
  state = {
    messages: [],
    activeMessages: []
  }

  componentDidMount() {
    notificationCenter = this;
  }

  addNotification = (n) => {
    if(n) {
      this.setState({messages: [n].concat(this.state.messages)})
      const delay = n.options.delay * 1000 || 0
      setTimeout(() => this.activateMessage(n), delay)
      const duration = (n.options.duration * 1000 || Math.max(n.message.length * 80, 5000)) + delay
      setTimeout(() => this.hideMessage(n), duration)
      setTimeout(() => this.removeMessage(n), duration + 200)
    }
  }

  activateMessage = (n) => {
    this.setState({activeMessages: [n.id].concat(this.state.activeMessages)})
  }

  hideMessage = (n) => {
    this.setState({
      activeMessages: this.state.activeMessages.filter(id => id != n.id)
    })
  }

  removeMessage = (n) => {
    this.setState({
      messages: this.state.messages.filter(e => e.id != n.id)
    })
  }

  render() {
    return <Notifications className='notifications-container'>
      {this.state.messages.map(n => {
        const icons = {notification: 'info', warning: 'warning', error: 'ban', success: 'check'}
        const open = this.state.activeMessages.includes(n.id)
        return <Collapsible open={open} key={n.id} className={`notification` + (open ? ' active' : '')}
        >
          <div  className={`notification-wrapper ${n.options.type}-type`} onClick={()=>this.hideMessage(n)}>
            <div className='icon'><span className={`fa fa-${icons[n.options.type]}`} /></div>
            <div className='message' role='alert'>{n.message}</div>
          </div>
        </Collapsible>
      })}
    </Notifications>
  }
}

let notifID = 0
let lastNotifications = []
let notifications = []
let notTimer = null
export const notify = (msg, options) => {
  clearTimeout(notTimer)
  if(!lastNotifications.includes(msg)) {
    lastNotifications.push(msg)
    notifID+=1;
    if(notificationCenter) {
      notifications.push({id: notifID, message: msg, options: Object.assign({type: 'notification'}, options || {})})
    }
    // prevent the same notification from being delivered more than once per second
    setTimeout(() => lastNotifications = lastNotifications.filter(x => x != msg), 1000)
  }
  notTimer = setTimeout(() => {
    notifications.forEach((n, idx) => {
      setTimeout(() => notificationCenter.addNotification(n), idx * 50)
    })
    notifications = []
  }, 100)
}


/***************************************************
  END NOTIFICATIONS
****************************************************/







/***************************************************
  VALIDATION
****************************************************/

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validate = (value, options) => {
  if(value.checkbox) return {error: false}
  options = options || {}
  let ret = {error: false}

  if(typeof value != 'object'){
    value = {text: value}
  }

  for(let entry of Object.entries(value)){
    const v = entry[1]
    if(options.min && v.length < min) ret.error = `must be at least ${min} characters`
    if(options.max && v.length > max) ret.error = `must be less than ${max} characters`
    if(!v) ret.error = `must not be empty`
  }
  if(value.password && value.password.length < 6) ret.error =`must be at least 6 characters`
  if(value.email && !emailRegex.test(value.email)) ret.error = `does not appear to be a valid email address`
  if(value.number && isNaN(parseFloat(value.number))) ret.error = `must be numeric`

  return ret
}

const ValidatorContainer = styled.form`
  .validator-error {
    border-color: red !important;
    >div {
      border-color: red !important;
    }
  }
`

let blurListeners = {}

export class Validator extends React.Component {
  state = {validating: false, checked: [], errored: [], blockUpdates: []}

  validate = async () => {
    return new Promise((resolve, reject) => {
      this.setState({validating: true, checked: [], errored: []}, () => {
        resolve(this.checkChildren())
      })
    })
  }

  componentDidUpdate = () => {
    this.checkChildren()
  }

  messages = []
  notTimer = null

  notify = (n) => {
    clearTimeout(notTimer)
    const showNotifications = () => {
      this.messages.forEach(x => {
        notify(x)
      })
      this.messages = []
    }
    let emptyChecked = this.messages.find(x => x.includes('all required fields'))
    const isEmpty = n.includes('must not be empty')
    if(!emptyChecked){
      for(let msg of this.messages) {
        if(isEmpty && msg.includes('must not be empty')) {
          this.messages = this.messages.filter(x => !x.includes('must not be empty'))
          this.messages.push('Please fill out all required fields')
          emptyChecked = true
        }
      }
    }

    if(!(emptyChecked && isEmpty)) this.messages.push(n)
    this.notTimer = setTimeout(showNotifications, 100)
  }

  checkChildren = (options) => {
    options = Object.assign({notify: true}, options || {})
    let errors = false
    let {checked, errored, blockUpdates, editing} = this.state

    if(this.state.validating){
      let newChecks = []
      let newErrors = []
      let newValids = []

      Object.keys(this.inputs).forEach(key => {
        const input = this.inputs[key]
        const i = input.component
        const cn = (i.props && i.props.className) || ''
        if(i && !checked.includes(key) && !cn.includes('optional')) {
          newChecks.push(key)
          let v = (i.props && i.props.value) || this.values[i]
          v = (v && v.value !== undefined ) ? v.value : v
          const type = (i.props && i.props.type) || i.type || 'text'
          const valid = validate({[type]: v})
          if(valid.error !== false){
            errors = true
            if(editing != key) this.notify(`${input.label} ${valid.error}`)
            if(!errored.includes(key)){
              newErrors.push(key)
            }
          } else {
            if(errored.includes(key)){
              newValids.push(key)
            }
          }
        }
      })

      const newErrored = errored.concat(newErrors).filter(x => !newValids.includes(x))
      if(newChecks.length > 0 || newErrors.length > 0 || newValids.length > 0) {
        this.setState({checked: checked.concat(newChecks), errored: newErrored})
      }

      return newErrored.length == 0
    }

    return errored.length == 0
  }

  updateValue = (el, key, e) => {
    let v = e.target ? e.target.value: e
    v = v.value || v
    this.values[key] = v
  }

  inputs = {}
  values = {}

  render = () => {
    const {checked, errored, editing} = this.state
    return <ValidatorContainer {...this.props} innerRef={c => this.container = c}>
      {React.Children.map(this.props.children, (child, index) => {
        if(!child) return
        const p = (child.props) || {}
        let errored = false
        let onChange = p.onChange
        let onBlur = p.onBlur
        let onKeyUp = p.onKeyUp
        let onFocus = p.onFocus
        if(p.onChange) {
          const label = p.placeholder || p.name || 'Field'
          const key = index+label
          errored = this.state.errored.includes(key)
          this.inputs[key] = {
            component: child,
            key,
            label
          }
          onChange = (...args) => {
            this.updateValue(child, key, ...args)
            p.onChange(...args)
          }
          onBlur = (...args) => {
            if(this.state.editing = key) this.setState({editing: null})
            this.setState({checked: checked.filter(x => x != key)})
            if(p.onBlur) p.onBlur(...args)
          }
          onFocus = (...args) => {
            this.setState({editing: key})
          }
          onKeyUp = (...args) => {
            this.setState({checked: checked.filter(x => x != key)}, () => this.checkChildren())
            if(p.onKeyUp) p.onKeyUp(...args)
          }
        }
        return React.cloneElement(child, {
          index,
          onChange,
          className: (p.className || '') + (errored ? ' validator-error' : ''),
          onBlur,
          onFocus,
          onKeyUp
        })
      })}
    </ValidatorContainer>
  }
}

/***************************************************
  END VALIDATION
****************************************************/




export function updateDB(collection, key, entry, options){
  let col = getGlobal(collection);
  let existing = col.find(d => d._id == key);
  let operation = null
  if(existing){
    operation = updateDoc(collection, Object.assign({_id: existing._id, slug: existing.slug}, entry));
  } else {
    operation = createDoc(collection, entry);
  }

  operation.then(() => {
    if(!options.skipReload){
      loadGlobal(collection);
    }
    if(typeof options.complete === 'function'){
      options.complete();
    }
  })
}

let debounceTimer = null;
let updated = {};

// debounced updates (consolidates rapid updates to the same object to one request)
export function updateDBAttributes(collection, key, atts, options) {
  if(!updated[collection]){
    updated[collection] = {}
  }
  let item = updated[collection][key];
  if(typeof item === 'undefined' && getGlobal(collection)){
    item = getGlobal(collection)[key];
  }
  if(!item){
    item = {}
  }
  for(let key in atts){
    item[key] = atts[key]
  }
  updated[collection][key] = item;

  if(debounceTimer){
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(() => {
    // push all updates
    for(let col in updated){
      for(let key in updated[col]){
        updateDB(col, key, updated[col][key], {skipReload: options.skipReload});
        delete updated[col][key]
      }
    }
  }, 500)
}


// lookup item in collection by given attribute/value
export function getBy(collection, attr, val){
  let col = (getGlobal(collection) || []);
  return col.find(d => d[attr] == val);
}

// lookup item in collection by its slug
export function getBySlug(collection, slug, options){
  if(options.exceptions && options.exceptions.includes(slug)) return false
  return getBy(collection, 'slug', slug)
}


// get slug that's unique for given collection based on given string (title)
export function getSlug(title, collection,  options) {
  collection = collection || []
  options = options || {}
  let slug = title.trim().replace(/ /g, '-').replace(/[^a-zA-Z_0-9\-]/g, "")

  if(options.skipValidation) return slug

  slug = slug.toLowerCase()

  let unique = false;
  let increment = 1;

  // try appending _2, _3, etc until slug is unique
  while(unique == false){
    let test = slug
    let existing = false
    if(increment > 1){
      test = slug + '-' + increment;
    }
    if(collection[0] && typeof collection[0] == 'string'){
      existing = collection.map(l => l.toLowerCase()).includes(test) && !(options.exceptions && options.exceptions.includes(test))

    } else {
      existing = getBySlug(collection, test, options)
    }
    if(existing){
      increment++;
    } else {
      unique = true;
      return test;
    }
  }
}

// if(module.hot) module.hot.accept()
