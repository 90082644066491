import React from 'react';
import { Snip, Image, ImageGetter, getGlobal, setGlobal, Input, getSetting,
  setSetting, Modal, DataStore } from 'launchpad';
import Select from 'react-select';
import Hero from '_shared/partial-hero/Hero'
import { NavLink } from 'react-router-dom';

import AutoSlider from '_shared/partial-auto-slider/AutoSlider';
import Page from '_shared/layout-page/Page';
import styled from 'styled-components';
import { confirm } from '_helpers';
import theme from 'styles/theme.js';

const R = DataStore('reviews')


const RForm = styled.form`
  .submit {
    text-align: right;
    margin-top: 20px;
  }
`

export class ReviewForm extends React.Component {
  constructor(props) {
    super(props)
    this.defaultState = {
      reviewText: '',
      author: '',
      company: '',
      title: '',
      date: ''
    }
    this.state = Object.assign({}, this.defaultState)
  }

  componentDidMount() {
    this.reset()
  }

  reset = () => {
    if (this.props.reviewId) {
      this.defaultState = R.get(this.props.reviewId)
    }
    this.setState(this.defaultState)
  }

  submit = (e) => {
    e.preventDefault()
    if (this.props.reviewId) {
      R.update(this.props.reviewId, this.state, () => this.reset())
    } else {
      R.add(this.state)
      this.reset()
    }
    this.props.onSubmit && this.props.onSubmit()
  }

  render() {
    return <RForm onSubmit={this.submit}>
      <Input onChange={(e, val) => { this.setState({ reviewText: val }) }} value={this.state.reviewText} label="Review Text" />
      <Input onChange={(e, val) => { this.setState({ author: val }) }} value={this.state.author} label="Author" />
      <Input onChange={(e, val) => { this.setState({ company: val }) }} value={this.state.company} label="Company" />
      <Input onChange={(e, val) => { this.setState({ title: val }) }} value={this.state.title} label="Title" />
      <Input onChange={(e, val) => { this.setState({ date: val }) }} value={this.state.date} label="Date" />
      <div className='submit'>
        <button>Submit</button>
      </div>
    </RForm>
  }
}



const ReviewStyles = styled.div`
  position: relative;
  border: 1px solid transparent;
  .tools {
    display: none;
  }

  &.admin:hover {
    background: rgba(0,0,0,.05);
    border: 1px dashed #ddd;
    .tools {
      display: block;
      position: absolute;
      top: 5px;
      right: 5px;
      a {
        cursor: pointer;
        margin-left: 5px;
      }
    }
  }
  cite {
    color: ${theme.colors.blue.dark}
  }
`

export class Review extends React.Component {
  state = {
    open: false
  }
  render() {
    const r = this.props.review
    return <div>
      <ReviewStyles className={'review ' + (this.props.className || '') + (getGlobal('is_admin') ? ' admin' : '')}>
        <p className='content'>"{r.reviewText}"</p>

        <cite>— <span className='author'>{r.author}</span>
          <span className='title'>{r.company && ' | ' + (r.title ? r.title + ', ' : '') + r.company}</span>
          {r.date && <span className='date'>, {r.date}</span>}
        </cite>

        {getGlobal('is_admin') && this.props.editable && <div className='tools'>
          <a className='delete material-icons' onClick={() => this.setState({ open: true })}>edit</a>
          <a className='delete material-icons' onClick={() => this.props.onDelete(r)}>close</a>
        </div>}
      </ReviewStyles>
      {this.props.editable && <Modal id={'review' + r.id} open={this.state.open} onClose={() => this.setState({ open: false })}>
        <ReviewForm reviewId={r.id} onSubmit={() => this.setState({ open: false })} />
      </Modal>}
    </div>
  }

}






const ReviewPageStyles = styled.section`
  padding-top: 40px;
  .review {
    padding: 20px 0px;
  }
`



export default class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new: false
    }
  }
  render() {
    const reviews = R.getAll()
    let newBtn = '';
    let newModal = '';
    if (getGlobal('is_admin')) {
      newBtn = (
        <div className='col-sm-12' style={{ marginLeft: '3px' }}>
          <button onClick={() => this.setState({ new: true })}><span className='material-icons'>add</span> New Review</button>
        </div>
      )
      newModal = (
        <Modal open={this.state.new} onClose={() => this.setState({ new: false })} title='New Review'>
          <ReviewForm onSubmit={() => this.setState({ new: false })} />
        </Modal>
      )
    }

    const overlay = <div>
      <h2><Snip page='reviews' name='hero-header' /></h2>
      <h3><Snip page='reviews' name='hero-subheader' /></h3>
    </div>

    return (
      <Page id='reviews'>
        {/*}
          <Hero id='reviews'>
          <h2><Snip page='reviews' name='hero-header'/></h2>
          <h3><Snip page='reviews' name='hero-subheader'/></h3>
          </Hero>
        */}

        <ReviewPageStyles>
          <div className='container reviews-list'>
            {newBtn}
            <div className='row'>
              {reviews.map((r, i) => r && <div key={r.id} className='col-sm-6'>
                <Review review={r} editable onDelete={r => R.delete(r.id)} />
              </div>)}
            </div>
          </div>
        </ReviewPageStyles>
        {newModal}
      </Page>
    )
  }
}








// Reviews Carousel
const FeaturedReviews = DataStore('featured_reviews')

const ReviewsCarouselContainer = styled.div`
  h3 {
    text-align: center;
  }
  position: relative;
  padding: 60px 0;
  .review {
    text-align: center;
    padding: 30px;
    padding-bottom: 60px;
  }
  .edit-reviews {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 30;
    cursor: pointer;
  }
  .review-set {
    padding: 0 100px;
    display: flex;
    align-items: center;
    &>div {
      flex: 1;
      .review {
        text-align: center;
      }
    }
  }
  .auto-slider {
    .fa {
      color: #ddd;
    }
    .buttons {
      a {background-color: #ddd;}
      a.active {background-color: #bbb;}
    }
    .next, .previous {
      &:hover {
        .fa {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: 812px) {
    .auto-slider {
      .previous {
        left: -15px;
      }
      .next {
        right: -15px;
      }
    }
    .slideshow {
      margin: 0 -15px;
    }
    .review-set {
      padding: 0 40px;
    }
  }
  padding: 15px;
`

const ReviewsEditContainer = styled.div`
  .review-item {
    display: flex;
    align-items: center;
    input {
      margin: 20px;
    }
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
`

export class ReviewsCarousel extends React.Component {
  state = {
    edit: false,
    reviewIds: null
  }

  getId = () => {
    return this.props.id || 'main'
  }

  getReviewIds = () => {
    if (this.state.reviewIds) return this.state.reviewIds
    const options = FeaturedReviews.get(this.getId())
    const reviewIds = this.state.reviewIds || (options && [...options.ids])
    if (reviewIds) {
      setTimeout(() => this.setState({ reviewIds }), 100)
    }
    return reviewIds || []
  }

  getReviews = () => {
    const reviewIds = this.getReviewIds()
    const reviews = reviewIds && reviewIds.map(id => R.get(id)).filter(r => r ? true : false)
    return reviewIds ? reviews : []
  }

  select = (push, id) => {
    let reviewIds = this.getReviewIds()
    if (push && !reviewIds.includes(id)) {
      reviewIds.push(id)
    } else if (!push && reviewIds.includes(id)) {
      reviewIds = reviewIds.filter(r => r != id)
    }
    this.setState({ reviewIds }, () => {
      const updated = { ids: this.state.reviewIds }
      FeaturedReviews.update(this.getId(), updated)
    })
  }


  render() {
    const allReviews = R.getAll()
    const reviews = this.getReviews()
    let reviewSets = []
    let currentSet = []
    reviews.forEach(r => {
      currentSet.push(<div key={r.id}><Review review={r} /></div>)
      if (currentSet.length == 1) {
        reviewSets.push(currentSet)
        currentSet = []
      }
    })
    if (currentSet.length) reviewSets.push(currentSet)

    return <ReviewsCarouselContainer>
      <Snip component='h3' className='text-center' page={this.props.page} name={'review_header_' + this.getId()} />
      {getGlobal('is_admin') && <div className='edit-reviews material-icons' onClick={() => this.setState({ edit: true })}>edit</div>}
      <AutoSlider autoSlide={5000}>
        {reviewSets.map((s, i) => <div key={i} className='review-set'>{s}</div>)}
      </AutoSlider>
      <Modal open={this.state.edit} onClose={() => this.setState({ edit: false })}>
        <ReviewsEditContainer>
          {allReviews.map(r => {
            return <div className='review-item' key={r.id}>
              <div>
                <input
                  type='checkbox'
                  title='Included'
                  checked={this.getReviewIds().includes(r.id)}
                  onChange={e => this.select(e.target.checked, r.id)}
                />
              </div>
              <Review review={r} />
            </div>
          })}
        </ReviewsEditContainer>
      </Modal>
    </ReviewsCarouselContainer>

  }
}

// launchpad_url: reviews
