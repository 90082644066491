import React from 'react'
import { apiGet, apiPost, getGlobal, setGlobal, notify, Snip } from 'launchpad'
import { Validator } from '_helpers'
import styled from 'styled-components'
import Slideshow from '_shared/partial-slideshow/Slideshow'
import { history } from 'app'
import { loadCart, addressFields } from 'cart_tools'
import * as GTMEvents from '../../utils/GTMEvents';

export const RegForm = styled.div`
  form {
    max-width: 500px;
    margin: 50px auto;
    text-align: center;
    input {
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 10px;
      display: block;
      width: calc(100% - 45px);
      margin: 20px;
    }
    .margin-bottom {
      margin-bottom: 20px;
    }
    a {
      cursor: pointer;
    }
  }
  .signed-in {
    text-align: center;
    padding: 180px 0;
  }
  .old-customers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .btn {
      margin-left: 10px;
    }
  }
`

export const SignInForm = styled.form`		
@media(max-width: 812px)		
{		
  margin-top: 0 !important;		
  margin-bottom: 0 !important;		
  h1 {		
    margin-top: 0;		
    margin-bottom: 5px;		
    font-size: 24px;		
  }		
  .btn-primary {		
    padding: 10px;		
  }		
  .old-customers		
  {		
    display:none;		
  }		
}		
`

export const loadUser = (cb) => {
  if(getGlobal('activeCustomer')) {
    if(cb) cb(getGlobal('activeCustomer'))
  } else {
    apiPost('check-user-status').then(user => {
      if(user) {
        apiGet('user/'+user).then(user => {
          setGlobal({activeCustomer: user})
          if(cb) cb(user)
        })
      } else {
        setGlobal({activeCustomer: null})
        if(cb) cb(null)
      }
    })
  }
}

export const setUser = (user, cb) => {
  setGlobal({activeCustomer: user})
  apiPost('users/update', user).then(data => {
    if(cb) cb()
  })
}

export const logout = () => {
  apiPost('logout').then(() => {
    setGlobal({activeCustomer: null})
    if(getGlobal('activeCart')){
      let data = {}
      getGlobal('activeCart').resetAddresses()
    }
  })
}

export const login = (email, password, cb) => {
  return apiPost('login', { email, password }).then(data => {
    if(data.authenticated){
      loadUser(cb)
    } else {
      if(data.error){
        notify(data.error, {type: 'error'})
      } else {
        notify('Sorry, something seems to have gone wrong', {type: 'warning'})
      }
    }
  })
}

class LoginForm extends React.Component {
  state = {
    'email': '',
    'password': '',
    cart: null
  }

  componentDidMount() {
    loadCart().then(cart => {
      cart.registerListener(this)
      this.setState({cart})
    })
  }

  componentWillUnmount() {
    this.state.cart && this.state.cart.unregisterListener(this)
  }

  submit = (e) => {
    e.preventDefault()
    const { email, password } = this.state
    const { onLogin } = this.props
    login(email, password, u => {
      if(u) {
        GTMEvents.userLogin(u);
        if(!onLogin){
          history.push('/user-dashboard')
        } else {
          onLogin(u)
        }
      }
    })
  }

  render(){
    return <SignInForm className="signInFrm" onSubmit={this.submit}>
      <h1>Log In</h1>
      <div className='old-customers'>
        <Snip page='global-login' name='old-customer-description' />
        <button className='btn btn-primary' onClick={this.props.onForgot}>Reset Password</button>
      </div>
      <input aria-label='Email' value={this.state.email} onChange={e=>this.setState({email: e.target.value})} placeholder='Email' />
      <input aria-label='Password' value={this.state.password} onChange={e=>this.setState({password: e.target.value})} placeholder='Password' type='password' />
      <button className='btn btn-primary margin-bottom'>Login</button>
      <br />
      Don't have an account? <a tabIndex={0} onClick={this.props.toRegistration}>Register today!</a>
      <br /><a tabIndex={0} onClick={this.props.onForgot}>Forgot Password</a>
    </SignInForm>
  }
}


export class RegistrationForm extends React.Component {
  state = {
    'email': '',
    'first_name': '',
    'last_name': '',
    'password': '',
    'confirmPassword': ''
  }

  submit = (e) => {
    const { email, password, confirmPassword, first_name, last_name } = this.state
    e.preventDefault()
    if(password != confirmPassword){
      return notify('Passwords do not match')
    }
    this.validator.validate().then(valid => {
      if(valid){
        const { onLogin } = this.props
        let data = { email, password, first_name, last_name, user_type: this.props.type || 'customer'}
        apiPost('register', Object.assign(this.props.defaults || {}, data)).then(data => {

          if(data._id){
            if(this.props.defaults && this.props.defaults.wholesaleAccount){
              // GA4
              if (typeof dataLayer !== 'undefined') {
                dataLayer.push({'event': 'Wholesale Registration'});
              }
            }
            login(email, password, u => {
              if(u) {
                GTMEvents.userSignup(u);
                if(!onLogin){
                  history.push('/user-dashboard')
                } else {
                  onLogin(u)
                }
              }
            })
          } else {
            if(data.error){
              notify(data.error, {type: 'warning'})
            }
          }
          //console.log(data)
        })
      }
    })
  }

  render(){
    const Wrapper = !this.props.type ? 'div' : RegForm
    return <Wrapper>
      <Validator onSubmit={this.submit} ref={r => this.validator = r}>
        <h1>Register</h1>
        <input type='email' value={this.state.email} onChange={e=>this.setState({email: e.target.value})} placeholder='Email' aria-label='Email'/>
        <input value={this.state.first_name} onChange={e=>this.setState({first_name: e.target.value})} placeholder='First Name'  aria-label='First Name'/>
        <input data-optional='true' value={this.state.last_name} onChange={e=>this.setState({last_name: e.target.value})} placeholder='Last Name'  aria-label='Last Name'/>
        <input value={this.state.password} onChange={e=>this.setState({password: e.target.value})} placeholder='Password' aria-label='Password' type='password' />
        <input value={this.state.confirmPassword} onChange={e=>this.setState({confirmPassword: e.target.value})} placeholder='Confirm Password'  aria-label='Confirm Password' type='password' />
        <button className='btn btn-primary'>Register</button>
        <br />
        {!this.props.type && <span>Already have an account? <a onClick={this.props.toLogin}>Click here to log in.</a></span>}
      </Validator>
    </Wrapper>
  }
}

export class PasswordForm extends React.Component {
  state = {email: ''}

  submit = e => {
    e.preventDefault()
    apiPost('reset-password', {email: this.state.email}).then(data => {
      if(data.error) notify(data.error, {type: 'error'});
        else if(this.props.onReset) this.props.onReset()
    })
  }

  render() {
    return <form onSubmit={this.submit}>
      <h1>Forgot Password</h1>
      <input value={this.state.email} onChange={e=>this.setState({email: e.target.value})} placeholder='Email' aria-label='Email'/>
      <button className='btn btn-primary'><span className='fa fa-envelope' /> Send Password Recovery Email</button>
      <br />
      Know your password? <a onClick={this.props.toLogin}>Click here to log in.</a>
    </form>
  }
}



export default class UserLogin extends React.Component {
  state = {
    'slide': 2
  }

  render() {
    return <RegForm>
      <Slideshow slide={this.state.slide}>
        <PasswordForm {...this.props} toLogin={()=>this.setState({slide: 2})} onReset={()=>{
          this.setState({slide: 2})
          notify('Please check your email for a link to reset your password.')
        }}/>
        <LoginForm {...this.props} toRegistration={()=>this.setState({slide: 3})} onForgot={()=>this.setState({slide: 1})}/>
        <RegistrationForm {...this.props} toLogin={()=>this.setState({slide: 2})}/>

      </Slideshow>
    </RegForm>
  }

}

if(module.hot) module.hot.accept()
