import React, {Component} from 'react'
import styled from 'styled-components'
import { findProducts, ProductImage, Category } from 'product_tools'
import { DataStore, getGlobal } from 'launchpad'
import { Link } from 'widgets'

// TODO: may want to optionally have product galleries be independent of specific products
//const PG = new DataStore('productGalleries')


const PSContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  gap: 10px;

  .product-field {
    position: relative;
    width: 100%;
    
    input {
      width: 100%;
      padding: 7px 12px;

      &::placeholder {
        color: #AAA;
      }
    }

    .results {
      background: white;
      position: absolute;
      z-index: 2;

      color: #555;
      top: auto;
      bottom: 100%;

      .result {
        padding: 3px 10px;
        border: 1px solid #eee;
        .fa {
          margin-right: 10px;
        }
        &:hover {
          background: #eee;
          cursor: pointer;
        }
      }
    }
  }

  .color-field, .size-field {
    position: relative;
    width: 100%;

    select {
      padding: 7px 12px;
      width: 100%;
    }
  }

  button {
    padding: 7px 12px;
    border: 1px solid #FFF;
    background: #FFF;
    color: #333;

    &:hover:not(:hover) {
      opacity: 0.9;
    }

    &:disabled {
      background: #AAA;
    }
  }
`

export class ProductSelector extends Component {
  state = {
    query: '',
    focused: false,
    sizes: [],
    colors: [],
    selected: null,
    selectedColor: '',
    selectedSize: '',
  }

  select = (selected) => {
    this.setState({
      query: selected.name,
      focused: false,
      selected,
      sizes: selected.sizes,
      colors: selected.colors
    })
  }

  blur = () => {
    setTimeout(()=>this.setState({focused: false}), 100)
  }

  handleAdd = () => {
    this.props.onSelect(
      this.state.selected,
      this.state.selectedColor,
      this.state.selectedSize
    );

    this.setState({
      selected: null,
      selectedColor: '',
      selectedSize: '',
      query: '',
      sizes: [],
      colors: [],
    })
  };

  render() {

    const { query, selected, colors, sizes, selectedColor, selectedSize } = this.state
    const { category_name } = this.props
    const data = category_name ? { category_name } : {}
    const results = query.length > 1 ? findProducts(Object.assign({name: query, limit: 5}, data)) : []


    return <PSContainer className="product-selector">
      <div className="product-field">
        <input
          placeholder='+ Type to Add Products'
          onBlur={this.blur}
          onFocus={() => this.setState({focused: true})}
          onChange={(e) => this.setState({query: e.target.value})}
          value={query}
        />
        <div className='results'>
          {this.state.focused && !results.length && query.length < 2 && <div className='result'>
            <span className='fa fa-arrow-right' /> Enter {2-query.length} more characters to search
            </div>}
          {this.state.focused && !results.length && query.length >= 2 && <div className='result'>
            <span className='fa fa-ban' /> No results found
          </div>}
          {this.state.focused && results.map(p => <div key={p._id} className='result' onClick={() => this.select(p)}>
            <span className='fa fa-plus' /> {p.name}
          </div>)}
        </div>
      </div>

      <div className="color-field">
        <select value={selectedColor} onChange={({ target }) => this.setState({ selectedColor: target.value })}>
          <option value="">Default Color</option>
          {colors.map((color) => (
            <option value={color}>{color}</option>
          ))}
        </select>
      </div>

      <div className="size-field">
        <select value={selectedSize} onChange={({ target }) => this.setState({ selectedSize: target.value })}>
          <option value="">Default Size</option>
          {sizes.map((size) => (
            <option value={size}>{size}</option>
          ))}
        </select>
      </div>

      <button
        onClick={() => this.handleAdd()}
        disabled={!selected}
      >
        Add
      </button>
    </PSContainer>
  }
}


const PBContainer = styled.div`
  border: 1px solid #eee;
  position: relative;

  a {
    height: 100%;
    display: flex;
    flex-direction: column;

    .image {
      margin-top: auto;
      margin-bottom: auto;
    }

    .info {
      padding: 20px 10px;
      width: 100%;
      text-align: center;
    }
  }

  .remove.fa-close {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    transition: opacity .3s;
    cursor: pointer;
  }

  &:hover {
    .remove.fa-close {
      opacity: 1;
    }
  }
`

let categories = {}

const ProductBox = props => {
  const { product, color, size, className, onRemove } = props;

  if(!product) {
    return <span />;
  }

  if (!categories[product.category._id]) {
    categories[product.category._id] = new Category(product.category);
  }

  const category = categories[product.category._id];

  const urlParams = new URLSearchParams();

  if (color) {
    urlParams.set('color', color);
  }
  if (size) {
    urlParams.set('size', size);
  }

  const qs = urlParams.toString();
  const url = `${!category.url.startsWith('/') ? '/' : ''}${category.url}/${product.url}${qs ? `?${qs}` : ''}`;

  return <PBContainer className={`product-box ${className}`}>
    <Link to={url} className='product-container'>
      <ProductImage
        backgroundColor='white'
        className='image'
        category={category}
        series={product._id}
        seriesName={product.name}
        subcategoryName={product.subcategory}
      />

      <div className='info'>
        <div className='pb-title'>{product.name}</div>
      </div>
    </Link>

    {onRemove && getGlobal('is_admin') && <div className='fa fa-close remove' onClick={onRemove}/>}
  </PBContainer>
}



const Container = styled.div`
  .muted {
    color: #ddd;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .product {
      flex: 1;
      margin-right: 10px;
      margin-bottom: 10px;
      max-width: calc(20% - 20px);
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 812px) {
    .products {
      .product {
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        flex-basis: 50%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
`

export default class ProductGallery extends Component {
  getProducts = () => {
    // Initially lists were declared as an array of IDs
    // Now they are declared as arrays of arrays of the following type:
    // [id, color, size]
    // Below we convert the old format to the new one
    return (this.props.gallery || []).filter(x => !!x).map((item) => Array.isArray(item) ? item : [item]);
  }

  addProduct = (selected, selectedColor, selectedSize) => {
    const selectedItemId = selected._id;

    if (!selectedItemId) {
      return;
    }

    const products = this.getProducts();

    const existingItem = products.find(([id, color, size]) => {
      if (id !== selectedItemId) {
        return false;
      }

      if (color && color !== selectedColor) {
        return false;
      }

      if (size && size !== selectedSize) {
        return false;
      }

      return true;
    });

    if (!existingItem) {
      this.props.onChange(products.concat([[selectedItemId, selectedColor, selectedSize]]));
    } else {
      notify(`That item is already listed`)
    }
  }

  removeProduct = (idx) => {
    let products = this.getProducts()
    products.splice(idx, 1)
    this.props.onChange(products)
  }

  render() {
    const products = this.getProducts()
    return <Container>
      {getGlobal('is_admin') && <ProductSelector category_name={this.props.category_name} onSelect={this.addProduct}/>}
      {getGlobal('is_admin') && !products.length && <h2 className='muted'>No products selected</h2>}
      <div className='products' role='group'>
        {products.map(([id, color, size], idx) => (
          <ProductBox
            className='product'
            key={`${id}${idx}`}
            product={findProducts({ _id: id })}
            color={color}
            size={size}
            onRemove={()=>this.removeProduct(idx)}
          />
        ))}
      </div>
    </Container>
  }
}



export class RelatedProducts extends Component {
  render() {
    const {product, category} = this.props
    const categoryIsAccessories = category == 'Accessories'
    const categoryProp = categoryIsAccessories ? 'accessories' : 'related'

    return (
      <ProductGallery
        onChange={(productDefinition) => {
          product.update({
            [categoryProp]: productDefinition
          })
        }}
        gallery={product[categoryProp]}
        category_name={category}
      />
    )
  }
}
