import React from 'react';
import { Snip, Image, ImageGetter, getGlobal, setGlobal, Input, getSetting,
  setSetting, Modal, DataStore, CheckBox, confirm, Collapsible, helpers } from 'launchpad';
import Page from '_shared/layout-page/Page';
import { NavLink } from 'react-router-dom';
import Hero from '_shared/partial-hero/Hero';
import {history} from 'app';
import {confirmDelete} from '_helpers';
import styled from 'styled-components';
import * as _helpers from '_helpers'
import { ContentBlock, CTA, Table, ProductList } from 'sections'
import { ReviewsCarousel } from 'pages/Reviews'
import { savePage } from 'pages/admin/PageManager'


const blockTypes = {
  'content': ContentBlock,
  'reviews': ReviewsCarousel,
  'hero': Hero,
  'cta': CTA,
  'product-list': ProductList
}


const CustomContainer = styled.div`
  .section-edit {
    padding-top: 28px;
    transition: padding .3s;
    position: relative;
    .toggle-section {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 22px;
      display: flex;
      justify-content: space-between;
      button {
        font-size: 14px;
        opacity: .5;
        &:hover {
          opacity: 1;
        }
      }
    }

    &.shown {
      padding-top: 0px;
      .toggle-section {
        height: 0px;
      }
    }
  }

  .add-bar {
    .add-button {
      text-align: center;
      background: rgba(0,0,0,.1);
      opacity: .2;
      padding: 15px;
      cursor: pointer;
      transition: opacity .3s;
    }
    &:hover {
      .add-button {
        opacity: 1;
      }
    }
    .add-layout {
      display: flex;
      >div {
        cursor: pointer;
        flex: 1;
        padding: 15px;
        text-align: center;
        background-color: rgba(0,0,0,0);
        transition: background-color .3s;
        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
        &:hover {
          background-color: rgba(0,0,0,.1);
        }
      }
    }
    border-bottom: 1px solid #ddd;
  }


`

export default class CustomPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      openModal: false,
      slug: '',

      // temp containers, only used when layout are updated
      layout: null,
      blocks: null
    }
  }

  // in lieu of full blown page builder
  defaultBlocks = [
    {id: 1, type: 'hero', name: 'Hero'},
    {id: 2, type: 'content', name: 'Content Block 1'},
    {id: 3, type: 'cta', name: 'CTA'},
    {id: 4, type: 'content', name: 'Content Block 2'},
    {id: 5, type: 'reviews', name: 'Reviews'}
  ]

  get page() {
    console.log(getGlobal('customPages'));
    return (getGlobal('customPages') || []).find(x => x._id == this.props.page._id)
  }

  getLayout = () => {
    const { layout } = this.state
    const p = this.page
    const blocklist = layout && layout.length ? layout : p.layout
    if(blocklist.length == 1 && blocklist[0] == 'empty') return []
    if(!blocklist.length) return this.defaultBlocks
    return blocklist
  }

  setLayout = (layout) => {
    if(layout.length == 0) layout = ['empty']
    this.setState({layout}, () => {
      savePage(Object.assign({}, this.page, {layout}), {skipRefresh: true})
    })
  }

  getBlocks = () => {
    if(this.state.blocks) return this.state.blocks
    const b = this.page.blocks || []
    const layout = b.length && (b.length > 1 || b[0] != 'empty') ? b : this.defaultBlocks
    return layout.filter(x => x != 'empty')
  }

  setBlocks = (layout, blocks, maxBlockId) => {
    maxBlockId = maxBlockId ? {maxBlockId} : {}
    this.setState({layout, blocks}, () => {
      savePage(Object.assign({}, this.page, {layout, blocks}, maxBlockId), {skipRefresh: true})
    })
  }

  addBlock = type => {
    let layout = this.getLayout()
    let blocks = this.getBlocks()
    const maxBlockId = this.page.maxBlockId + 1;
    const newBlock = {id: maxBlockId, type, name: helpers.getTitle(type)}
    layout.push(newBlock)
    blocks.push(newBlock)
    this.setBlocks(layout, blocks, maxBlockId)
  }

  remove = block => {
    confirm('Are you sure you want to remove this block?', () => {
      let layout = this.getLayout()
      let blocks = this.getBlocks()
      layout = layout.filter(x => x.id != block.id)
      blocks = blocks.filter(x => x.id != block.id)
      this.setBlocks(layout, blocks)
    })
  }

  moveBlock = (block, dir) => {
    let blocks = this.getBlocks()
    block = blocks.find(x => x.id == block.id)
    const i = blocks.indexOf(block)
    blocks = blocks.filter(x => x.id != block.id)
    blocks.splice(i+dir, 0, block)
    let layout = []
    blocks.forEach(b => {
      if(this.getLayout().find(x => x.id == b.id)){
        layout.push(b)
      }
    })
    
    this.setBlocks(layout, blocks)
  }

  toggleBlock = (block) => {
    const layout = this.getLayout()
    let newBlocks = []
    if(layout.find(x => x.id == block.id)){
      newBlocks = layout.filter(x => x.id != block.id)
    } else {
      this.getBlocks().forEach(b => {
        if(layout.find(x => x.id == b.id) || b.id == block.id){
          newBlocks.push(b)
        }
      })
    }
    this.setLayout(newBlocks)
  }



  getContentBlock = (block) => {
    const layout = blockTypes
    const Component = layout[block.type]
    const id = `${block.type}${block.id}`

    return <Component key={id} className={(block.type == 'hero') && 'half'} page={this.props.page._id} name={id}>
      {block.type == 'hero' && <Snip component='h1' name='hero-header' />}
    </Component>
  }

  render() {
    const {page} = this.props
    const layout = this.getLayout()
    return <Page id={page._id}>
      <CustomContainer>
        {this.getBlocks().map(block => {
          const show = layout.find(x => x.id == block.id)
          return <div key={block.id} className='page-section'>
            {
              getGlobal('is_admin')

                ? <div className={`section-edit ${show ? 'shown' : ''}`}>

                    <Collapsible open={show}>
                      {this.getContentBlock(block)}
                    </Collapsible>

                    <div className='toggle-section'>
                      <div>
                        <button
                          key={block.id}
                          onClick={() => this.toggleBlock(block)}
                          >
                          <em className='material-icons'>{show ? 'remove' : 'add'}</em>
                          {show ? `Hide ${block.name}` : `Show ${block.name}`}
                        </button>
                      </div>

                      <div className='options'>
                        <button
                          onClick={() => this.moveBlock(block, -1)}
                        >
                          <em className='fa fa-chevron-up'></em>
                        </button>
                        <button
                          onClick={() => this.moveBlock(block, 1)}
                        >
                          <em className='fa fa-chevron-down'></em>
                        </button>
                        <button
                          onClick={() => this.remove(block)}
                        >
                          <em className='fa fa-close'></em>
                        </button>
                      </div>


                    </div>

                </div>

                : (show ? this.getContentBlock(block) : <div />)
            }
          </div>

        })}
        {getGlobal('is_admin') && <div className='add-bar' onMouseEnter={()=>this.setState({showAdd: true})} onMouseLeave={()=>this.setState({showAdd: false})}>
          <div className='add-button' onClick={()=>this.setState({showAdd: !this.state.showAdd})}><span className='fa fa-plus'></span></div>
          <Collapsible className='add-layout' open={this.state.showAdd}>
            {Object.keys(blockTypes).map(x => <div onClick={()=>this.addBlock(x)}>{helpers.getTitle(x)}</div>)}
          </Collapsible>
        </div>}
      </CustomContainer>
    </Page>
  }
}

// launchpad_noindex: true
