import React from 'react';
import {setGlobal, getGlobal, applyMeta} from 'launchpad';

export const PageContext = React.createContext({pageId: ''})

export class Page extends React.Component {
  get pageId(){
    return this.props.id+'_'+window.location.pathname
  }
  componentDidMount() {
    setGlobal({'pageContext': this.pageId, 'pageLabel': (this.props.label || this.props.id || '').toUpperCase()})
    applyMeta()
  }

  componentWillUnmount() {
    if(getGlobal('pageContext') == this.pageId){
      setGlobal({'pageContext': '', pageLabel: ''})
    }
  }

  render() {
    return <main id={this.props.id}>
      <PageContext.Provider value={{pageId: this.props.id}}>
        {this.props.children}
      </PageContext.Provider>
    </main>
  }
}
