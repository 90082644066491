import React from 'react';
import Slideshow from '_shared/partial-slideshow/Slideshow'
import styled from 'styled-components'

const Container = styled.div`

`

export default class AutoSlider extends React.Component {
  constructor(props) {
    super(props);
    this.divs = [];
    this.totalSlides = 0;
    this.state = {
      slide: 0,
      animate: false,
      locked: false
    }
    this.childTotal = 0
  }

  componentDidMount() {
    this.updateCheck(this.props)
    if(this.props.autoSlide){
      this.interval = setInterval(() => this.nextSlide(true), this.props.autoSlide);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentDidUpdate(props) {
    this.updateCheck(this.props)
  }

  updateCheck(props) {
    if(props && props.children && props.children.length && props.children.length != this.childTotal){
      this.childTotal = props.children.length
      this.setState({slide: props.children.length + 1}, () => {
        setTimeout(() => this.setState({animate: true}), 10);
      })
    }
  }

  setSlide(slide, preSlide) {
    if(!this.state.locked){
      this.setState({locked: true})
      if(!preSlide){
        this.setState({slide: slide});
      } else {
        // temp disable animation on edges and jump to other end to create infinite scroll
        this.setState({animate: false}, ()=>{
          setTimeout(() => this.setState({slide: preSlide}, () => {
            setTimeout(() => this.setState({slide: slide, animate:true}), 40)
          }), 20)
        })
      }
      setTimeout(() => {this.setState({locked: false})}, 400);
    }
  }

  checkInterval(auto){
    if(!auto && this.interval) clearInterval(this.interval);
  }

  nextSlide(auto) {
    this.checkInterval(auto)
    let next = this.state.slide + 1;
    if(next > (this.props.children.length * 2)){
      this.setSlide(this.props.children.length + 1, this.props.children.length)
    } else {
      this.setSlide(next)
    }
  }

  previousSlide() {
    this.checkInterval()
    let previous = this.state.slide - 1;
    if(previous < this.props.children.length + 1){
      this.setSlide(this.props.children.length * 2, (this.props.children.length * 2) + 1)
    } else {
      this.setSlide(previous)
    }
  }

  render() {
    let length = this.props.children ? this.props.children.length : 0
    let count = 0
    let dots = (this.props.children || []).map((child, idx) => {
      return (
        <a aria-hidden="true" onClick={e=> this.setState({slide: idx + length + 1})} key={idx}
          className={this.state.slide == (idx + length + 1) ? 'active' : ''} />
      )
    })
    let cn = (this.props.children || [])
    let children = cn.concat(cn).concat(cn)

    return(
      <Container className={'auto-slider ' + (this.state.animate ? '' : 'block-animations ') + (this.props.className || '')} ref={div => this.div = div}>
        <Slideshow slide={this.state.slide}>
          {children}
        </Slideshow>
        <div className='previous' onClick={e => this.previousSlide()}>
          <i className='fa fa-chevron-left' />
        </div>
        {this.props.overlay ?
          <div className='overlay'>
            {this.props.overlay}
          </div>
        : ''}
        <div className='next' onClick={e => this.nextSlide()}>
          <i className='fa fa-chevron-right'/>
        </div>
        {!this.props.hideDots ?
          <div className='buttons'>{dots}</div> :
          ''
        }
      </Container>
    );
  }
}
