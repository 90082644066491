/*
----------------
launchpad/index.jsx
----------------
Basic (optional) functions to facilitate rapid development of CMS functionality

use `launchpadInit(<App />, '[root id]')` to make "global state" available e.g.:
setGlobal({xyz: 'value'});   -->   getGlobal('xyz');

include the `<AdminBar />` component anywhere in the app to make <Snip> editing
possible (with support for headings, b, i, etc.) and to use media library
functionality e.g.:  getMedia(img => doStuff(img));

*/


import React from 'react';
import ReactDOM from 'react-dom';
import openSocket from 'socket.io-client'

// to use react-notifications
//import '!style-loader!css-loader!react-notifications/lib/notifications.css';

// for easy import, e.g. `import {Image} from 'launchpad';`
export {DataStore, ItemForm} from 'launchpad/admin/DataStore';
export {Image, ImageGetter} from 'launchpad/admin/widgets/Image';
export {Snip, getLorem, TextEditor} from 'launchpad/admin/widgets/snips';
export {Loading, Spinner} from 'launchpad/admin/widgets/Loading'
export {Modal, ModalContainer} from 'launchpad/admin/widgets/Modal.jsx';
export {Page, PageContext} from 'launchpad/admin/widgets/page';
export {Paginate} from 'launchpad/admin/widgets/Paginate'
export {Slider} from 'launchpad/admin/widgets/Slider';
export {Setting} from 'launchpad/admin/widgets/Setting';

export {AdminPanel} from 'launchpad/admin/AdminPanel';


// NOTE: no longer importing these directly, lazy loading them instead only when
// user is in admin mode

// export {AdminBar} from 'launchpad/admin/AdminBar';
// export {ContextPane, getContextPane} from 'launchpad/admin/ContextPane';
// export {MediaLibrary} from 'launchpad/admin/MediaLibrary';



export {AdminLogin} from 'launchpad/admin/AdminLogin';
export {Input} from 'launchpad/admin/widgets/Input';
export {SettingCheckbox} from 'launchpad/admin/widgets/SettingCheckbox';

// TODO: keeping this as a proxy, should clean up eventually
export {Collapsible} from 'widgets';

export {Meta, MetaEditor} from 'launchpad/admin/widgets/MetaEditor';
export {DynamicMenu} from 'launchpad/admin/widgets/DynamicMenu/DynamicMenu';
export {DraggableLink} from 'launchpad/admin/widgets/DynamicMenu/DraggableLink';
export {confirm} from '_helpers';
export {default as helpers} from 'launchpad/devops/helpers'
import {notify as helperNotify} from '_helpers'

export const notify = helperNotify;

//export const helpers = h

import { globalState, setGlobal, getGlobal } from 'launchpad/globals'
export { setGlobal, getGlobal } from 'launchpad/globals'


/* basic API interaction
========================================= */

//TODO: API_BASE is more or less irrelevant now that we're using webpack to proxy to
// the API (mimics production), may be worth revisiting the logic here

//const API_BASE = 'http://localhost:5007';
const API_BASE = '';


export const notifyDisconnect = (err) => {
  console.error(`
===============================
  ⚠️ API CONNECTION ERROR ⚠️
===============================

${err.stack}
  `)
  notify('Sorry, something seems to have gone wrong! If this message keeps appearing, please try refreshing the page.', {type: 'error'})
}

// generic api request/response
export function apiRequest(route, data, method) {
  if(route.startsWith('/')) route = route.slice(1)
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/api/v1/${route}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      method: method,
      body: JSON.stringify(data)
    })
    .then(resp => {
      if(!resp || resp.status != 200) return reject(resp)
      if(!getGlobal('apiConnected')) setGlobal({apiConnected: true})
      try {
        resolve(resp.json().catch(e => reject(e)))
      } catch(e) {
        resolve(resp)
      }
    })
    .catch(err => {
      notifyDisconnect(err)
      reject(err)
    })
  })
}

// get request from api
export function apiGet(route) {
  if(route.startsWith('/')) route = route.slice(1)
  if (window.globalRequestsCache !== undefined &&
      window.globalRequestsCache[route] !== undefined) {
    return new Promise((resolve, reject) => {
      resolve(window.globalRequestsCache[route]);
    });
  }
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE}/api/v1/${route}`, {method: 'get', credentials: 'include'})
      .then(resp => {
        resolve(resp.json().catch(err => reject(err)))
      })
      .catch(err => notifyDisconnect(err));
  })
}

// post given data to given API route
export function apiPost(route, data){
  return apiRequest(route, data, 'POST')
}

// post given data to given API route
export function apiPut(route, data){
  return apiRequest(route, data, 'PUT')
}

export function apiUpload(route, data){
  if(route.startsWith('/')) route = route.slice(1)
  return fetch(`${API_BASE}/api/v1/${route}`, {
    method: 'POST',
    body: data
  }).then(resp => resp.json())
}



/* basic auth functionality
============================================== */

// login
export function login(email, password){
  return apiPost('admin/login', {email, password});
}

// logout
export function logout() {
  apiPost('admin/logout').then(checkAuth).catch(e=>console.log(e));
}

// check auth status
// load collection from API into global state
export function checkAuth(cb){
  apiGet('auth-status')
  .then(data => {
    if(data.admin || data.user_access == 'admin'){
      setGlobal({is_admin: data.admin, user_access: data.user_access})
    } else {
      setGlobal({is_admin: false, user_access: null})
    }
    if(typeof cb == 'function'){
      cb(data.admin)
    }
  })
  .catch(error => {
    console.log(`error checking auth status`, error);
    setGlobal({is_admin: false});
    //TODO: error handling
  })
}



/* Launchpad tools, e.g. Media Library
===================================================== */

// runs functions if launchpad_data.AdminBar exists, else logs error
function runAdmin(func, name){
  if(globalState && globalState.AdminBar){
    func();
  } else {
    console.error((name ? name : func.name) + '() requires the <AdminBar> component to be mounted')
  }
}

// aliases for common media related tasks
export function getMedia(cb, current){
  runAdmin(()=>{globalState.MediaLibrary.getFile(cb, current)}, 'getMedia');
}

export function showMedia(){
  runAdmin(()=>globalState.MediaLibrary.show(), 'showMedia');
}

export function hideMedia(){
  runAdmin(()=>globalState.MediaLibrary.hide(), 'hideMedia');
}

export function toggleMedia(){
  runAdmin(()=>{
    let ml = globalState.MediaLibrary
    if(ml.state.open){
      ml.hide();
    } else {
      ml.show();
    }
  })
}

let appHistory;

export function getHistory() {
  return appHistory
}

export function launchpadInit(app, id, options) {
  // begin loading images, then load text before rendering the app
  if(options){
    appHistory = options.history;
  }
  reloadImageInstances();
  reloadSettings();
  loadGlobal('meta')
  reloadSnips(() => {
    ReactDOM.render(app, document.getElementById(id))
  });
}



/* API related functions
------------------------------------------------------*/

let loadHooks = {}

// register reload hook
export function addLoadListener(col, id, fn){
  if(!loadHooks[col]){
    loadHooks[col] = []
  }
  loadHooks[col][id] = fn
}

// remove load hook
export function removeLoadListener(col, id){
  delete loadHooks[col][id];
}


// load collection from API into global state
export function loadGlobal(collection, cb){
  apiGet(collection)
  .then(data => {
    //console.log(collection + ' loaded')
    setGlobal({[collection]: data, noAPIConnection: false})
    if(typeof cb === 'function') cb()
    for(let x in loadHooks[collection]){
      loadHooks[collection][x]();
    }
  })
  .catch(error => {
    console.error(`error loading ${collection}:`, error)
    setGlobal({noAPIConnection: true})
    //TODO: error handling
  }).finally(() => {
    if(typeof cb === 'function') cb()
  })
}

// loads all snips and refreshes app
export function reloadSnips(cb) {
  loadGlobal('snips', cb)
}

// loads all images and refreshes app
export function reloadImageInstances(cb) {
  loadGlobal('images', cb);
}

// loads all media references (not the actual files)
export function reloadImages(cb) {
  loadGlobal('media', cb)
}

// loads all settings
export function reloadSettings(cb) {
  loadGlobal('settings', cb)
}




/* CRUD API interactions
-----------------------------------------*/

// create new document in given collection
export function createDoc(collection, data){
  return apiPut(collection, data);
}

// delete existing document
export function deleteDoc(collection, id){
  return apiRequest(`${collection}/${id}`, {}, 'delete')
}


// update existing document without debounce
export function updateDoc(collection, data){
  return apiRequest(`${collection}`, data, 'post');
}

let updateDebounceTimer = null;
let updated = {};

// debounced updates (consolidates rapid updates to the same object to one request)
export function debouncedUpdateDoc(collection, key, atts, options) {
  if(!updated[collection]){
    updated[collection] = {}
  }
  let item = updated[collection][key];
  if(typeof item === 'undefined' && getGlobal(collection)){
    item = getGlobal(collection)[key];
  }
  if(!item){
    item = {}
  }
  for(let key in atts){
    item[key] = atts[key]
  }
  updated[collection][key] = item;

  if(updateDebounceTimer){
    clearTimeout(updateDebounceTimer);
  }
  updateDebounceTimer = setTimeout(() => {
    // push all updates
    for(let col in updated){
      for(let key in updated[col]){
        updateDoc(col, updated[col][key]).then(res => {
          loadGlobal(col)
          if(options && options.cb) options.cb()
        })
        delete updated[col][key]
      }
    }
  }, 300)
}


// upload file and create reference in media
export function uploadMedia(file, progressCB, doneCB) {
  let uploader = new XMLHttpRequest();
  uploader.upload.addEventListener('progress', e => {
    let percent = Math.round((e.loaded / e.total) * 100)
    progressCB(percent);
  }, false);
  uploader.upload.addEventListener('load', () => {
    if(typeof doneCB === 'function'){
      doneCB(uploader.responseText)
    }
    reloadImages();
  });
  uploader.open("POST", `${API_BASE}/api/v1/upload-media`, true);
  uploader.setRequestHeader("Content-type", file.type);
  uploader.send(file);
}

// delete file from storage and media references by url
export function removeMedia(url) {
  let mediaRef = getGlobal('media').find(m => m.url == url);
  deleteDoc('media', mediaRef._id).then(() => {
    loadGlobal('media');
  })
}


let localSnips = []
// get and set snips
export function setSnip(value, order, id, page) {
  let newSnip = getSnip(id, page);
  newSnip.content = value;
  newSnip.order = order;
  let snips = getGlobal('snips')
  let updated = false
  snips = snips.map(snip => {
    if(snip.id == id && snip.page == page) {
      snip.content = value
      updated = true
    }
    return snip
  })
  if(!updated) snips.push(newSnip)
  localSnips = snips
  setGlobal({snips})
  updateDoc('snips', newSnip);
}

export function getSnip(name, page) {
  page = (page || 'global-text')
  let snip = (getGlobal('snips') || []).find(x => x.page == page && x.name == name);
  return snip ? snip : {content: '', page, name};
}

export function getSnips(page){
  return (getGlobal('snips') || []).concat(localSnips).filter(x => x.groups && x.groups.includes(page));
}

// get and set page meta
export function setMeta(page, name, value) {
  let meta = getMeta(page, name);
  meta.value = value;
  console.log(page, name, value)
  debouncedUpdateDoc('meta', page+name, meta)
}

export function getMeta(page, name) {
  let meta = (getGlobal('meta') || []).find(x => x.page == page && x.name == name)
  return meta ? meta : {page, name}
}

export function applyMeta(){
  if(document){
    // TODO: expose ability to set default title/description
    let title = getMeta(getGlobal('pageContext'), 'title').value || 'Frame USA'
    let description = getMeta(getGlobal('pageContext'), 'description').value || 'Frame USA'
    document.querySelector('title').innerHTML = title
    document.querySelector('meta[name=description]').content = description
    if(getGlobal('app')) getGlobal('app').forceUpdate()
  }
}

addLoadListener('meta', 'main', applyMeta)


// get and set images
export function setImage(group, label, obj) {
  let image = getImage(label, group)
  if(image._id) {
    for(let key in obj){
      image[key] = obj[key];
    }
    saveImage(group, label, obj)
  } else {
    saveImage(group, label, obj).then(reloadImageInstances);
  }
}


function saveImage(page, name, obj){
  obj = Object.assign({ page, name }, obj);
  let image = getImage(name, page);
  if(image._id) {
    obj._id = image._id;
    debouncedUpdateDoc('images', image._id, obj);
  } else {
    return createDoc('images', Object.assign({ page, name }, obj));
  }
}



export function getImage(name, page) {
  let url, small

  const image = (getGlobal('images') || []).find(x => x.name == name && x.page == page && x._id != 'temp')
  if(image){
    let media = (getGlobal('media') || []).find(x => x._id == image.mediaId)
    if(media){
      url = media.url
      small = media.small
    }
  }
  return Object.assign({}, image || {}, {url, small})
}

export function setSetting(obj, cb){
  let update = {}
  for(let key in obj){
    update.name = key
    update.value = obj[key]
    //console.log(`setting ${key} to:`, obj[key])
    if(typeof update.value == 'object'){
      update.value = JSON.stringify({launchpad_encoded_object: update.value})
    }
  }
  let setting = (getGlobal('settings') || []).find(x => x.name == update.name)
  if(setting) {
    update._id = setting._id
  }
  if(setting && setting._id) {
    debouncedUpdateDoc('settings', setting._id, update, {cb: cb})

    //immediately update local copy of settings
    setGlobal({settings: getGlobal('settings').map(s => {
      if(setting._id == s._id) {
        s = Object.assign({}, setting, update)
      }
      return s
    })})
  } else {
    createDoc('settings', update).then(reloadSettings)
  }
}

export function getSetting(name) {
  let setting = (getGlobal('settings') || []).find(x => x.name == name)
  if(setting && setting.value && setting.value.includes && setting.value.includes('launchpad_encoded_object')) {
    setting.value = JSON.parse(setting.value).launchpad_encoded_object
  }
  return setting ? setting.value : ''
}


/* socket.io server interaction
========================================= */
let socket = null

export const getSocket = () => {
  if(!socket) socket = openSocket(API_BASE, {path: '/api/v1/socket.io'})
  return socket
}



//if(module.hot) module.hot.accept()
