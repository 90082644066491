import { getSetting, DataStore } from 'launchpad'

const getPrice = p => Math.round((p + 0.00001) * 100) / 100

// ******************************************
//   design-a-frame pricing
// ******************************************

export const backingOptions = ['Cardboard', 'White Foam Core', 'Black Foam Core']
export const glazingOptions = ['Glass', 'Styrene', 'Acrylic']
export const matOptions = ['Standard', 'Premium', 'Black Core', 'Acid Free', 'Suede']

export const MatData = DataStore('MatData')

export const getMatData = type => {
  return MatData.query({type}) || {}
}

export const setMatData = (type, data) => {
  MatData.addOrUpdate({type}, data)
}
// Mat Designer Code Start
export const MatDesignerData = DataStore('MatDesignerData')

export const getMatDesignerData = type => {
  return MatDesignerData.query({type}) || {}
}

export const setMatDesignerData = (type, data) => {
  MatDesignerData.addOrUpdate({type}, data)
}

export const MatDesignerCornerData = DataStore('MatDesignerCornerData')

export const getMatDesignerCornerData = type => {
  return MatDesignerCornerData.query({type}) || {}
}

export const setMatDesignerCornerData = (type, data) => {
  MatDesignerCornerData.addOrUpdate({type}, data)
}

export const FilterColorData = DataStore('FilterColorData')

export const getFilterColorData = () => {
  return FilterColorData.query({}) || {}
}

export const setFilterColorData = (data) => {
  FilterColorData.addOrUpdate({}, data)
}
// Mat Designer Code End
export const DAFParams = {
  acrylic_sq_ft_price: 1.31,
  acrylic_sku: '74127',
  acrylic_min_size: 0,
  acrylic_max_size: '',
  glass_sq_ft_price: .42,
  glass_sku: '74126',
  glass_min_size: 0,
  glass_max_size: '',
  styrene_sq_ft_price: .43,
  styrene_sku: '74128',
  styrene_min_size: 0,
  styrene_max_size: '',
  cardboard_sq_ft_price: .07,
  cardboard_sku: '74130',
  cardboard_min_size: 0,
  cardboard_max_size: '',
  white_foam_core_sq_ft_price: .23,
  white_foam_core_sku: '74131',
  white_foam_core_min_size: 0,
  white_foam_core_max_size: '',
  black_foam_core_sq_ft_price: .32,
  black_foam_core_sku: '74132',
  black_foam_core_min_size: 0,
  black_foam_core_max_size: '',
  standard_mat_sq_ft_price: .25,
  standard_mat_sku: '11000',
  premium_mat_sq_ft_price: .45,
  premium_mat_sku: '',
  black_core_mat_sq_ft_price: .71,
  black_core_mat_sku: '',
  acid_free_mat_sq_ft_price: .78,
  acid_free_mat_sku: '',
  suede_mat_sq_ft_price: 1.08,
  suede_mat_sku: '',
  b2c_adder: 1.3,
  matte_paper_sq_ft_price: .675,
  semi_gloss_paper_sq_ft_price: .694,
  gloss_sq_ft_price: .725,
  margin: 3.0,
  vgroove_8_21: 1,
  vgroove_21_48: 1.5,
  vgroove_48_72: 2,
  fitting_charge_sku: '74133',
  mat_min_size: '0.25',
  mat_max_size: '5'
}

// Mat Designer Code

export const MDParams = {
  // acrylic_sq_ft_price: 1.31,
  // acrylic_sku: '74127',
  // acrylic_min_size: 0,
  // acrylic_max_size: '',
  // glass_sq_ft_price: .42,
  // glass_sku: '74126',
  // glass_min_size: 0,
  // glass_max_size: '',
  // styrene_sq_ft_price: .43,
  // styrene_sku: '74128',
  // styrene_min_size: 0,
  // styrene_max_size: '',
  // cardboard_sq_ft_price: .07,
  // cardboard_sku: '74130',
  // cardboard_min_size: 0,
  // cardboard_max_size: '',
  // white_foam_core_sq_ft_price: .23,
  // white_foam_core_sku: '74131',
  // white_foam_core_min_size: 0,
  // white_foam_core_max_size: '',
  // black_foam_core_sq_ft_price: .32,
  // black_foam_core_sku: '74132',
  // black_foam_core_min_size: 0,
  // black_foam_core_max_size: '',
  standard_mat_sq_ft_price: .25,
  standard_mat_sku: '11000',
  premium_mat_sq_ft_price: .45,
  premium_mat_sku: '11000',
  black_core_mat_sq_ft_price: .71,
  black_core_mat_sku: '11000',
  acid_free_mat_sq_ft_price: .78,
  acid_free_mat_sku: '11000',
  suede_mat_sq_ft_price: 1.08,
  suede_mat_sku: '11000',
  b2c_adder: 1.3,
  matte_paper_sq_ft_price: .675,
  semi_gloss_paper_sq_ft_price: .694,
  gloss_sq_ft_price: .725,
  margin: 3.0,
  vgroove_8_21: 1,
  vgroove_21_48: 1.5,
  vgroove_48_72: 2,
  fitting_charge_sku: '74133',
  mat_min_size: '0.25',
  mat_max_size: '5'
}

export const cornerOption = [
  {name:'Rectangle', path: require('images/corners/Rectangle.jpg')},
  {name:'Oval', path: require('images/corners/Oval.jpg')},
  {name:'Round', path: require('images/corners/Round.jpg')},
  {name:'Cathedral', path: require('images/corners/Cathedral.jpg')},
  // {name:'Charis', path: require('images/corners/Charis.jpg')},
  {name:'Circle Slant', path: require('images/corners/Circle Slant.jpg')},
  {name:'Circuit', path: require('images/corners/Circuit.jpg')},
  {name:'Classic', path: require('images/corners/Classic.jpg')},
  // {name:'Colonial', path: require('images/corners/Colonial.jpg')},
  {name:'Crescent', path: require('images/corners/Crescent.jpg')},
  {name:'Crown', path: require('images/corners/Crown.jpg')},
  // {name:'Deco', path: require('images/corners/Deco.jpg')},
  {name:'Diamond', path: require('images/corners/Diamond.jpg')},
  // {name:'Durango', path: require('images/corners/Durango.jpg')},
  {name:'Kobe', path: require('images/corners/Kobe.jpg')},
  {name:'Monterystep', path: require('images/corners/Monterystep.jpg')},
  {name:'Apollo', path: require('images/corners/Apollo.jpg')},
  // {name:'Palamino', path: require('images/corners/Palamino.jpg')},
  {name:'Philo', path: require('images/corners/Philo.jpg')},
  {name:'Pier', path: require('images/corners/Pier.jpg')},
  // {name:'Arrow', path: require('images/corners/Arrow.jpg')},
  {name:'Santa Fe', path: require('images/corners/Santa Fe.jpg')},
  // {name:'Double Taos', path: require('images/corners/Double Taos.jpg')},
  {name:'Double Western', path: require('images/corners/Double Western.jpg')},
  {name:'Thales', path: require('images/corners/Thales.jpg')},
]

const multipliers = {
  standard: 1.25,
  cardboard: 1.25,
  premium: 1.2,
  black_core: 1.2,
  acid_free: 1.15,
  suede: 1.1
}

// Mat Designer Code End
export const getOptions = (set, l, w) => {
  return set.filter(x => {
    const key = x.replace(/ /g, '_').toLowerCase()
    const min = getDAFP(key+'_min_size') || 0
    const max = getDAFP(key+'_max_size') || 9999
    if(l < min || w < min || l > max || w > max) return false
    return true
  })
}

export const getBackingOptions = (l, w) => {
  return getOptions(backingOptions, l, w)
}

export const getGlazingOptions = (l, w) => {
  return getOptions(glazingOptions, l, w)
}


const slug = s => (s && s.toLowerCase) ? s.toLowerCase().replace(/ /g, '_') : ''
//console.log("Slug", slug)
export const getDAFP = (key) => {
  const f = parseFloat(getSetting('DAFParam_'+key))
  return (isNaN(f) ? getSetting('DAFParam_'+key) : f) || DAFParams[key]
}

// MAT Designer Data
export const getMatP = (key) => {
  //console.log("getSetting('MDParams_'+key)",getSetting('MDParams_'+key));
  const f = parseFloat(getSetting('MDParams_'+key))
  return (isNaN(f) ? getSetting('MDParams_'+key) : f) || MDParams[key]
}

export const getMDP = (key) => {
  //const f = parseFloat(getSetting('DAFParam_'+key))
  //console.log("getSetting('MDParam_'+key)",getSetting('MDParam_'+key));
  const f = parseFloat(getSetting('MDParam_'+key))
  return (isNaN(f) ? getSetting('MDParam_'+key) : f) || MDParams[key]
}

// MAT Designer Data End
export const getDAFMoulding = (l, w, sku) => {
  const mouldingCost = sku.price
  return getPrice((((l + w) * 2) / 12) * 1.25 * mouldingCost)
}

export const getGlazingBackingCost = (l, w, type, modifier) => {
  const cost = getDAFP(slug(type) + '_sq_ft_price')
  return getPrice((((l * w) / 144) * cost * modifier) + .15)
}

export const getDAFMatCost = (l, w, type) => {
  const cost = getDAFP(slug(type || '') + '_mat_sq_ft_price')
  const m = multipliers[slug(type)] || 1
  //console.log("PRICE", getPrice((((l * w) / 144) * cost * m) + .25))
  return getPrice((((l * w) / 144) * cost * m) + .25)
}


export const getMDMatCost = (l1, w1, type) => {
  const cost = getMDP(slug(type || '') + '_mat_sq_ft_price')
  const m = multipliers[slug(type)] || 1
  //console.log("PRICE", getPrice((((l1 * w1) / 144) * cost * m) + .25))
  //return getPrice((((l * w) / 144) * 1.2 * m) + .25)
  return getPrice((((l1 * w1) / 144) * cost * m) + .25)
}

export const getDAFFitting = (l, w, mats) => {
  const ui = l + w
  const base = .25 * mats.length
  if(ui < 26) return .7 + base
  if(ui < 49) return 1 + base
  return getPrice(1.25 + base)
}

export const getDAFWire = (l, w) => {
  return getPrice(l + w > 24 ? .3 : 0)
}

export const getDAF = (options) => {
  let {l, w, matQty, glazing, backing, mats, customSku, qty, image, imageSize, chargeImage, vgrooveVal} = options
  mats = mats || []
  customSku = customSku || {sku: 'NOT SELECTED', description: 'CUSTOM FRAME SKU', price: 0}
  const margin = parseFloat(getDAFP('margin'))
  let vgroov = 0 ;
  // console.log(margin)
  const moulding = getDAFMoulding(l, w, customSku) * margin
  const glazingCost = getGlazingBackingCost(l, w, glazing, 1.10) * margin
  const backingCost = getGlazingBackingCost(l, w, backing, 1.15) * margin
  const matsCost = mats.map(x => getDAFMatCost(l, w, (x.type || x))).reduce((total, x) => x + total, 0) * margin
  const fittingCost = getDAFFitting(l, w, mats) * margin
  const wireCost = getDAFWire(l, w) * margin
  const matminwidth = parseFloat(getDAFP('mat_min_size'))
  const matmaxwidth = parseFloat(getDAFP('mat_max_size'))

  //console.log("vgrooveValvgrooveValvgrooveVal", vgrooveVal)
  if(vgrooveVal) {
    let t = parseInt(l) + parseInt(w);
    let p = 0;
  //   vgroove_8_21: 1,
  // vgroove_21_48: 1.5,
  // vgroove_48_72: 2,
  //console.log('vgrooveValt',t,l,w)
     if(t < 8){
      p = 0;
    } else if(t >=8 && t <= 21 ) {
      p = getDAFP('vgroove_8_21');
      //console.log('vgroove_8_21',p)
    } else if(t >= 21.125 && t <= 48) {
      p = getDAFP('vgroove_21_48');
      //console.log('vgroove_21_48',p)
    } else {
      p = getDAFP('vgroove_48_72');
      //console.log('vgroove_48_72',p)
    }
    vgroov = p;
    //console.log('vgroove',p)
    //skus.push({sku: '12345', description: `V - Groove`, price: p})
  }

  const vgr = vgrooveVal ? 'Vgroove' : ''
  const subtotal = moulding + glazingCost + backingCost + matsCost + fittingCost + wireCost

  const paperCost = chargeImage ? (((l*w)/144) * parseFloat(getDAFP('semi_gloss_paper_sq_ft_price'))) + .45 : 0

  // const total = subtotal + packing
  const size = `${l}x${w}`

  let skus = [
    {sku: 'BAF001', description: 'Build-A-Frame', price: 0},
    {sku: '74134P', description: `Custom Uploaded Image - ${imageSize}`, price: paperCost * margin},
  ]

  if(!paperCost || !margin || paperCost * margin == 0) {		
    skus = [		
      {sku: 'BAF001', description: 'Build-A-Frame', price: 0},		
      {sku: '74134', description: `Custom Uploaded Image - ${imageSize}`, price: paperCost * margin},		
    ]		
  }
  skus = skus.concat(mats.map((m, idx) => {
    return {
      sku: getDAFP(`${slug(m.type || m)}_mat_sku`),
      description: `${(m.type || m)} Mat (${m.color && m.color.code}) - ${idx === 0 ? 'w' : 'r'} ${m.size} - ${size} | ${imageSize}${idx === 0 && vgrooveVal ? ' Vgroove' : ''}`,
      price: getDAFMatCost(l, w, (m.type || m)) * margin}
  }))

  const packingSku = '74135'
  const packingMultiplier = l + w >= 48 ? .13 : .2
  const packing = subtotal * packingMultiplier

  skus = skus.concat([
    {sku: customSku.sku, description: `Design-A-Frame ${customSku.description} - ${size}`, price: moulding + packing + wireCost},
    {sku: getDAFP(`${slug(glazing)}_sku`), description: `Design-A-Frame ${glazing} Glazing - ${size}`, price: glazingCost},
    {sku: getDAFP(`${slug(backing)}_sku`), description: `Design-A-Frame ${backing} Backing - ${size}`, price: backingCost},
    {sku: getDAFP('fitting_charge_sku'), description: `Design-A-Frame Fitting Charges - ${size}`, price: fittingCost},
    // {sku: packingSku, description: 'Design-A-Frame Packing Fee', price: packing}
  ])
  const price1 = skus.map(x=>x.price).reduce((x, i) => x + (isNaN(i) ? 0 : i), 0)
  const price = price1 + vgroov
  const vgroveprice = parseFloat(vgroov) * b2c;
  const b2c = parseFloat(getDAFP('b2c_adder'))
  return { skus, price, b2c, vgroov, matminwidth, matmaxwidth}
}


export const getMat = (options) => {
  let {l, w,l1,w1, matQty, glazing, backing, mats, customSku, qty, image, imageSize, chargeImage, cornerOption, vgrooveVal} = options
  mats = mats || []
  //customSku = customSku || {sku: 'NOT SELECTED', description: 'CUSTOM FRAME SKU', price: 0}
  const margin = parseFloat(getMatP('margin'))
  //console.log(margin)
  //console.log("options", options);
 //console.log("Mats", mats);
  const moulding = 0
  const glazingCost = 0
  const backingCost = 0
  const matsCost = mats.map(x => getMDMatCost(l1, w1, (x.type || x))).reduce((total, x) => x + total, 0) * margin
  //console.log(matsCost);
  const fittingCost = 0
  const wireCost = 0
  let vgroov = 0 ;
  const subtotal = matsCost
  const matsminwidth = parseFloat(getMDP('mat_min_size'))
  const matsmaxwidth = parseFloat(getMDP('mat_max_size'))

  //const paperCost = chargeImage ? (((l*w)/144) * parseFloat(getDAFP('semi_gloss_paper_sq_ft_price'))) + .45 : 0

  // const total = subtotal + packing
  const size = `${l}x${w}`

  let skus = []
  let skusdata = [];

  // if(!paperCost || !margin || paperCost * margin == 0) {
  //   skus = [
  //     {sku: 'BAF001', description: 'Build-A-Frame', price: 0},
  //     {sku: '74134', description: `Custom Uploaded Image - ${imageSize}`, price: paperCost * margin},
  //   ]
  // }

  //console.log("vgrooveValvgrooveValvgrooveValvgrooveValvgrooveValvgrooveVal", vgrooveVal)
  if(vgrooveVal) {
    let t = parseInt(l) + parseInt(w);
    let p = 0;
    //   vgroove_8_21: 1,
    // vgroove_21_48: 1.5,
    // vgroove_48_72: 2,

    //console.log('vgrooveValt',t,l,w)
    // if(t >=8 && t <= 21 ) {
    //   p = getMDP('vgroove_8_21');
    //   console.log('vgroove_8_21',p)
    // } else if(t >= 21.125 && t <= 48) {
    //   p = getMDP('vgroove_21_48');
    //   console.log('vgroove_21_48',p)
    // } else {
    //   p = getMDP('vgroove_48_72');
    //   console.log('vgroove_48_72',p)
    // }

    if(t < 8){
      p = 0;
    } else if(t >=8 && t <= 21 ) {
      p = getMDP('vgroove_8_21');
      //console.log('vgroove_8_21',p)
    } else if(t >= 21.125 && t <= 48) {
      p = getMDP('vgroove_21_48');
      //console.log('vgroove_21_48',p)
    } else {
      p = getMDP('vgroove_48_72');
      //console.log('vgroove_48_72',p)
    }
    vgroov = p;
    //console.log('vgroove',p)
    //skus.push({sku: '12345', description: `V - Groove`, price: p})
  }
  skusdata = skusdata.concat(mats.map((m, idx) => {
    //console.log("xxxxxxxxxxx", m);
    //console.log("SKU",getMDP(`${slug(m.type || m)}_mat_sku`));
    //console.log("Pricessssss+SKU",{sku: getMDP(`${slug(m.type || m)}_mat_sku`), description: `${(m.type || m)} Mat (${m.color && m.color.code}) - ${idx == 0 ? 'w' : 'r'} ${m.size} - ${size} | ${imageSize}`, price: getMDMatCost(l1, w1, (m.type || m)) * margin});
    return {sku: getMDP(`${slug(m.type || m)}_mat_sku`), description: `${(m.type || m)} Mat (${m.color && m.color.code}) - ${idx == 0 ? 'w' : 'r'} ${m.size} - ${size} | ${imageSize}`, price: getMDMatCost(l1, w1, (m.type || m)) * margin}
  }))
  const vgr = vgrooveVal ? 'Vgroove' : ''

  skus = skus.concat({
    sku:'11000',
    description:`${mats.map((m, idx) =>`(${m.color && m.color.code}) ${idx == 0 ? 'w' : 'r'} ${m.size}`)} - ${imageSize} | ${size} ${cornerOption} ${vgr}`,
    price: matsCost+vgroov
  });
  
  const packingSku = '74135'
  const packingMultiplier = l + w >= 48 ? .13 : .2
  const packing = subtotal * packingMultiplier

  // skus = skus.concat([
  //   {sku: customSku.sku, description: `Mat-designer ${customSku.description} - ${size}`, price: moulding + packing + wireCost},
  //   // {sku: getDAFP(`${slug(glazing)}_sku`), description: `Design-A-Frame ${glazing} Glazing - ${size}`, price: glazingCost},
  //   // {sku: getDAFP(`${slug(backing)}_sku`), description: `Design-A-Frame ${backing} Backing - ${size}`, price: backingCost},
  //   // {sku: getDAFP('fitting_charge_sku'), description: `Design-A-Frame Fitting Charges - ${size}`, price: fittingCost},
  //   // {sku: packingSku, description: 'Design-A-Frame Packing Fee', price: packing}
  // ])
  const price = skus.map(x=>x.price).reduce((x, i) => x + (isNaN(i) ? 0 : i), 0)
  // CHANGED THIS TO MATCH DESIGN A FRAME
  // const b2c = parseFloat(getMDP('b2c_adder'))
  const b2c = parseFloat(getDAFP('b2c_adder'))
  return {skusdata,skus, price, b2c ,vgroov,matsCost,matsminwidth,matsmaxwidth}
}

export const getMatDesign = (options) => {
  let {l, w, matQty, glazing, backing, mats, customSku, qty, image, imageSize, chargeImage} = options
  mats = mats || []
  customSku = customSku || {sku: 'NOT SELECTED', description: 'CUSTOM FRAME SKU', price: 0}
  const margin = parseFloat(getMatP('margin'))
  // console.log(margin)
  const moulding =  0; //getDAFMoulding(l, w, customSku) * margin
  const glazingCost = 0; //getGlazingBackingCost(l, w, glazing, 1.10) * margin
  const backingCost = 0; //getGlazingBackingCost(l, w, backing, 1.15) * margin
  const matsCost = mats.map(x => getDAFMatCost(l, w, (x.type || x))).reduce((total, x) => x + total, 0) * margin
  const fittingCost = 0; //getDAFFitting(l, w, mats) * margin
  const wireCost = 0; //getDAFWire(l, w) * margin

  const subtotal = moulding + glazingCost + backingCost + matsCost + fittingCost + wireCost

  //const paperCost = chargeImage ? (((l*w)/144) * parseFloat(getDAFP('semi_gloss_paper_sq_ft_price'))) + .45 : 0

  // const total = subtotal + packing
  const size = `${l}x${w}`

  // let skus = [
  //   {sku: 'BAF001', description: 'Build-A-Frame', price: 0},
  //   {sku: '74134P', description: `Custom Uploaded Image - ${imageSize}`, price: paperCost * margin},
  // ]

  let skus = []

  skus = skus.concat(mats.map((m, idx) => {
    return {sku: getDAFP(`${slug(m.type || m)}_mat_sku`), description: `${(m.type || m)} Mat (${m.color && m.color.code}) - ${idx == 0 ? 'w' : 'r'} ${m.size} - ${size} | ${imageSize}`, price: getDAFMatCost(l, w, (m.type || m)) * margin}
  }))

  // const packingSku = '74135'
  // const packingMultiplier = l + w >= 48 ? .13 : .2
  // const packing = subtotal * packingMultiplier

  skus = skus.concat([
    {sku: customSku.sku, description: `Design-A-Frame ${customSku.description} - ${size}`, price: 0},
    // {sku: packingSku, description: 'Design-A-Frame Packing Fee', price: packing}
  ])
  const price = skus.map(x=>x.price).reduce((x, i) => x + (isNaN(i) ? 0 : i), 0)
  const b2c = parseFloat(getDAFP('b2c_adder'))
  return { skus, price, b2c }
}
// ******************************************
//   custom frame pricing
// ******************************************

export const customFrameParams = {
  wireKitCutOff: 24,
  foamPrice: 0.035,

  // Modified to use depending on quantity
  smallBulkModifier: 3.0,
  medBulkModifier: 2.60,
  largeBulkModifier: 2.35,
  xlargeBulkModifier: 2.25,

  // Number of Frames Cutoffs for Modifiers to use
  smallBulkCutOff: 2,
  medBulkCutOff: 6,
  largeBulkCutOff: 13,
  wireHangingKit: 0.3,

  // Backing Modifiers For Complete Frame
  smallBackingModifier: 0.05,
  medBackingModifier: 0.06,
  largeBackingModifier: 0.07,

  // Backing Modifiers For Shell
  smallShellBackingModifier: 0.015,
  medShellBackingModifier: 0.0205,
  largeShellBackingModifier: 0.0236,

  // Cut Off values for each Modifier
  backingSmallCutOff: 26,
  backingMedCutOff: 49,

  // Moudling modifiers
  mouldingModA: 2,
  mouldingModB: 12,
  mouldingModC: 1.25,
  addOnValue: 1.00,
  useStoreFront: 1
}

export const getCFP = (key) => {
  return parseFloat(getSetting('customFrameParam_'+key)) || customFrameParams[key]
}




export const getMouldingCost = (l, w, productCost) => {
  const modA = getCFP('mouldingModA');
  const modB = getCFP('mouldingModB');
  const modC = getCFP('mouldingModC');
  return ((l+w) * (modA / modB) * modC * productCost) + 1
}

export const getBackingCost = (l, w, shellOnly) => {
  const u = l + w
  if(shellOnly){
    if(u < getCFP('backingSmallCutOff')) return getCFP('smallShellBackingModifier') * u
    if(u < getCFP('backingMedCutOff')) return getCFP('medShellBackingModifier') * u
    return getCFP('largeShellBackingModifier') * u
  } else {
    if(u < getCFP('backingSmallCutOff')) return getCFP('smallBackingModifier') * u
    if(u < getCFP('backingMedCutOff')) return getCFP('medBackingModifier') * u
    return getCFP('largeBackingModifier') * u
  }
}

export const getHangerCost = (l, w) => {
  return (l + w) > getCFP('wireKitCutOff') ? getCFP('wireHangingKit') : 0
}

export const getFoamCost = (l, w, foamCore) => {
  return foamCore ? (l + w) * getCFP('foamPrice') : 0
}

export const getBulkModifier = (qty) => {
  if(qty < getCFP('smallBulkCutOff')) return getCFP('smallBulkModifier')
  if(qty < getCFP('medBulkCutOff')) return getCFP('medBulkModifier')
  if(qty < getCFP('largeBulkCutOff')) return getCFP('largeBulkModifier')
  return getCFP('xlargeBulkModifier')
}


export const getCustomPrice = (qty, l, w, productCost, shellOnly, foamCore) => {
  // TODO: mat costs?
  const mat = 0
  // TODO: product cost?

  qty = parseInt(qty)
  l = parseFloat(l)
  w = parseFloat(w)
  productCost = parseFloat(productCost)

  //console.log(qty, l, w, productCost)

  const mc = getMouldingCost(l, w, productCost)
  const bc = getBackingCost(l, w, shellOnly)
  const hc = getHangerCost(l, w)
  const fc = getFoamCost(l, w, foamCore)

  //console.log(qty, l, w, productCost, shellOnly)
  return ((mc + bc + hc + fc + mat) * getBulkModifier(qty)) * qty
}
