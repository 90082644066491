import { getGlobal, PageContext, DataStore, Image, Snip, Modal, Collapsible,
  notify, confirm } from 'launchpad';
import ContentEditable from 'react-contenteditable';
import React from 'react';
import styled from 'styled-components'
import theme from 'styles/theme.js';
import { OptionsPanel, LayoutSelector, Section } from 'sections'
import { Link } from 'widgets'
import { ProductSelector } from 'widgets/ProductGallery'
import { findProducts } from 'product_tools'
import { ProductGrid } from 'templates/Search'

const ProductLists = DataStore('CTAOptions')


const Container = styled.div`
  position: relative;
  padding: 0 30px;
`

class Class extends React.Component {

  state = {
    edit: false,
    // only used when editing
    options: false
  }

  edit = (obj) => {
    const updated = Object.assign({}, this.props.options, obj)
    this.props.onEdit(updated)
  }

  getOptions = () => {
    if(this.state.options) return this.state.options
    const o = ProductLists.getAll()
    let options = o.find(op => {
      return op.page == this.props.page && op.name == this.props.name
    })
    return  Object.assign(this.defaultOptions || {}, options || {page: this.props.page, name: this.props.name})
  }

  setOptions = (options) => {
    const currentOptions = this.getOptions()
    let newOptions = Object.assign({}, currentOptions, options)

    // load into state while editing
    this.setState({options: newOptions})

    ProductLists.update(currentOptions.id, newOptions)
  }

  get list() {
    // Initially lists were declared as an array of IDs
    // Now they are declared as arrays of arrays of the following type:
    // [id, color, size]
    // Below we convert the old format to the new one
    return (this.getOptions().list || []).filter(x => !!x).map((item) => Array.isArray(item) ? item : [item]);
  }

  handleSelect = (selected, selectedColor, selectedSize) => {
    const selectedItemId = selected._id
    let list = this.list

    const existingItem = list.find(([id, color, size]) => {
      if (id !== selectedItemId) {
        return false;
      }

      if (color && color !== selectedColor) {
        return false;
      }

      if (size && size !== selectedSize) {
        return false;
      }

      return true;
    });

    if (!existingItem) {
      list.push([selectedItemId, selectedColor, selectedSize]);
    } else {
      notify(`That item is already listed`)
    }

    this.setOptions({ list })
  }

  remove = item => {
    confirm(`Are you sure you want to remove this product?`, () => {
      this.setOptions({list: this.list.filter(x => x && x[0] != item._id)})
    })
  }

  setImage = (series, image) => {
    let imageData = this.getOptions().imageData || {}
    imageData[series._id || series] = image
    this.setOptions({imageData})
  }

  render() {
    const options = this.getOptions()
    const { edit } = this.state
    const { page, name } = this.props

    // Patch products with extra data (color, size) in order to build their links
    // taking into account selected color and size
    const products = this.list.map(([_id, color, size]) => {
      const product = findProducts({ _id })
      product.extra = { color, size }
      return product
    });

    return <div>
      <Section editing={this.state.edit} className={`cta ${this.props.className || ''}`}>
        <Container>
          <div className='container'>
            <ProductGrid
              products={products}
              hideFilters
              thumbnailPrefix={page+name}
              onRemove={i => this.remove(i)}
              onSetImage={this.setImage}
              imageData={options.imageData}
            />
          </div>
          {getGlobal('is_admin') && <div className='edit-button'>
            <button className='material-icons' onClick={()=>this.setState({edit: !edit})}>{edit ? 'close' : 'edit'}</button>
          </div>}
        </Container>
      </Section>

      {getGlobal('is_admin') && <OptionsPanel open={this.state.edit} onClose={()=>this.setState({edit: false})}>
          <h3>Product List Options</h3>
          <div className='option-group'>
            <div className='option-row'>
              <div>
                <ProductSelector onSelect={this.handleSelect}/>
              </div>
            </div>

          </div>
      </OptionsPanel>}
    </div>
  }
}


export const ProductList = (props) => <PageContext.Consumer>
  { context => <Class {...props} page={props.page || context.pageId} /> }
</PageContext.Consumer>
