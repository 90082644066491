import {getGlobal} from "launchpad";

function getItemID(item){
    if (item.item_id) return item.item_id;
    if (item.pid) return item.pid;
    if (item.is_Mat) return "11000";
    return (item.sku !== "74136" && item.sku !== "74135") ? (item.fasSku || item.sku) : (item.sku || item.fasSku);
}

function mapCartItem(item) {
    if (!item.qty || item.qty === 0) return {item_id: getItemID(item), error: 'Item qty = 0'};
    const price = roundToMoney(strToFloat(item.price) / item.qty)
    let data = {
        item_id: getItemID(item),
        item_name: item.name || item.description,
        variant: item.description,
        quantity: item.qty,
        price: price,
        sku: item.sku,
    };
    if (item.rawPrice) {
        const priceWithoutDiscount = roundToMoney(strToFloat(item.rawPrice) / item.qty);
        if (price !== priceWithoutDiscount) data['discount'] = roundToMoney(priceWithoutDiscount - price);
    }
    return data;
}

function strToFloat(str){
    if (typeof str == 'number') return str;
    return (typeof str == 'string') ? parseFloat(str) : 0.0;
}

function roundToMoney(price) {
    if (!price) return 0.0;
    if (typeof price == 'string') price = parseFloat(price);
    return Math.round(price*100)/100;
}

export async function purchase(cart){
    const productList = cart.items.map(function (item) {
        return mapCartItem(item);
    });
    const data = {
        event: 'purchase',
        ecommerce: {
            currency: 'USD',
            transaction_id: cart.transaction_id,
            coupon: cart.coupon_code,
            shipping: strToFloat(cart.shipping),
            tax: strToFloat(cart.tax),
            discount: strToFloat(cart.discount),
            total: strToFloat(cart.total),
            value: strToFloat(cart.total),
            subtotal: strToFloat(cart.subtotal),
            payment_type: cart.payment_type,
            items: productList,
        }
    }
    await pushToDataLayer(data);
}
export async function addCouponCode(cart){
    const productList = cart.items.map(function (item) {
        return mapCartItem(item);
    });
    //const discount = roundToMoney(productList.map((p)=>(p.discount || 0) * p.quantity).reduce((v,a)=>v + a,0));
    const data = {
        event: 'add_coupon_code',
        ecommerce: {
            currency: 'USD',
            coupon: cart.coupon_code,
            shipping: strToFloat(cart.freight),
            tax: strToFloat(cart.tax),
            discount: strToFloat(cart.discount),
            total: strToFloat(cart.total),
            value: strToFloat(cart.total),
            subtotal: strToFloat(cart.subtotal),
            items: productList,
        }
    }
    await pushToDataLayer(data);
}

export async function addShippingInfo(email, phone){
    if (email && !localStorage.getItem('userEmail'))
        await addUserEmailToLocalstorage(email);
    if (phone && !localStorage.getItem('userPhone'))
        localStorage.setItem('userPhone', phone);
    const data = {
        event: 'add_shipping_info',
        ecommerce: {
            currency: 'USD',
            checkout_step: 2,
        }
    }
    await pushToDataLayer(data);
}
export async function addPaymentInfo(){
    const data = {
        event: 'add_payment_info',
        ecommerce: {
            currency: 'USD',
            checkout_step: 3,
        }
    }
    await pushToDataLayer(data);
}
export async function beginCheckout(items){
    const productList = items.map(function (item) {
        return mapCartItem(item);
    });
    const data = {
        event: 'begin_checkout',
        ecommerce: {
            currency: 'USD',
            items: productList,
        }
    }
    await pushToDataLayer(data);
}

export async function removeFromCart(item){
    if (!item) return;
    const product = mapCartItem(item);
    let data = {
        event: 'remove_from_cart',
        ecommerce: {
            currency: 'USD',
            value: product.price * product.quantity,
            items: [product]
        }
    };
    await pushToDataLayer(data);
}

export async function userLogin(user){
    await pushToDataLayer({
        event: 'login',
    });
}

export async function userSignup(user){
    await pushToDataLayer({
        event: 'sign_up',
    });
}

export async function viewItem(item){
    let data = {
        event: 'view_item',
        ecommerce: {
            currency: 'USD',
            items: [{
                item_id: item._id,
                series_id: item._id,
                item_name: item.name,
                item_category: item.category ? item.category.name : '',
            }]
        }
    };
    await pushToDataLayer(data);
}

export async function viewItemList(items){
    if (!items || items.length < 1) return;
    const productList = items.map((item)=> {
        return {
            item_id: item._id,
            series_id: item._id,
            item_name: item.name,
            item_category: item.category ? item.category.name : '',
        }
    })
    let data = {
        event: 'view_item_list',
        ecommerce: {
            currency: 'USD',
            items: productList,
        }
    };
    await pushToDataLayer(data);
}

export async function addCustomMatToCart(item){
    const price = strToFloat((item.price*item.b2c).toFixed(2));
    let data = {
        event: 'add_to_cart',
        ecommerce: {
            currency: 'USD',
            value: price,
            items: [{
                item_id: getItemID(item),
                item_name: item.description,
                item_category: 'Custom Mat',
                sku: getItemID(item),
                variant: item.description,
                quantity: item.qty,
                price: price,
            }]
        }
    };
    await pushToDataLayer(data);
}

export async function addCustomFrameToCart(item){
    const price = strToFloat((item.price*item.b2c).toFixed(2));
    let data = {
        event: 'add_to_cart',
        ecommerce: {
            currency: 'USD',
            value: price * item.qty,
            items: [{
                item_id: getItemID(item),
                item_name: item.description,
                item_category: 'Custom Frame',
                sku: item.sku,
                variant: item.description,
                quantity: item.qty,
                price: price,
                url: item.url,
            }]
        }
    };
    await pushToDataLayer(data);
}

export async function addToCart(series, selectedItem){
    const price = strToFloat(selectedItem.final_price.toFixed(2));
    let data = {
        event: 'add_to_cart',
        ecommerce: {
            currency: 'USD',
            value: price,
            items: [{
                item_id: selectedItem.item_id,
                item_name: selectedItem.name,
                item_category: series.category.name,
                sku: selectedItem.sku,
                variant: selectedItem.description,
                quantity: selectedItem.qty,
                url: selectedItem.url,
                price: price,
            }]
        }
    };
    await pushToDataLayer(data);
}


async function addUserInfo(data){
    let userId, userEmail, userEmailHash, userPhone;
    const user = getGlobal('activeCustomer')
    if (user) {
        userId = user._id;
        userEmail = user.email;
        userPhone = user.phone;
        const uid = localStorage.getItem('userId');
        if (!uid || uid !== userId){
            localStorage.setItem('userId', userId);
            localStorage.setItem('userPhone', userPhone);
            await addUserEmailToLocalstorage(user.email);
        }
        if (!localStorage.getItem('userPhone')) localStorage.setItem('userPhone', userPhone);
    } else {
        userId = localStorage.getItem('userId');
        userEmail = localStorage.getItem('userEmail');
        userPhone = localStorage.getItem('userPhone');
    }
    userEmailHash = localStorage.getItem('userEmailHash');
    if (userId) data['user_id'] = userId;
    if (userEmail) data['user_email'] = userEmail;
    if (userEmailHash) data['user_email_hash'] = userEmailHash;
    if (userPhone) data['user_phone'] = userPhone;
    return data;
}

function addCartId(data){
    const cartId =  localStorage.getItem('cartToken');
    if (cartId) data['cart_id'] = cartId;
    return data;
}

async function pushToDataLayer(data){
    const dataLayer = window.dataLayer || [];
    data = addCartId(data);
    data = await addUserInfo(data);
    dataLayer.push(data);
}

async function encodeEmail(message) {
    const msgUint8 = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
}

async function addUserEmailToLocalstorage(email){
    localStorage.setItem('userEmail', email);
    const emailHash = await encodeEmail(email);
    localStorage.setItem('userEmailHash', emailHash);
    return emailHash;
}