import React from 'react';
import { getSetting } from 'launchpad';
import { Snip, Image, DynamicMenu, helpers } from 'launchpad';

class Footer extends React.Component {

  socialLink = (type, title, icon) => {
    icon = icon || type
    title = title || helpers.getTitle(type)
    return getSetting(type) ? <div key={type} className='social-link'>
      <a href={getSetting(type)} className={type + ' social-button-link'} target="_blank">
        <span className={`fa fa-${icon}`} /> {title}
      </a>
    </div> : ''
  }
  render() {
    const socialLinks = {
      facebook: '',
      twitter: '',
      linkedin: 'LinkedIn',
      instagram: '',
      youtube: 'YouTube',
      pinterest: ''
    }
    return (
      <footer>
        <div>
          <div className='footer-lists'>
            <div className='row'>
              <div className='col-lg-4 logo'>
                <Image name='foter-logo' mode='contain' dimmer={false} />
              </div>
              <div className='col-lg-8'>
                <div className='row footer-links'>

                  <div className='col-lg-3 col-md-6'>
                    <h3>About Frame USA</h3>
                    <DynamicMenu disableSubmenu layout='vertical' menuId='footer-about' />
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <h3>Company</h3>
                    <DynamicMenu disableSubmenu layout='vertical' menuId='footer-company' />
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <h3>Shop</h3>
                    <DynamicMenu disableSubmenu layout='vertical' menuId='footer-shop' />
                  </div>

                  <div className='col-lg-3 social col-md-6'>
                    <h3>Social</h3>
                    {Object.keys(socialLinks).map(key => this.socialLink(key, socialLinks[key]))}
                  </div>

                </div>
              </div>
            </div>
            <div className='copyright'>
                all rights reserved &copy; Frame USA {(new Date()).getFullYear()}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
