import React from 'react';
import {Dialog, DialogTitle, DialogContent} from "@material-ui/core"
import styled from 'styled-components'


const ExitButton = styled.button`
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border-radius: 0;
  color: #ccc;
  border: none;
  outline: none!important;
  svg {
    display: block;
    overflow: visible;
    position: relative;
    width: 24px;
    height: 24px;
  }
`
export default class AdvMuiModal extends React.Component{
    constructor(props) {
        super(props);
    }
    render (){
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="customized-dialog-title"
                maxWidth={this.props.maxWidth}
                fullWidth={this.props.fullWidth}
                className="AdvMuiModal"
            >
                <DialogTitle>
                    <ExitButton type="button" onClick={this.props.onClose} name="exit" >
                        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24">
                            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z">
                            </path>
                        </svg>
                    </ExitButton>
                </DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
            </Dialog>
        )
    }
}