import React from 'react';
import { onEnterOrSpaceDownClick } from '../../utils';

class HelpButton extends React.Component {
  render() {
    const {simple, className, onClick, ariaLabel} = this.props;
    return simple
      ? <i
        tabIndex={0}
        role='button'
        aria-label={ariaLabel || 'Extra information'}
        className={`fa fa-question ${className}`}
        onClick={onClick}
        onKeyDown={onEnterOrSpaceDownClick}
      />
      : <div
        role='button'
        aria-label={ariaLabel || 'Extra information'}
        tabIndex={0}
        className={`size sc-jDwBTQ ePkAYQ ${className}`}
        onClick={onClick}
        onKeyDown={onEnterOrSpaceDownClick}
      >
        <i class="fa fa-question"></i>
      </div >
  }
}
HelpButton.defaultProps = {
  simple: false,
  className: '',
};

export default HelpButton;