import React from 'react';
import {
  getGlobal, setGlobal, Snip, Image, Collapsible, setSetting,
  getSetting, DynamicMenu
} from 'launchpad';
import { NavLink } from 'react-router-dom';
import { mainMenu, history } from 'app.jsx';
import { Hamburger } from 'launchpad/admin/widgets/Icons';
import styled from 'styled-components'
import { confirm } from '_helpers'
import { logout } from '_shared/user/UserLogin'
import SearchBar from 'templates/Search'
import { CartIcon } from 'cart_tools'
import { Link, Icon } from 'widgets'
import SkipLink from './SkipLink';
import { onEnterOrSpacebarDownClick } from '../../../utils';


const HeaderContainer = styled.header`
  .icons {
    display: flex;
    align-items: center;
    .fa, .svg-icon {
      margin: 4px 8px;
      font-size: 30px;
      color: #444;
      cursor: pointer;
    }
    a {
      display: inline-block;
    }
  }
  .sign-in {
    position: relative;
    .collapsible-panel {
      position: absolute;
      width: calc(100% - 30px);
      background: #fff;
      left: 20px;
      z-index: 3;
      .account-options a {
        display: block;
        padding: 5px 20px;
      }
    }
  }

  .sub-nav {
    ul {
      min-height: 30px;
    }
  }

  .search-bar {
    flex: 1;
  }

  .mobile-options {
    display: none;
  }

  @media (max-width: 812px) {
    .header-wrapper {
      display: flex;
      width: 100%;
      height: auto;
      align-items: center;
      > * {
        padding: 5px;
        order: 1;
        align-items: center;
      }
      .main-menu {
        order: 0;
        flex-grow: 0;
        margin: 15px;
      }

    }


    .sub-nav {
      display: none;
    }

    .logo {
      width: 20%;
      .editable-image {
        width: 100% !important;
        height: 40px;
      }
    }

    .sign-in {
      display: none;
    }

    .tools {
      display: flex;
      padding-right: 0;
      .search-bar {
        flex: 1;
        input {
          padding-right: 25px;
        }
      }
      .icons {
        margin: 0;

        .fa, .svg-icon {
          margin: 4px;
        }
      }
    }

    .main-menu {
      padding: 0;
      .text-right.menu {
        text-align: center !important;
      }
    }

    .mobile-options {
      display: block;

      .collapsible-panel {
        box-shadow: none;
      }

      .sign-in {
        display: block;
        a {
          padding-left: 15px;
          &:not(:last-child){
            border-bottom: 1px solid #ddd;
          }
        }
        .btn-primary {
          width: 100%;
        }
        .collapsible-panel {
          position: relative;
          background: #fff;
          left: 0px;
          z-index: 1;
          .account-options a {
            display: block;
          }
        }
      }
    }

    .hamburger {
      width: 25px;
      margin: 0;
    }
  }
`


class Header extends React.Component {
  adjustScreenSize = (e) =>
    this.setState({ isMobile: e.matches });
    
  constructor(props) {
    super(props);
    this.state = {
      hamburger: false,
      isMobile: window.matchMedia("(max-width: 812px)").matches,
      //collapsed: true,
      top: 0
    }
  }

  componentDidMount() {
    history.listen((location, action) => {
      // close menus when navigating
      setGlobal({ headerGallery: false, headerServices: false });
    });
    window.addEventListener('resize', () => this.checkSize());
    setTimeout(() => this.forceUpdate(), 5000)

    window
      .matchMedia('(max-width: 812px)')
      .addEventListener('change', this.adjustScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.checkSize())
    window.removeEventListener('change', this.adjustScreenSize)
  }

  componentDidUpdate() {
    this.checkSize();
  }

  toggleHamburger() {
    setGlobal({ mainHamburgerOpen: !getGlobal('mainHamburgerOpen') })
    //this.setState({collapsed: !this.state.collapsed})
  }

  checkSize() {
    if (this.shadowNav) {
      //let maxHeight = 0;
      let el = this.shadowNav.getElementsByTagName('ul')[0];
      let height = el.offsetHeight;
      let maxHeight = el.children[0].offsetHeight + 10;

      //let height = el.offsetHeight;


      // for(let idx in el.childNodes){
      //   let height = el.childNodes[idx].offsetHeight;
      //   maxHeight = (height > maxHeight) ? height : maxHeight;
      // }

      //console.log(height, maxHeight)

      let headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
      if (headerHeight != this.state.top) {
        this.setState({ top: headerHeight });
      }

      if (!getGlobal('activeMenuLink')) {
        if ((height > maxHeight) && !this.state.hamburger) {
          this.setState({ hamburger: true });
        } else if ((height <= maxHeight) && this.state.hamburger) {
          this.setState({ hamburger: false, collapsed: true });
        }
      }
    }
  }


  // little hack to make the sign in box work like a select
  signInFocused = false

  focusSignIn = () => {
    this.signInFocused = true;
    this.setState({ showSignIn: true })
  }

  blurSignIn = () => {
    this.signInFocused = false;
    setTimeout(() => {
      if (!this.signInFocused) this.setState({ showSignIn: false })
    }, 10)
  }

  openPSC = () => {
    if (window.ga || window.gtag) {
        // GA4
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({'event': 'Provide Support Live Chat'});
        }
      // window.gtag( 'event', 'click', {
      //   'event_category': 'Provide Support Live Chat',
      //   'event_label': window.location,
      //   'transport_type': 'beacon',
      // } );
    }
    if (!window.ClickPSC()) {
      notify(`Sorry, it looks like live chat is currently unavailable. Please try again between 9am and 5pm EST.`)
    }
  }

  render() {
    const collapsed = !this.state.hamburger || !getGlobal('mainHamburgerOpen')
    const desktopTabIndex = this.state.isMobile ? -1 : 0
    const mobileTabIndex = collapsed || !this.state.isMobile ? -1 : 0
    return (
      <HeaderContainer>
        <div className='header-wrapper'>
          <SkipLink
          />
          <nav>
            <NavLink to="/" className="logo"><Image useEditIcon name='main-logo' mode='contain' dimmer={false} /><span className='sr-only'>Example</span></NavLink>
            <div className='search-menu'>
              <div className='tools'>
                <SearchBar placeholder='Search (ex. poster frames)' />
                <div className='icons'>
                  <div className='sign-in' tabIndex={desktopTabIndex}
                    onFocus={this.focusSignIn}
                    onBlur={this.blurSignIn}>
                    <button

                      className='btn btn-primary'
                    >
                      My Account
                    </button>
                    <Collapsible className='account-options' tabIndex={desktopTabIndex} open={this.state.showSignIn} onMouseUp={this.blurSignIn}>
                      {getGlobal('activeCustomer')
                        ? <NavLink to='/user-dashboard' tabIndex={desktopTabIndex} onMouseUp={e => { e.stopPropagation(); this.setState({ showSignIn: false }) }}> My Account </NavLink>
                        : <NavLink to='/login' tabIndex={desktopTabIndex} onMouseUp={(e) => { e.stopPropagation(); this.setState({ showSignIn: false }) }}> Sign In </NavLink>
                      }
                      <Link href='/order-review' tabIndex={desktopTabIndex} onClick={() => { }}> Order Review </Link>
                      {getGlobal('activeCustomer') && <a href='#' onClick={logout}>Log Out</a>}
                    </Collapsible>
                  </div>
                  {/* <span className='fa fa-comments'/> */}
                  <Icon role='button' aria-label='Customer support' tabIndex={0} name='chat' width='30' onClick={this.openPSC} onKeyDown={onEnterOrSpacebarDownClick}/>
                  <CartIcon />
                </div>
              </div>
            </div>
            <Image className='header-right d-none d-lg-block' name='header-right' mode='contain' dimmer={false} />
          </nav>
          <nav className='main-menu'>
            <div className='menu'>
              <MainMenu hamburger={this.state.hamburger}
                desktopTabIndex={desktopTabIndex}
                mobileTabIndex={mobileTabIndex}
                collapsed={collapsed}
                top={this.state.top}
                onToggle={() => this.toggleHamburger()}
              //onNav={() => this.setState({collapsed: true})}
              />
            </div>
          </nav>
          <div className='sub-nav'>
            <DynamicMenu menuId='main-submenu' />
          </div>

          {/* offscreen nav to check for wrapping */}
          <nav className="shadow-nav main-menu" ref={nav => this.shadowNav = nav}>
            <MainMenu tabIndex={-1} hamburger={false} collapsed={true} top={this.state.top} noSave />
          </nav>
        </div>
      </HeaderContainer>
    );
  }
}

class MainMenu extends React.Component {
  state = {
    showSignIn: false,
  }

  focusSignIn = () => {
    this.setState({ showSignIn: true })
  }

  blurSignIn = () => {
    this.setState({ showSignIn: false })
  }

  render() {
    let btn = '';
    const desktopTabIndex = this.props.desktopTabIndex || this.props.tabIndex
    const mobileTabIndex = this.props.mobileTabIndex || this.props.tabIndex
    if (this.props.hamburger) {
      btn = <button aria-label={this.props.collapsed ? 'Open menu' : 'Close menu'} className='hamburger' onClick={this.props.onToggle}><Hamburger open={!this.props.collapsed} /></button>
    }
    const { showSignIn } = this.state
    return (
      <div className='text-right menu'>
        <DynamicMenu tabIndex={desktopTabIndex} menuId='main-menu' className={"text-center list-menu" + (this.props.hamburger ? ' hide' : '')} noSave={this.props.noSave} />
        {btn}
        <div className={'collapsible-nav text-left' + (this.props.collapsed ? '' : ' open') + (this.props.hamburger ? '' : ' hidden')}
          style={{ top: this.props.top + 'px' }}>

          <div className='mobile-options'>
            <div className='sign-in' tabIndex={mobileTabIndex}>
              <button className='btn btn-primary' tabIndex={mobileTabIndex} onClick={showSignIn ? this.blurSignIn : this.focusSignIn}>My Account</button>
              <Collapsible className='account-options' tabIndex={mobileTabIndex} open={this.state.showSignIn} onMouseUp={this.blurSignIn}>
                {getGlobal('activeCustomer')
                  ? <NavLink to='/user-dashboard' tabIndex={mobileTabIndex} onMouseUp={e => { e.stopPropagation(); this.setState({ showSignIn: false }) }}> My Account </NavLink>
                  : <NavLink to='/login' tabIndex={mobileTabIndex} onMouseUp={(e) => { e.stopPropagation(); this.setState({ showSignIn: false }) }}> Sign In </NavLink>
                }
                <Link href='/order-review' tabIndex={mobileTabIndex} onClick={() => { }}> Order Review </Link>
                {getGlobal('activeCustomer') && <a href='#' onClick={logout}>Log Out</a>}
              </Collapsible>
            </div>
          </div>

          <DynamicMenu tabIndex={mobileTabIndex} menuId='main-menu' toggleSubmenu layout='vertical' noSave={this.props.noSave} />
          <div className='mobile-options'>
            <DynamicMenu tabIndex={mobileTabIndex} menuId='main-submenu' />
          </div>
        </div>
      </div>
    )
  }
}

export default Header
