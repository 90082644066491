import React from 'react';
import {setGlobal, getGlobal, applyMeta, Page as CorePage} from 'launchpad';

// TODO: verify that this is still necessary (vs. import {Page} from 'launchpad')

export default class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // setGlobal({'pageContext': this.props.id, 'pageLabel': (this.props.label || this.props.id || '').toUpperCase()})
    applyMeta()
  }

  componentWillUnmount() {
    // if(getGlobal('pageContext') == this.props.id){
    //   setGlobal({'pageContext': '', pageLabel: ''})
    // }
  }

  render() {
    return <CorePage {...this.props}>
      <div key='main' {... this.props} className={'page '+(this.props.className || '')}>
        {this.props.children}
      </div>
    </CorePage>
  }
}
