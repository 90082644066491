import React from 'react';

class SkipLink extends React.Component {
  render() {
    const onClick = (event) => {
      event.preventDefault();
      for (const target of this.props.skipToTargets) {
        const container = document.querySelector(target);
        if (container) {
          const tabIndex = container.tabIndex;
          container.tabIndex = -1;
          container.focus();
          container.tabIndex = tabIndex;
          break;
        }
      }
    }
    return <button
      className='skip-link'
      type='button'
      onClick={onClick}
    >Skip to main content</button>
  }
}

SkipLink.defaultProps = {
  skipToTargets: [".skipLinkTarget", "main"],
};

export default SkipLink