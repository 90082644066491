import React from 'react'
import { getGlobal, helpers, getSetting, apiGet, apiPost, Loading, confirm,
  DataStore, setGlobal, notify } from 'launchpad'
import styled from 'styled-components'
import { getUpload, getSlug } from '_helpers'
import { ProductContainer, getCustomPrice, getSizeLabel } from 'product_tools'
import { NavLink } from 'react-router-dom';
import { loadUser } from '_shared/user/UserLogin'
import { cartConfig } from '../../global_config'
import { Icon } from 'widgets'

import {startPayment} from './authnet'
export {startPayment} from './authnet'
import { loadFreightPrices, getOversize, getOversizeSkus, getShippingCost, defaultShippingRates } from './shipping'
export { loadFreightPrices, getOversize, getOversizeSkus, getShippingCost, defaultShippingRates } from './shipping'
import { Coupons, validateCoupon, getCoupon } from './coupons'
export { Coupons, validateCoupon, getCoupon } from './coupons'
import {getSalesTaxRateByState, calcSalesTax} from "./salesTax";
export {getSalesTaxSettings, setSalesTaxSettings} from "./salesTax";



export const addressFields = ['company', 'first_name', 'last_name', 'street', 'street_2', 'phone', 'city', 'state', 'zip', 'country']
export let emptyAddress = {}
addressFields.forEach(f => {
  emptyAddress['billing_'+f] = ''
  emptyAddress['shipping_'+f] = ''
})
emptyAddress['email'] = ''
let couponCount = 0
export const config = cartConfig


export const completePaypal = (data, cart) => {
  return new Promise(async (resolve, reject) => {
    await cart.updateData({})
    const paypal = await apiPost(`paypal-payment/${cart._id}`, {paypal: data})
    if(data.orderID && data.payerID && !paypal.error) {
      return resolve(paypal)
    } else {
      return reject(paypal)
    }
  })
}


const charitySku = 70466
let addingCharity = false

// container for cart related functionality, abstracts away some of the weirdness so
// the rest of the code can be simpler
class Cart extends ProductContainer {
  collection = 'orders'

  get customer() {
    return (this.user || getGlobal('activeCustomer')) ? (getGlobal('activeCustomer') || {}) : {}
  }

  get coupon(){
    return getCoupon(this.coupon_code)
  }

  checkCoupon = async() => {		
    let user = this.customer;		
    //console.log("User", user);		
    const coupon = this.coupon;
    if(user && user.email && coupon && coupon.code)		
     return await apiGet('/orderscoupon/'+user.email+'/'+ coupon.code)		
    else		
     return {count: 0}	
  }

  getItems = () => {
    const coupon = this.coupon
    if(this.charityDonation && !this.items.find(x => x.standardSku == charitySku) && !addingCharity){
      addingCharity = true
      this.addToCart({sku: charitySku, standardSku: charitySku, price: 1, qty: 1, retailModifier: 100, series: {}, name: 'Charity Donation', description: 'Charity Donation'})
      setTimeout(() => addingCharity = false, 1000)
    }
    let items = (this.items || [])
    if(!this.charityDonation){
      items = items.filter(x => x.standardSku != charitySku)
    }

    const packingSku = {retailModifier: 100, static: true, packingFee: true}

    // reset packing fee line items
    items = items.filter(x => !x.packingFee)

    // update packing fee line items qty's as needed
    for(let [i, x] of items.slice().entries()){
      const UI = x.length + x.width
      if(UI >= 50) {
        const os = getOversize(UI)
        let updated = false
        items = items.map(i => {
          if(i.sku == os.sku){
            i.price = os.price
            i.qty = i.qty + x.qty
            updated = true
          }
          return i
        })
        if(!updated) items.push(Object.assign({sku: os.sku, price: os.price, qty: x.qty, description: 'Oversize Packing Fee', name: 'Oversize Packing Fee',}, packingSku))
      }
    }

    // return items.filter(x => x.qty > 0).map(x => {
    //   if(x.sku == charitySku) x.charity = true
    //   x.length = x.length ? parseFloat(x.length) : x.length
    //   x.width = x.width ? parseFloat(x.width) : x.width
    //   const valid = validateCoupon(this).valid
    //   //console.log(validateCoupon(this));
    //   //console.log("Testtttttt Xxxxxxx", x);
    //   //console.log("Testtttttt Coupon", coupon);
    //   //console.log("Testtttttt Coupon111", coupon && !coupon.productype);
    //   //console.log("Testtttttt Coupon111", coupon && ((!coupon.productype || coupon.productype == "") && coupon.items.length == 0));
    //   if(coupon && (!coupon.usercount || coupon.usercount == 0 || couponCount.count <= coupon.usercount))		
    //   {		
    //     //console.log("xxxxxx1",couponCount);		
    //     if(valid && (coupon.items || coupon.productype == "Design A Frame" || coupon.productype == "FAS" || !coupon.productype || coupon.productype == "") && ((coupon.productype == "Design A Frame" && x.is_DAF == true) || (coupon.productype == "FAS" && x.fasSku) || (coupon.productype == "Product" && coupon.items.includes(x.series && x.series._id)) || (coupon.productype == "Category" && coupon.items.includes(x.category)) || ((!coupon.productype || coupon.productype == "") && (!coupon.items || coupon.items.length == 0)))){		
    //      //console.log("xxxxxxxx",x);		
    //       x.discount = {type: coupon.type, amount: coupon.amount}		
    //     }		
    //   }
    //   x.label = `${(x.sku || x.fasSku)}-${x.length}x${x.width}-${x.shellOnly}${x.foamCore}`
    //   if(!x.full_description) x.full_description = `${!x.skuOnly ? getSizeLabel(x.length, x.width)+' ' : ''}${x.name}
    //   ${x.color ? `in ${helpers.getTitle(x.color)}` : ''}
    //   ${x.foamCore ? ' (Foam Core)': ''}
    //   ${x.shellOnly ? ' (Shell Only)' : ''}`
    //   console.log("Return X", x);
    //   return x
    // })
    return items.filter(x => x.qty > 0).map(x => {
      if(x.sku == charitySku) x.charity = true
      x.length = x.length ? parseFloat(x.length) : x.length
      x.width = x.width ? parseFloat(x.width) : x.width
      const valid = validateCoupon(this).valid
      //console.log("Coupon", coupon,validateCoupon(this));
      //console.log("Couponx", x);
      //console.log("coupon.items", coupon?coupon.items:"");
      // console.log("Couponx", coupon?(coupon.productype == "Product" && coupon.items.includes(x.series && x.series._id)):"");
      // console.log("Couponx", coupon?(coupon.productype == "Category" && coupon.items.includes(x.category)):"");
      //console.log("Couponx", coupon?(coupon.productype == "Design A Frame" && x.is_DAF == true):"");
      //console.log("Coupon Code",(coupon && coupon.productype == "FAS" && x.fasSku));
      //console.log("Coupon Code",coupon);
      //console.log("Coupon Code1",couponCount);
      if(coupon && (!coupon.usercount || coupon.usercount == 0 || couponCount.count <= coupon.usercount))
      {
        //console.log("xxxxxx1",couponCount);
        if(valid && (coupon.items|| coupon.productype == "Design A Frame" || coupon.productype == "FAS") && ((coupon.productype == "Design A Frame" && x.is_DAF == true) || (coupon.productype == "FAS" && x.fasSku) || (coupon.productype == "Product" && coupon.items.includes(x.series && x.series._id)) || (coupon.productype == "Category" && coupon.items.includes(x.category)))){
         //console.log("xxxxxxxsssssss",x);
          x.discount = {type: coupon.type, amount: coupon.amount}
          //console.log("x.discount",x);
        }
      }
      x.label = `${(x.sku || x.fasSku)}-${x.length}x${x.width}-${x.matDescription?x.description:x.name}-${x.shellOnly}${x.foamCore}`
      if(!x.full_description) x.full_description = `${!x.skuOnly ? getSizeLabel(x.length, x.width)+' ' : ''}${x.name}
      ${x.color ? `in ${helpers.getTitle(x.color)}` : ''}
      ${x.foamCore ? ' (Foam Core)': ''}
      ${x.shellOnly ? ' (Shell Only)' : ''}`
      //console.log('cart New',x);
      return x
    })
  }

  getSummary = () => {
    //console.log("Hi1");
    const coupon = this.coupon
    const fs = this.isFastShip()
    const prices = this.getPrices()
    const items = this.getItems()
    let fasItem = false
    let summary = Object.assign({}, prices, {
      items: items.map(x => {
        if(x.sku == "74136" || x.sku == "74135"){
          fasItem = true
        }
        //console.log('x.is_DAF',x.is_DAF,x.is_Mat,item)
        const priceKey = x.label
        //console.log('xxxx',x);
        let item = {
          pid : (x && x.series)?x.series._id: "",
          //sku: fs ? x.fasSku : (x.sku || x.fasSku),
          sku:(x.is_Mat) ? "11000" : fs && (x.sku != "74136" && x.sku != "74135") ? x.fasSku : (x.sku || x.fasSku),
          qty: x.qty,
          price: prices.map[priceKey],
          rawPrice: prices.rawMap[priceKey],
          name: x.name,
          category: x.category && x.category.name,
          description: fs && (x.sku != "74136" && x.sku != "74135") ? x.fasDescription : x.description,
          full_description: x.full_description,
          matDescription:x.matDescription,
          display_name: x.display_name,
          length: x.length,
          width: x.width,
          custom: x.custom,
          isFas: fs || !x.sku,
          is_DAF: x.is_DAF,
          is_Mat: x.is_Mat,
          color: helpers.getTitle(x.color),
          fast_ship: ((x.fasSku || fasItem) && x.fasInventory && x.fasInventory > 0) ? 'FAS' : '01'
        }
        if(x.shellOnly) item.shellOnly = true
        if(x.foamCore) item.foamCore = true
        if(x.is_DAF) {
          item.DAF_items = x.DAF_items
          item.custom_image = x.custom_image
          item.b2c = x.b2c
        }
        if(x.custom_sku) item.custom_sku = x.custom_sku
        return item
      }),
      fast_ship: fs
    })
    // if(this.charityDonation){
    //   summary.items.push({
    //     sku: charitySku,
    //     qty: 1,
    //     name: 'Charity Donation',
    //     description: 'Charity Donation',
    //     price: this.charityDonation.toFixed(2)
    //   })
    // }

    if (coupon) summary['coupon_code'] = coupon.code;
    if(parseFloat(prices.packing_fee)){
      summary.items.push({
        sku: prices.packing_sku,
        qty: 1,
        name: 'Oversize Shipping Fee',
        description: 'Oversize Shipping Fee',
        price: prices.packing_fee
      })
    }
    console.log("Summary", summary);
    return summary
  }

  isFastShip = () => {
    var Items1 = this.getItems().filter(x => x.sku != "74135" && x.sku != "74136")
    //console.log("fitems",Items1);
    return Items1.find(x => {
      return !x.fasSku || x.fasInventory < x.qty
    }) ? false : true
  }

  isWholesale = () => {
    return this.customer.wholesaleAccount ? true : false
  }


  isTaxable = () => {
    if (this.customer && this.customer._id && !this.customer.taxable) return false;
    const taxRate = getSalesTaxRateByState(this.shipping_country, this.shipping_state);
    return !!taxRate;
  }

  get charityAmount() {
    const charityItem = (this.items || []).find(x => x.sku == charitySku)
    return charityItem ? charityItem.price * charityItem.qty : 0
  }

  getSubtotal = (options) => {
    options = options || {}
    const {wholesale, coupon} = options
    const fs = this.isFastShip()
    let prices = {
      map: {},
      rawMap: {},
      subtotal: 0,
      total: 0,
      maxUI: 0
    }
    const items = this.getItems()
    items.filter(x => x.standardSku != charitySku).forEach(x => {
      let price = (Math.round(((fs && (x.sku != "74135" && x.sku != "74136") ? x.fasPrice : (x.price || x.fasPrice)) * x.qty) * 100) / 100)
      if(x.custom){
        price = getCustomPrice(x.qty, x.length, x.width, x.price, x.shellOnly, x.foamCore)
      }
      if(!wholesale) {
        price = price * ((fs && (x.sku != "74135" && x.sku != "74136") ? x.fasRetailModifier : (x.retailModifier || x.fasRetailModifier)) / 100)
      }
      let discount = 0
      if(coupon && (!coupon.usercount || coupon.usercount == 0 || couponCount.count <= coupon.usercount))
      {
        if(coupon && x.discount){
          if(x.discount.type == 'percent') discount = (price * (x.discount.amount / 100))
          if(x.discount.type == 'fixed') discount = (x.discount.amount || 0)
        }
      }
      const discounted = Math.max(0, price - discount)
      prices.subtotal += discounted
      prices.map[x.label] = discounted.toFixed(2)
      prices.rawMap[x.label] = price.toFixed(2)
      const ui = x.length + x.width
      if(ui > prices.maxUI) prices.maxUI = ui
    })
    const charityItem = items.find(x => x.standardSku == charitySku)
    if(charityItem){
      prices.map[charityItem.label] = this.charityAmount
      prices.rawMap[charityItem.label] = this.charityAmount
    }
    prices.discount = 0
    if(coupon && (!coupon.usercount || coupon.usercount == 0 || couponCount.count <= coupon.usercount))
    {
      if(coupon && ((!coupon.items || coupon.items.length < 1) && coupon.productype != "Design A Frame" && coupon.productype != "FAS")) {
        if(coupon.type == 'percent') prices.discount = prices.subtotal * (coupon.amount / 100)
        if(coupon.type == 'fixed') prices.discount = (coupon.amount || 0)
      }
    }
    return prices
  }

  resetAddresses = () => {
    this.updateData(emptyAddress)
    if(getGlobal('activeCheckout')) getGlobal('activeCheckout').resetAddresses()
  }

  getPrices = () => {
    const coupon = this.coupon
    const st = this.getSubtotal({wholesale: this.isWholesale()})
    const baseSubtotal = st.subtotal + this.charityAmount
    const fs = this.isFastShip()
    let options = {}
    options.wholesale = this.isWholesale()
    if(validateCoupon(this, baseSubtotal).valid)  options.coupon = coupon
    let prices = Object.assign(this.getSubtotal(options), {freight: 0, tax: 0})

    const packing = {fee: 0, sku: undefined}
    prices.packing_fee = packing.fee
    prices.packing_sku = packing.sku
    //prices.shipping = prices.freight
    prices.freight = getShippingCost(this, prices.subtotal + prices.tax - prices.discount) 
    //prices.freight = prices.freight + prices.freight * .078
    prices.shipping = prices.freight
    //console.log('free_shipping1',prices.shipping)
    //console.log("coupon.free_shipping", coupon && coupon.free_shipping);
    if(validateCoupon(this, baseSubtotal).valid && coupon.free_shipping) {
      prices.discount = parseFloat(prices.discount) + parseFloat(prices.shipping)
    }

    if (this.isTaxable()) {
      const priceWithoutTax = parseFloat(prices.subtotal) + parseFloat(prices.shipping) - parseFloat(prices.discount)
      const salesTax = calcSalesTax(this.shipping_country, this.shipping_state, priceWithoutTax);
      if (!!salesTax) prices.tax = parseFloat(salesTax);
    }

    prices.subtotal += (this.charityAmount || 0) + (prices.packing_fee || 0)
    // prices.total = prices.subtotal + prices.tax + prices.shipping - prices.discount
    //console.log('prices=>>>>43',prices,prices.total ,prices.tax,prices.shipping )

    // console.log("prices.subtotal", prices.subtotal)
    // console.log("prices.tax", prices.tax)
    // console.log("prices.shipping", prices.shipping)
    // console.log("prices.discount", prices.discount)
    if(isNaN(prices.subtotal) || prices.subtotal == "NaN") {
      prices.subtotal = 0.00;
    }
    if(isNaN(prices.tax) || prices.tax == "NaN") {
      prices.tax = 0.00;
    }
    if(isNaN(prices.shipping) || prices.shipping == "NaN") {
      prices.shipping = 0.00;
    }
    if(isNaN(prices.discount) || prices.discount == "NaN") {
      prices.discount = 0.00;
    }
    // console.log('prices=>>>>4',prices,prices.total ,prices.tax,prices.shipping,parseFloat(prices.shipping) )
    prices.total = prices.subtotal + prices.tax + parseFloat(prices.shipping) - prices.discount
    //console.log("Prices",prices);
    Object.keys(prices).forEach(key => {
      if(!['map', 'rawMap', 'packing_sku'].includes(key)) prices[key] = parseFloat(prices[key]).toFixed(2)
    })

    return prices
  }

  initCart = async () => {
    if(!this._id){
      const order = await apiGet('/new-order')
      await this.reset(order)
    }
    window.localStorage.setItem('cartToken', this._id)
  }

  addToCart = async (items, options) => {
    var link = window.location.pathname;
    options = options || {}
    if(!Array.isArray(items)) {
      items = [items]
    }
    let error = false
    await this.initCart()
    for(let item of items) {
      let updated = false
      this.items = this.items.map(x => {
        if((x.sku ? x.sku == item.sku : x.fasSku == item.fasSku)
          && x.length == item.length
          && x.width == item.width
          && item.foamCore == x.foamCore
          && item.shellOnly == x.shellOnly
        ) {
          x.qty = options.update ? item.qty : x.qty + item.qty
          if(x.qty == 0 && x.sku == charitySku){
            this.updateData({charityDonation: 0})
          }
          updated = true
        }
        if(!x.sku && x.qty > x.fasInventory) {
          error = 'out of stock'
          notify(`Sorry, we only have ${x.fasInventory} of that item in stock`)
          x.qty = x.fasInventory
        }
        return x
      })
      if(!updated) {
        this.items.push(item)
      }
    }
    await this.updateData({items: this.items.filter(x => x.qty > 0)})
    return error
  }

  setCharity = (amt) => {
    this.charityDonation = amt
    this.updateData({charityDonation: amt})
  }

  setUser = async (user) => {
    this.user = user
    await this.update({user})
  }

  setInfo = async (item) => {
    let update = {}
    for(let type of ['shipping_', 'billing_']) {
      const source = item.billingSame ? 'shipping_' : type
      for(let field of addressFields) {
        update[type+field] = item[source+field]
      }
    }
    update.additional_notes = item.additionalNotes
    update.email = getGlobal('activeCustomer') ? getGlobal('activeCustomer').email : item.email
    return this.update(update)
  }

  getUserDefaults = () => {
    if(!this.user && !getGlobal('activeCustomer')) return {}
    const u = getGlobal('activeCustomer')
    if(!u) return {}
    return {
      shipping: u.addresses.shipping.find(x => x.id == u.defaultShipping) || u.addresses.shipping[0],
      billing: u.addresses.billing.find(x => x.id == u.defaultBilling) || u.addresses.billing[0]
    }
  }

  updateData = async (item) => {
    await this.update(item)
    couponCount = await this.checkCoupon();
    const { subtotal, tax, freight, total, packing_fee, packing_sku, items, fast_ship, discount }  = this.getSummary()
    await this.update({subtotal, tax, freight, total, packing_fee, packing_sku, discount, selectedItems: items, fast_ship,
      taxable: this.isTaxable(), wholesale: this.isWholesale()})
  }

  close = async (transId) => {
    // bing revenue tracking
    window.uetq = window.uetq || [];
    window.uetq.push('event', '', {'revenue_value': this.total, 'currency': 'USD'});

    const cart = new Cart()
    cart.listeners = this.listeners
    setGlobal({activeCart: cart})
    window.localStorage.setItem('cartToken', '')
  }
}



export const loadCart = async () => {
  if(!getGlobal('activeCart')) {
    const token = window.localStorage.getItem('cartToken')
    let cart = {}
    if(token && token != 'undefined'){
      try {
        cart = await apiGet('/orders/'+token)
        if(cart.error){
          cart = {}
          window.localStorage.setItem('cartToken', '')
        }
      } catch(e) {
        console.log(e)
      }
    }
    setGlobal({activeCart: new Cart(cart)})
  }
  return getGlobal('activeCart')
}



const IconContainer = styled.span`
  display: inline-block;
  position: relative;
  .badge{
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    border-radius: 50%;
    min-width: 20px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 11px;
  }
  @media (max-width: 812px) {
    .badge {
      top: -2px;
      right: -2px;
    }
  }
`

export class CartIcon extends React.Component {
  componentDidMount(){
    loadCart().then(cart => {
      cart.registerListener(this)
    })
  }

  componentWillUnmount() {
    if(getGlobal('activeCart')) getGlobal('activeCart').unregisterListener(this)
  }

  render() {
    const cart = getGlobal('activeCart') || {getItems: () => []}
    let qty = 0
    cart.getItems().filter(x=>!x.packingFee).forEach(x => qty += x.qty)

    return <IconContainer>
      <NavLink aria-label='Cart' to='/cart'>
        {/* <span className='fa fa-shopping-cart'/> */}
        <Icon tabIndex={-1} name='shopping-cart' width='30'/>
        {qty ? <div className='badge'><div>{qty}</div></div> : ''}
      </NavLink>
    </IconContainer>
  }
}


// if(module.hot) module.hot.accept()
