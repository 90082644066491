import React from 'react';
import { Image, Snip, getGlobal, setGlobal } from 'launchpad';
import Page from '_shared/layout-page/Page';
import styled from 'styled-components';
import ContentTabLeft from '_shared/partial-content-tab-left';
import theme from 'styles/theme.js';
import { ReviewsCarousel } from 'pages/Reviews';
import { CTA } from 'sections'
import AutoSlider from '_shared/partial-auto-slider/AutoSlider'

// HERO CAROUSEL
let StyledHeroCarousel = styled.div`
  .editable-image {
    height: 100%;
  }
  .carousel-item {
    height: 50vh;
    img {
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .carousel-caption {
      width: 35%;
      height: auto;
      top: 50px;
      left: 90px;
    }
    &.couch,
    &.painter {
      .carousel-caption {
        top: 50px;
        left: auto;
        right: 90px;
      }
    }
    &.painter {
      .carousel-caption {
      }
    }
    &.livingroom {
      .carousel-caption {
      }
    }
    .overlay {
      h1, h2, h3, h4 {
        color: #444;
        text-shadow: none;
      }
    }
  }
  .carousel-caption {
    right: auto;
    bottom: auto;
    text-align: left;
    color: #646f79;
    padding: 60px;
    &.overlay {
      background-color: rgba(255, 255, 255, 0.8);
    }
    h2 {
      * {
        display: inline-block;
      }
    }
    p {
      color: ${theme.colors.blue.dark};
    }
  }

  .slide-content {
    overflow: hidden;
    position: relative;
  }

  &:hover {
    .next, .previous {
      .fa {
        opacity: .9;
      }
    }
    .buttons a {
      background-color: rgba(255, 255, 255, .6)
    }
  }
  @media (max-width: 812px) {
    img {
      height: 60vh;
    }
  }
`;

export class HeroCarousel extends React.Component {
  render() {
    return (
      <StyledHeroCarousel>
        <AutoSlider autoSlide={10000}>
          <div className="carousel-item active couch">
            <img src={require('images/home-slides/couch.jpg')} alt="First slide" />
            <div className="carousel-caption overlay d-none d-md-block">
              <Snip component="h2" name="slide-1-heading" page={this.props.page}></Snip>
              <Snip name="slide-1-content" page={this.props.page}></Snip>
            </div>
          </div>
          <div className="carousel-item active gallery">
            <img src={require('images/home-slides/gallery.jpg')} alt="Second slide" loading="lazy" />
            <div className="carousel-caption overlay d-none d-md-block">
              <Snip component="h2" name="slide-2-heading" page={this.props.page}></Snip>
              <Snip name="slide-2-content" page={this.props.page}></Snip>
            </div>
          </div>
          <div className="carousel-item active livingroom">
            <img src={require('images/home-slides/livingroom.jpg')} alt="Third slide" loading="lazy" />
            <div className="carousel-caption overlay d-none d-md-block">
              <Snip component="h2" name="slide-3-heading" page={this.props.page}></Snip>
              <Snip name="slide-3-content" page={this.props.page}></Snip>
            </div>
          </div>
          <div className="carousel-item active painter">
            <img src={require('images/home-slides/painter.jpg')} alt="Fourth slide" loading="lazy" />
            <div className="carousel-caption overlay d-none d-md-block">
              <Snip component="h2" name="slide-4-heading" page={this.props.page}></Snip>
              <Snip name="slide-4-content" page={this.props.page}></Snip>
            </div>
          </div>
        </AutoSlider>

        {/* <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
            <li data-target="#carouselIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">

          </div>
          <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div> */}
      </StyledHeroCarousel>
    )
  }
}


// BLOG FEED
import Parser from 'rss-parser';

let StyledBlogfeed = styled.div`
  background-color: #F4F9FC;
  padding: 50px 0;
  .container {
    padding: 0;
  }
  #blog-feed-heading {
    margin-bottom: 25px;
  }
  .item {
    margin-bottom: 25px;
    a {
      color: ${theme.colors.blue.mediumDark};
      ${'' /* color: #545556; */}
    }
    .row {
      margin-bottom: 0;
      height: 100%;
    }
    .featured-image {
      border: 1px solid #eee;
      border-bottom: 0;
      padding: 0;
      @media(min-width: 992px) {
        border-left: 0;
        border-bottom: 1px solid #eee;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .post-content {
      padding: 40px 20px;
      background-color: #fff;
      border: 1px solid #eee;
      border-top: 0;
      @media(min-width: 992px) {
        border-right: 0;
        border-top: 1px solid #eee;
      }
    }
  }
  .loading {
    text-align: center;
    padding: 80px;
  }
`;

export class BlogFeed extends React.Component {
  componentDidMount() {
    if (!getGlobal('BlogItems')) {
      let parser = new Parser({
        customFields: {
          item: [
            ['media:content', 'featuredImage']
          ]
        }
      });     
        setTimeout(() => {

          var xhttp = new XMLHttpRequest();
          
          xhttp.open("GET", 'https://blog.frameusa.com/feed', true);
          xhttp.setRequestHeader('Content-Type', 'application/json');
          //xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
          //xhttp.setRequestHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
          xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
              parser.parseString(this.responseText.toString(), (e, feed) => {
                setGlobal({ BlogItems: feed.items })
              })
            }
          };
          xhttp.send();
        }, 500);
    
    }
  }

  render() {
    const items = getGlobal('BlogItems') || []
    return items.length
      ? <StyledBlogfeed>
        <div className='container'>
          <div className="row">
            <h3 className="col-12 text-center"><Snip name="blog-feed-heading" page="home" /></h3>
            {items.slice(0, 4).map((i, index) => <div key={index} className='item col-xs-12 col-md-6'>
              <div className="row flex-row-reverse">
                <a href={i.link} target="_blank" className="featured-image col-xs-12 col-md-5" style={{minHeight: '170px'}}>
                  <img src={i.featuredImage && i.featuredImage.$.url ? i.featuredImage.$.url : 'https://via.placeholder.com/300x300?text=Post+Image'} loading="lazy" alt={i.title} />
                </a>
                <div className="post-content col-xs-12 col-md-7">
                  <a href={i.link} target="_blank"><h4>{i.title}</h4></a>
                  <p>{i.contentSnippet.split(" ").splice(0, 7).join(" ") + ' ...'}</p>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </StyledBlogfeed>

      : <StyledBlogfeed><div className='loading'>Loading...</div></StyledBlogfeed>
  }
}
// END BLOG FEED



const HomeStyles = styled.div`
  .volume {
    padding: 80px 40px;
    .editable-image {
      padding-bottom: 90%;
    }
    h3, p {
      margin-bottom: 25px;
    }
  }
`

export default class Home extends React.Component {
  render() {
    return (
      <Page id='home'>
        <HomeStyles>
          <HeroCarousel />
          <ContentTabLeft page="home" name='home-content-top' />
          <CTA name='main-cta' />

          <div className='container-fluid volume'>
            <div className='row'>
              <div className='col-sm-3'>
                <Image page='home' name='volume-image' />
              </div>
              <div className='col-sm-9'>
                <Snip component='h3' name='mid-heading' />
                <Snip component='p' lorem={50} name='mid-content' />
              </div>
            </div>
          </div>

          <BlogFeed />
          {/* <Testimonials page="home" /> */}
          <ReviewsCarousel id="home" />
        </HomeStyles>
      </Page>
    );
  }
}
