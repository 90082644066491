import React from 'react';
import { Snip, getGlobal, setGlobal, Input, Modal, DataStore, CheckBox,
  apiGet, apiPost, apiUpload, confirm, Paginate, ItemForm,
  notify, apiRequest } from 'launchpad';
import Page from '_shared/layout-page/Page';
import { NavLink } from 'react-router-dom';
import Hero from '_shared/partial-hero/Hero';
import {history} from 'app';
import {confirmDelete} from '_helpers';
import styled from 'styled-components';
import * as _helpers from '_helpers'



const Container = styled.div`
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  h2 {
    margin-bottom: 40px;
    display: flex;
    button {
      margin-left: auto;
      font-size: 16px
      display: inline-flex;
      align-items: center;
    }
  }
  .page-list {
    width: 100%;
  }
  .tools {
    button {
      margin-right: 10px;
      background: none;
    }
  }
  .toggle {
    cursor: pointer;
  }
`

const EditContainer = styled.div``

export class EditForm extends React.Component {
  render = () => {
    const props = {
      schema: {
        name: 'text',
        sku: 'text',
        length: 'number',
        width: 'number',
        min_length: 'number',		
        min_width: 'number',		
        max_length: 'number',		
        max_width: 'number',
        profile: 'text',
        color: 'text',
        color_group: 'text',
        visible: {type: 'boolean', default: true},
        visibleDAF: {type: 'boolean', default: true},
        taxable: 'boolean',
        engravable: 'boolean',
        preview_image: 'text',
        profile_image: 'text',
        corner_image: 'text',
        warehouse: 'text',
        // shipping_24hours: 'boolean'
      }
    }
    return <ItemForm {...props} {...this.props} />
  }
}


const UploadCSVContainer = styled.div`
  text-align: right;
  input[type="file"] {
    display: none
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }
`

class UploadCSV extends React.Component {
  state = {selectedFile: undefined}
  handleUpload = () => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('file', file, file.name);
    apiUpload('/upload-csv', data).then(() => {
      notify('CSV uploaded', {type: 'success'});
    });
  }
  render() {
    return (
      <UploadCSVContainer>
        <label className="custom-file-upload">
          Update products from CSV
          <input type="file" onChange={this.handleUpload} accept=".csv" />
        </label>
      </UploadCSVContainer>
    )
  }
}


export default class SKUManager extends React.Component {
  state = {
    new: false
  }

  componentDidMount() {
    if(!getGlobal('is_admin')){
      window.location.href = '/';
    }
    //loadPages();
  }

  onUpdate = (oldState, newState) => {
    // console.log(newState)
    // if(!oldState.wholesaleAccount && newState.termsAccount){
    //   newState.wholesaleAccount = true
    //   notify('All terms accounts must be wholesale', {type: 'warning'})
    // }
    // if(!newState.wholesaleAccount && oldState.termsAccount){
    //   newState.termsAccount = false
    //   notify('Terms accounts are not available for retail customers', {type: 'warning'})
    // }
    // console.log(newState)
    return newState
  }

  submit = (update) => {
    apiPost('products', update)
    this.setState({edit: null})
    this.paginate.reload()
  }


  render(){
    const pages = (getGlobal('customPages') || []);
    return(
      <Page id='sku-manager' className='padded'>
        <Container>
          <UploadCSV />
          <SKUList submit={this.submit} onDelete={this.paginate && this.paginate.reload} ref={p => this.paginate = p} onEdit={p=>this.setState({edit: p})} />
          <Modal open={this.state.edit} onClose={()=>this.setState({edit: null})}>
            <EditForm item={this.state.edit} onUpdate={this.onUpdate} overrideSubmit={this.submit} />
          </Modal>
        </Container>
      </Page>
    )
  }
}


const deleteSKU = (p, cb) => {
  confirm('Are you sure you want to delete this SKU?', () => {
    apiRequest(`/products/${p._id}`, {}, 'DELETE').then(() => {
      notify('SKU deleted', {type: 'success'})
      if(cb) cb()
    })
  })
}


export const SKUList = React.forwardRef((props, ref) => {
  return <Paginate {...props} ref={ref} collection='products' columns={p => {
    // TODO: need to ensure this is pulled from the actual series/category
    // const categoryUrl = `/${p.category && p.category.name.toLowerCase().replace(/ /g, '-')}`
    // const seriesUrl = `${categoryUrl}/${p.series && p.series.url}`
    const visibleProps = {onClick: props.submit ? () => props.submit({_id: p._id, visible: !p.visible}) : ()=>{} }
    return {
      name: p.name,
      sku: p.sku,
      price: `$${p.price && p.price.toFixed(2)}`,
      // category: p.category && <NavLink to={categoryUrl}>{p.category.name}</NavLink>,
      // series: p.series && <NavLink to={seriesUrl}>{p.series.name}</NavLink>,
      size: `${p.length}x${p.width}`,
      color: p.color,
      color_group: p.color_group,
      // warehouse: p.warehouse,
      // shipping_24hours: p.shipping_24hours ? <span className='fa fa-check' /> : <span className='fa fa-ban' />,
      visible: p.visible ? <span {...visibleProps} className='toggle fa fa-check' /> : <span {...visibleProps} className='toggle fa fa-ban' />,
      tools: <span id={p._id} key={p._id} className='tools'>
        <button className='material-icons' onClick={() => props.onEdit(p)}>edit</button>
        <button onClick={() => deleteSKU(p, props.onDelete)} className='material-icons'>delete</button>
      </span>
    }
  }} />
})


// launchpad_title: SKU Manager
// launchpad_url: admin/skus
// launchpad_admin_panel: true
// launchpad_admin_group: product tools
// launchpad_type: admin
// launchpad_icon: barcode
