import React from 'react';
import {FormControlLabel, Checkbox} from "@material-ui/core";

export class SettingCheckbox extends React.Component {
  render() {
    return (
      <div className='Input'>
        <FormControlLabel
          control={<Checkbox checked={this.props.checked}
                             onChange={e =>  this.props.onChange(e.target.checked ? 'true' : 'false')}/>}
          label={this.props.label}/>
      </div>
    )
  }
}
