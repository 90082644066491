import React from 'react'
import {getGlobal, setGlobal} from 'launchpad'
import { Link } from 'widgets'


/*

  DRAGGABLE LINK
  ====================================
  Draggable object which compensates for certain browser/spec dragging oddities

  You can use onStartDrag vs onDragStart, or onStopDrag vs on DragEnd to
  avoid conflicts with the built in dragging API, but the standard event handlers
  should work well in most cases.

*/

export class DraggableLink extends React.Component {
  // fired when drag is started
  startDrag = (e, link) => {
    // solves quite a few quirks/bugs with native drag API
    if(e) e.stopPropagation()

    // fixes bug with firefox, not actually used
    e.nativeEvent.dataTransfer.setData('text/html', 'fixing firefox')

    // store a reference to the dom element being dragged
    // (no longer used used)
    this.el = e.target

    if(this.props.onStartDrag) this.props.onStartDrag(e)
    if(this.props.onDragStart) this.props.onDragStart(e)

    // let all relevant components know that there's a new active link
    setGlobal({activeMenuLink: link})
  }

  // fired when drag is complete
  endDrag = (e) => {
    // solves quite a few quirks/bugs with native drag API
    if(e) e.stopPropagation()

    // fire events
    if(this.props.onStopDrag) this.props.onStopDrag(e);
    if(this.props.onDragEnd) this.props.onDragEnd(e);

    // let all relevant components know that there's no longer an active link
    setGlobal({activeMenuLink: null});
  }

  render() {
    // allow dev to define which HTML element is used
    let Element = this.props.element || 'div';
    if(this.props.to) Element = Link

    // ignore certain props to avoid conflicts
    let passedProps = Object.assign({}, this.props)
    const skippedProps = ['element', 'onStartDrag', 'onStopDrag', 'link', 'hideWhileDragging']
    skippedProps.forEach(p => {
      delete passedProps[p]
    })

    return <Element {...passedProps}
      draggable
      onDragStart={e=>this.startDrag(e, this.props.link)}
      onDragEnd={this.endDrag}
      >
        {this.props.children}
    </Element>
  }
}
