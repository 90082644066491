import { getSetting, setSetting } from 'launchpad'

let menuId = 0
let linkId = 0

export const getLinkId = () => {
  linkId++;
  return linkId
}

// keep track of all currently available menus
export let menus = {}

export const registerMenu = (menu, id) => {
  if(!menus[id]) menus[id] = []
  menus[id].push(menu)
}

export const unregisterMenu = (m, id) => {
  menus[id] = menus[id] && menus[id].filter(menu => menu != m)
}



// keep track of where links came from, and where they're going
export let targetMenu = null;
export let targetLink = null;
export let activeMenu = null;
export let activeLink = null;

// setters for above
export const setTarget = (type, obj) => {
  if(type=='menu') {
    targetMenu = obj
  } else {
    targetLink = obj
  }
}

export const setActive = (type, obj) => {
  if(type=='menu') {
    activeMenu = obj
  } else {
    activeLink = obj
  }
}

// reset dynamic menu "global" state after an operation is complete
export const resetMenus = () => {
  //console.log('resetting menus ', menus)
  if(targetMenu){
    menus[targetMenu].forEach(m => m.save())
    if(activeMenu && activeMenu != targetMenu){
      menus[activeMenu].forEach(m => m.save())
    }
    activeLink = null;
    activeMenu = null;
    targetLink = null;
    targetMenu = null;
    Object.keys(menus).forEach(key => {
      menus[key].forEach(m => m.cleanUp())
    })
  }
}


// call check function to validate all menus
export const checkMenus = () => {
  Object.keys(menus).forEach(key => {
    menus[key].forEach(m=>m.check())
  })
}

// clean up links coming from the `AutoRoutes` component
export const removeChildren = (menu) => {
  return menu.map(l => {
    if(l){
      let link = Object.assign({}, l)
      delete link.children
      delete link.parent
      return link
    }
  })
}


// save menu to DB as a JSON string
export const setMenu = (key, menu) => {
  if(menu){
    menu = removeChildren(menu)
    setSetting({['dynamicMenus-'+key]: JSON.stringify(menu)})
  }
}

// unpack and validate saved menu
export const getMenu = (key) => {
  let menu = getSetting('dynamicMenus-'+key)
  if(menu) {
    try {
      let menuObj = JSON.parse(menu)
      if(menuObj.length) {
        let ids = []
        menuObj = menuObj.map(l => {
          if(l){
            // ensure unique menu ids
            if(linkId <= l.id){
              linkId = l.id + 1;
            }
            if(!l.id || ids.includes(l.id)){
              l.id = getLinkId()
            }
            ids.push(l.id)
            return l
          }
        })
      }
      return menuObj.filter(l => l ? true : false)
    } catch(e) {
      console.warn('ERROR LOADING MENU (id: '+key+')', e)
    }
    return []
  }
}
