import React from 'react'
import { getGlobal, Modal, Collapsible, Loading, helpers } from 'launchpad'
import { SKUList, EditForm } from 'pages/admin/SKUManager'
import styled from 'styled-components'
import { getProductImage, ProductImage } from 'product_tools'
import { ChromePicker } from 'react-color'
import {C, colorNameToHex} from 'pages/admin/colorUtils'

const Container = styled.div`
  &.container {
    margin-bottom: 50px;
  }

  .show-tools {
    padding: 20px;
    text-align: right;
  }
`

export default class ProductEditor extends React.Component {
  state = {
    newItem: false,
    editing: false,
    showPanels: false,
    openPanel: 'skus'
  }

  openPanel = (openPanel) => {
    if(this.state.openPanel == openPanel) {
      this.setState({ openPanel: ''})
    } else {
      this.setState({ openPanel })
    }
  }

  submitUpdate = async (update) => {
    await this.props.submitUpdate(update)
    this.paginate.reload()
  }

  render() {
    const { edit, newItem, editing, showPanels } = this.state
    const { product, skus } = this.props
    if(!getGlobal('is_admin')) return <span />
    if(!product._id) return <Loading active />
    const isOpen = (panel) => panel == this.state.openPanel

    return <Container>
      <div className='show-tools'>
        <button className='material-icons' onClick={()=>this.setState({showPanels: !showPanels})}>edit</button>
      </div>

      <Collapsible open={showPanels}>
        <div className='container'>
          <button className='btn' onClick={()=>this.openPanel('skus')}><i className='fa fa-edit' /> Edit SKUs</button>
          <button className='btn' onClick={()=>this.openPanel('colors')}><i className='fas fa-palette' /> Edit Colors</button>
          <button className='btn' onClick={()=>this.setState({newItem: true})}>
            <span className='material-icons'>add</span> Add SKU
          </button>
          <Modal open={newItem} onClose={()=>this.setState({newItem: false})}>
            <EditForm overrideSubmit={this.submitUpdate} resetOnSubmit />
          </Modal>

          <Modal open={editing} onClose={()=>this.setState({editing: false})}>
            <EditForm item={editing} overrideSubmit={this.submitUpdate} onDelete={this.refresh} />
          </Modal>

          <Collapsible open={isOpen('skus')}>
            <SKUList ref={p=>this.paginate=p} filters={{series: product._id}} onEdit={p=>this.setState({editing: p})} submit={this.submitUpdate}/>
          </Collapsible>

          <Collapsible open={isOpen('colors')}>
            <ColorEditor product={product} skus={skus} />
          </Collapsible>
        </div>
      </Collapsible>
    </Container>
  }
}




const ColorContainer = styled.div`
  .color-set {
    background: #ddd;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    h3 {
      margin-bottom: 20px;
    }
    display: inline-block;
    width: 48%;
    margin: 1%;
  }
  .swatches {
    width: 100%;
    display: flex;
    .swatch {
      margin: 10px;
      flex: 1;
      padding: 5px;
      background: #555;
      color: #eee;
      text-align: center;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin: 0 1%;
      h5 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .preview {
        position: relative;
        background: white center no-repeat;
        background-size: contain;
        width: 100%;
        padding-bottom: 100%;
        border: 1px solid #777;
        border-radius: 5px;
        cursor: pointer;
        .tools {
          background: rgba(255,255,255,.7);
          position: absolute;
          color: black;
          bottom: 5px;
          right: 5px;
          padding: 2px;
          display: flex;
          flex-direction: column;
          opacity: 0;
          transition: opacity .3s;
          margin: 0;
          button {
            &:not(:last-child){
              margin-bottom: 3px;
            }
            margin: 0;
            background: none;
          }
        }
        &:hover {
          .tools {
            opacity: 1;
          }
        }
      }
    }
  }
`

const ColorPicker = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .color-editor {
    width: 100%;
    justify-content: center;

    display: flex;
    .preview {
      width: 250px;
      height: 250px:
    }
  }
`

class ColorEditor extends React.Component {
  state = {
    editing: null,
    selectedColors: {}
  }

  getImage = (sku, trait) => {
    const {product} = this.props
    const colorMap = product.colorMap || {}
    if(trait == 'color_preview' && !colorMap[trait]) trait = 'corner_image'
    return getProductImage(colorMap[trait] || sku[trait])
  }

  getColorSelection = () => {
    const {skus} = this.props
    const colorMap = {}
    skus.forEach(sku => {
      if(!colorMap[sku.color]){
        let colorTraits = {}
        const traits = ['preview_image', 'corner_image', 'color_preview', 'profile_image', ]
        for(let trait of traits){
          colorTraits[trait] = this.getImage(sku, trait)
        }
        colorMap[sku.color] = colorTraits
      }
    })
    return colorMap
  }

  render() {
    const {product} = this.props
    const colors = this.getColorSelection()
    return <ColorContainer className='color-editor'>

      {Object.keys(colors || {}).map(key => {
        const color = colors[key]
        return <div key={key} className='color-set'>
          <h3>{helpers.getTitle(key)}</h3>
          <div className='swatches'>
            {Object.keys(color).map(c => {
              const image = color[c]
              const background = product.getBackground(key, c)
              return <div key={c} className='swatch'>
                <h5>{helpers.getTitle(c)}</h5>
                <ProductImage product={product} color={key} type={c} />
              </div>
            })}
          </div>
        </div>
      })}


      <div className='image-processor' style={{display: 'none'}}>
        <div className='image'>
          <input ref={i => this.fileInput = i} type='file' onChange={this.readImage} />
          <img style={{display: 'none'}} ref={img=>this.img = img} />
        </div>
      </div>

      <Modal open={this.state.editing} onClose={()=>this.setState({editing: null})}>
        <ColorPicker>
          <h3>{this.state.editing}</h3>
          <div className='color-editor'>
            <ChromePicker
              color={this.state.selectedColors[this.state.editing] || colorNameToHex(this.state.editing || 'white')}
              onChangeComplete={color => this.setColor(color)}
            />
            <div className='preview' style={product.getBackground(this.state.editing)} />
          </div>
        </ColorPicker>
      </Modal>
    </ColorContainer>
  }
}
