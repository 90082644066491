import React from 'react';
//import ReactDOM from '@hot-loader/react-dom';
//import { hot } from 'react-hot-loader/root';

import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { AdminLogin, launchpadInit, setGlobal, getGlobal,
 applyMeta, Modal, ModalContainer, checkAuth, Loading } from 'launchpad';
import { OptionsContainer } from 'sections/options'

import Header from '_shared/layout-header/Header.jsx';

// necessary for hot reloading, might be a good idea to skip in production
//import { pageMap } from 'config/pageImports'
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { ConfirmModal, NotificationContainer, notify, LoadIndicator } from '_helpers';

import { theme, colors } from "styles/theme.js";

import AutoRoutes, { refreshRoutes } from 'config/routes'
import Footer from '_shared/layout-footer/Footer.jsx';
import loadable from '@loadable/component'
import { loadCart } from 'cart_tools'
import styled from 'styled-components';
import { cartConfig } from 'global_config';
import { ImageUploader } from '_helpers'
import { loadUser, setUser } from '_shared/user/UserLogin'

// import FontCss from "styles/font-awesome-all.min.css"
// import{setConfig} from 'react-hot-loader'
// setConfig({logLevel: 'debug'})



// notification testing
// setInterval(() => {
//   const rando = parseInt(Math.random() * 10 + 2)
//   notify(`Here's a message with a number ${rando}`, {duration: rando})
// }, 1500)



// scroll to the top when navigating so page changes aren't disorienting
export const history = createBrowserHistory();
history.listen((location, action) => {
  window.scrollTo(0, 0);
  // if(getGlobal('contextPane')){
  //   getGlobal('contextPane').setPanel(window.location.pathname)
  // }
  if(getGlobal('mainHamburgerOpen')) setGlobal({mainHamburgerOpen: false})
  applyMeta();
  if(window.registerPageView){
    window.registerPageView()
  }
});


setGlobal({ editImages: true })


// a few keyboard shortcuts for common tasks and "super admin" functionality
function checkKeys(e) {
  if(getGlobal('is_admin') || getGlobal('doAdminToggle')){
    var evtobj = window.event ? event : e

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode == 69 && evtobj.ctrlKey) {
      setGlobal({ editImages: !getGlobal('editImages') })
    }

    // use ctrl + e to activate global image editing
    if (evtobj.keyCode == 65 && evtobj.ctrlKey && evtobj.shiftKey) {
      if (getGlobal('is_admin') || getGlobal('doAdminToggle'))
        setGlobal({ is_admin: !getGlobal('is_admin'), doAdminToggle: true })
    }

    if (evtobj.keyCode == 71 && evtobj.ctrlKey) {
      const pane = getGlobal('app').contextPane
      if(pane && pane.showPageSelector) pane.showPageSelector()
    }
  }
}

document.onkeydown = checkKeys


const MUItheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3'
    },
    secondary: {
      main: '#555555'
    }
  },
  typography: {
    useNextVariants: true,
  },
})


const GlobalStyles = styled.div`
  flex: 1;
  display: flex;
  a:not([href]):not([tabindex]) {
    color: ${colors.blue.mediumLight};
  }
  .devMessage {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: rgba(150, 0, 0, .8);
    padding: 10px 30px;
    color: white;
  }
  .offline {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    h2 {
      margin-bottom: 30px;
    }
  }
`

// Main app class
class App extends React.Component {
  state = {
    is_admin: false
  }

  contextPane = <span />
  mediaLibrary = <span />
  adminBar = <span />

  componentDidMount() {
   
    
    //setTimeout(() => {
    checkAuth();
    loadUser();
    refreshRoutes();
    //}, 300)
    setGlobal({mainDomRoot: this.root, app: this}, false)
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(([key, val]) =>
    //   prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    // );
    // Object.entries(this.state).forEach(([key, val]) =>
    //   prevState[key] !== val && console.log(`State '${key}' changed`)
    // );
    if((getGlobal('is_admin') || getGlobal('user_access') == 'admin' ) && !this.state.is_admin){
      this.setState({is_admin: true})
      const MediaLibrary = loadable(() => import('launchpad/admin/MediaLibrary'))
      const ContextPane = loadable(() => import('launchpad/admin/ContextPane'))
      const AdminBar = loadable(() => import('launchpad/admin/AdminBar'))
      this.mediaLibrary = <MediaLibrary fallback={<Loading active={true}/>} />
      this.contextPane = <ContextPane fallback={<Loading active={true}/>} />
      this.adminBar = <AdminBar fallback={<Loading active={true}/>} />
    }
  }


  render() {
    const devMode = cartConfig.skipAuthnet || cartConfig.skipGP || cartConfig.testMode
    const is_admin = getGlobal('is_admin') || (getGlobal('user_access') == 'admin')
    if(getGlobal('noAPIConnection')) return <GlobalStyles><div ref={el => this.root = el} className='offline'>
      <h2>Sorry, it looks like we're offline at the moment</h2>

      <p>We're either performing maintenance or experiencing technical difficulties. Either way, we'll be back shortly!</p>
    </div></GlobalStyles>
    return (


      // <Helmet> <link rel="canonical" href="FontCss" /></Helmet>
      
      <MuiThemeProvider theme={MUItheme}>
        <GlobalStyles>
          <Router history={history}>
            {/* maybe move to <LaunchpadWrapper /> or similar to provide classes */}
            <div className={"root-wrapper "
              + (getGlobal('editImages') ? ' edit-images ' : '')
              + ((getGlobal('openContext') && is_admin) ? ' context-open ' : '')
              + (getGlobal('pageContext') || 'generic') + '-wrapper '
              + (is_admin ? ' is-admin' : ' test')}
              id='rootWrapper' ref={el => this.root = el && el.el} >
              <NotificationContainer />
              <ConfirmModal />
              <ImageUploader />
              {this.contextPane}
              {this.adminBar}
              {this.mediaLibrary}
              <Header />
              {/* <Switch>
                <Route key = 'about' exact path='/about' component={require('pages/About').default} />,
              </Switch> */}

              <AutoRoutes />
              <Footer key={'footer'} />
              <OptionsContainer />
              <ModalContainer/>
              <LoadIndicator />
              {devMode && <div className='devMessage'>WARNING!! This app is in testing mode, please change global_config before pushing to production</div>}
            </div>
          </Router>
        </GlobalStyles>
      </MuiThemeProvider>
    );
  }
}

//export default hot(App)
export default App;
//// if(module.hot) module.hot.accept()
