import React from 'react';
import {toggleMedia, setGlobal, getGlobal, setSetting, getSetting} from 'launchpad';
import TextField from '@material-ui/core/TextField'


export class Input extends React.Component {
  render() {
    return (
      <div className='Input'>
        <TextField {...this.props} onChange={(e) => this.props.onChange(e, e.target.value)} />
      </div>
    )
  }
}
