import React from 'react'
import {icons} from 'images/icon_list.js'
import styled from 'styled-components'

const IC = styled.div`
  display: inline-block;
  position: relative;
`

const iconSet = icons || {}

export class Icon extends React.Component {
  state = {height: '30px'}

  componentDidMount = () => {
    // compensating for styled-components FOUC
    const intvl = setInterval(this.updateStyles, 500)
    setTimeout(()=>clearInterval(intvl), 10000)

    this.updateStyles()
  }
  componentDidUpdate = () => this.updateStyles()

  updateStyles = () => {
    if(this.svg){
      const styles = window.getComputedStyle(this.svg)
      const color = styles.getPropertyValue('color')
      const height = styles.getPropertyValue('font-size')
      if(height != this.state.height) this.setState({height})
      let doc;
      try {
        doc = this.svg.getSVGDocument();
      } catch(e) {
        doc = this.svg.documentElement;
      }

      if(doc) {
        const els = doc.querySelectorAll('*')
        for(let x of Array.from(els)){
          if(!(['g']).includes(x.tagName)){
            const c = this.props.color || color
            x.setAttribute('fill', c)
            x.setAttribute('stroke', c)
          }
          if(x && x.style)
            x.style.pointerEvents = 'none'
        }
      }
    }
  }

  render(){
    const props = this.props
    const size = props.size ? props.size + 'px' : this.state.height
    return <IC style={{width: size, height: size}} {...props} className={`svg-icon ${props.name}`}>
      <object
        tabIndex={-1}
        scrolling='no'
        ref={x=>this.svg=x}
        data={icons[props.name]}
        width={size}
        style={{maxHeight: size, pointerEvents: 'none', position: 'absolute', top: 0, left: 0}}
      >
        {!icons[props.name] && '??'}
      </object>
    </IC>
  }
}
