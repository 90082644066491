import React from 'react';
import styled from 'styled-components';
import { Image, Snip, getSnip } from 'launchpad';
import ItemList from '_shared/ItemList'

let StyledContentTabLeft = styled.div`
  .snip-container {
    display: inline-block;
  }
  .tab-pane {
    .row {
      .col-sm-6 {
        margin-bottom: 15px;
      }
    }
  }

  .quad-block {
    display: flex;
    flex-wrap: wrap;
    .block {
      width: 50%;
      padding: 10px 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: flex-start;
      .editable-image {
        padding-bottom: 25%;
        width: 25%;
        flex-shrink: 0;
      }
      .snip-container {
        min-width: 100px;
        margin-left: 20px;
      }
    }
    @media (max-width: 812px){
      .block {
        width: 100%;
      }
    }
  }

  .tab-2 {
    .editable-image {
      width: 100%;
      padding-bottom: 100%;
    }
  }
  .list {
    ol {
      li::after {
        @media (max-width: 812px){
          content: "+";
          font-size: 28px;
          line-height: 28px;
          right: 0;
          position: absolute;
          height: 32px;
          width: 32px;
          padding: 0 6px;
          top: 1px;
        }
      }
      li.active::after {
        @media (max-width: 812px){
          content: "-";
          padding: 0 8px;
        }
      }
      .indicator-bar {
        @media (max-width: 812px){
          display: none;
        }
      }
    }
  }
  .items {
    .slideshow {
      @media (max-width: 812px){
        display: none;
      }
    }
  }
`;


const Block = props => <div className="block">
  <Image name={props.name+'-block-image'} page={props.page} />
  <Snip name={props.name+'-block-text'} page={props.page} />
</div>

const QuadBlock = props => <div className='quad-block'>
  <Block name={props.name+'-block-1'} page={props.page} />
  <Block name={props.name+'-block-2'} page={props.page} />
  <Block name={props.name+'-block-3'} page={props.page} />
  <Block name={props.name+'-block-4'} page={props.page} />
</div>

export default class ContentTabLeft extends React.Component {
  name = (n) => {
    return `${this.props.name}-${n}`
  }


  componentDidMount() {
    //var slides = document.getElementsByClassName()
    if(document.documentElement.clientWidth < 813) {
      document.getElementsByClassName('slideshow')[1].setAttribute("style", "display: none");
    }
    var nodes = document.getElementsByClassName("list")[0].lastChild;
    var children  = nodes.childNodes;
    children.forEach(child => {
      setTimeout(function() {
        child.classList.remove("active");
      }, 500)
      
      child.addEventListener("click", function(event) {
        if(this.className.indexOf('active') == -1) {
          document.getElementsByClassName('slideshow')[1].setAttribute("style", "display: block");
          var style  = document.getElementsByClassName('indicator-bar')[0].getAttribute("style");
          style+= "display: block";
          document.getElementsByClassName('indicator-bar')[0].setAttribute("style", style);
          this.className += " active";
        } else {
          document.getElementsByClassName('slideshow')[1].setAttribute("style", "display: none");
          var style  = document.getElementsByClassName('indicator-bar')[0].getAttribute("style");
          style = style.replace("display: block", "");
          document.getElementsByClassName('indicator-bar')[0].setAttribute("style", style);
          this.classList.remove("active");
        }
      });
    })
  }
  render() {
    return (
      <StyledContentTabLeft>
        <ItemList numbered className='container'>
          <QuadBlock name={this.name('quadblock-1')} />

          <div className="tab-2 row align-items-lg-center">
            <div className="col-lg-5 mb-7 mb-7 mb-lg-0">
              <Image className="img-fluid" src="https://via.placeholder.com/350x350?text=Sample+Image" name={this.name('tab-2-image')} />
            </div>

            <div className="col-lg-7">
              {/* Description */}
              <div className="pl-lg-4">
                <Snip component='h3' name={this.name('tab-2-heading')} page={this.props.page} />
                <Snip name={this.name('tab-2-main-content')} page={this.props.page} />
              </div>
              {/* End Description */}
            </div>
          </div>

          <QuadBlock name={this.name('quadblock-2')} />
        </ItemList>
      </StyledContentTabLeft>
    )
  }
}
