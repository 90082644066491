import React from 'react';

export default class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    this.divs = [];
    this.totalSlides = 0;
    this.state = {
      slide: 1,
      height: 'auto'
    }
  }

  componentWillMount() {
    this.reset(this.props);
  }

  componentWillReceiveProps(props) {
    this.reset(props);
  }

  reset(props) {
    if (props.slide) {
      this.setState({
        slide: props.slide
      }, () => this.setHeight());
    }
  }

  setHeight() {
    if (this.divs[this.state.slide - 1] && this.props.dynamicHeight) {
      this.setState({
        height: (this.divs[this.state.slide - 1].getBoundingClientRect().height) + 'px'
      })
    }
  }

  render() {
    this.divs = [];
    let left = '-' + ((this.state.slide - 1) * 100) + '%';
    let currentSlide = 0;
    return (
      <div className='slideshow'>
        <div className='slide-container' style={{ left: left, height: this.state.height }}>
          {(this.props.children || []).map(
            child => {
              currentSlide += 1;
              return (
                <div className='slide' key={currentSlide}>
                  <div className='slide-content' ref={div => { if (div) { this.divs.push(div) } }}>{child}</div>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }
}