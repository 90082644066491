import { getGlobal, setGlobal, apiGet, apiPost } from 'launchpad'
import { ProductContainer } from './product'
import { findProducts, relLink } from './index'
import { getSlug } from '_helpers'

let categories = {}

export class Category extends ProductContainer {
  collection = "categories"

  get url() {
    //console.log(this.source)
    return this.source.url || relLink(this.source && (this.source.name || '').replace(/ /g, '-').toLowerCase())
  }

  // set is a no-op for now, may dynamically store later on
  set url(update) {}

  get hasFas() {
    return findProducts({category: this._id, fasOnly: true}).length > 0
  }

  isMatch = (x, series, subcategory) => {
    series = series._id || series
    return (!series || x.series == series) && (!subcategory || x.subcategory == subcategory)
  }

  getImage = (series, subcategory) => {
    const tn = (this.thumbnails || []).find(x => this.isMatch(x, series._id || series, subcategory))
    return tn ? tn.url : ''
  }

  getBackground = (series, subcategory) => {
    return {backgroundImage: `url(${this.getImage(series._id || series, subcategory)})`}
  }


  setImage = (series, subcategory, url) => {
    let match = false
    let thumbnails = (this.thumbnails || []).map(tn => {
      if(this.isMatch(tn, series._id || series, subcategory)){
        tn.url = url
        match = true
      }
      return tn
    })
    if(!match) thumbnails.push({series: series._id || series, subcategory, url})
    this.update({thumbnails})
  }

  getSubcategory = (subcategory) => {
    return this.findProducts({}, {sortMap: this.subcategory_sorting && this.subcategory_sorting[subcategory.name] ? this.subcategory_sorting[subcategory.name] : []})
      .filter(x => subcategory.name == 'All Wood Frames' || x.subcategories.includes(subcategory.name || subcategory))
  }

  getSubcategories = () => {
    let subs = this.subcategories.slice()
    if(this.name == 'Wood Frames') subs = (['All Wood Frames']).concat(subs)
    return subs.map(s => {
      const products = this.getSubcategory(s)
      const slug = getSlug(s)
      return {
        _id: slug,
        name: s,
        url: slug,
        color_groups: [... new Set(products.map(x => x.color_groups.join(',')))],
        sizes: [... new Set([].concat.apply([], products.map(x=>x.sizes)))],
        profiles: [... new Set([].concat.apply([], products.map(x=>x.profiles)))],
        category: {name: this.name || '', _id: this._id},
        total_skus: products.map(x => x.total_skus).reduce((total, sku) => total = sku + total, 0)
      }
    }).filter(x => x.total_skus > 0 || x.name == 'All Wood Frames')
  }

  findProducts = (obj, options) => {
    options = options || {}
    if(!this._id) return []
    const products = findProducts(Object.assign((obj || {}), {category: this._id, checkFas: true})).filter(x => x.total_skus).map(x => {
      return Object.assign({}, x, {series: x._id})
    })
    if(Array.isArray(options.sortMap)){
      return this.sortProducts(products, options.sortMap)
    }
    return products
  }

  sortProducts = (products, sortMap) => {
    if(!sortMap) return products
    let sortedProducts = []
    let unsortedProducts = []
    for(let x of sortMap){
      for(let p of products) {
        if(p.subcategory == x || p._id == x){
          sortedProducts.push(p)
        }
      }
    }
    for(let p of products){
      if(!sortedProducts.includes(p)) unsortedProducts.push(p)
    }
    return sortedProducts.concat(unsortedProducts)
  }

  getChildren = (subcategory) => {
    if(!this._id) return []
    let products = this.findProducts({}, {sortMap: this.sort_order})
    if(!subcategory) {
      products = this.sortProducts(this.getSubcategories()
      .map(x => Object.assign({}, x, {url: x.url+'-category', subcategory: x.name}))
      .concat(products.filter(x => !x.subcategories[0])), (this.sort_order || []))
    } else {
      products = this.getSubcategory(subcategory)
    }
    return products
  }
}




// use this to load a category object
export const loadCategory = async (id) => {
  if(!categories[id]) categories[id] = new Category(await apiGet('/categories/'+id))
  return categories[id]
}
