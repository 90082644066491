const routes = [
  "https://www.frameusa.com",
  "//www.frameusa.com/pages/livechat",
  "//www.frameusa.com/",
  "//www.frameusa.com/metal-frames",
  "//www.frameusa.com/wood-frames",
  "//www.frameusa.com/poster-frames",
  "//www.frameusa.com/shadow-box-frames",
  "//www.frameusa.com/design-a-frame",
  "//www.frameusa.com/24-hr-shipping",
  "//www.frameusa.com/cdn-cgi/l/email-protection",
  "//www.frameusa.com/diploma-frames",
  "//www.frameusa.com/product/3199/gift-card",
  "//www.frameusa.com/Templates/Shared/images/noimage-transparent.png",
  "//www.frameusa.com/metal-frames/frame-samples",
  "//www.frameusa.com/poster-frames/frame-samples",
  "//www.frameusa.com/shadow-box-frames/frame-samples",
  "//www.frameusa.com/clip-frames",
  "//www.frameusa.com/product/124/picture-frame-hardware",
  "//www.frameusa.com/bent-acrylics",
  "//www.frameusa.com/u-frame-kit-ii-picture-frames",
  "//www.frameusa.com/product/93/u-frame-kit-i-picture-frames",
  "//www.frameusa.com/glory-metal-picture-frames",
  "//www.frameusa.com/heavy-metal-picture-frames",
  "//www.frameusa.com/product/67/metal-i-picture-frames",
  "//www.frameusa.com/metal-ii-picture-frames",
  "//www.frameusa.com/hangers-accessories",
  "//www.frameusa.com/heavy-metal-certificate-picture-frames",
  "//www.frameusa.com/metal-certificate-picture-frames",
  "//www.frameusa.com/deluxe-certificate-picture-frames",
  "//www.frameusa.com/economy-certificate-picture-frames",
  "//www.frameusa.com/boardroom-certificate-picture-frames",
  "//www.frameusa.com/designer-picture-frames",
  "//www.frameusa.com/windsor-pecan-picture-frames",
  "//www.frameusa.com/windsor-mahogany-picture-frames",
  "//www.frameusa.com/oak-picture-frames",
  "//www.frameusa.com/step-oak-picture-frames",
  "//www.frameusa.com/architect-picture-frames",
  "//www.frameusa.com/heartland-picture-frames",
  "//www.frameusa.com/classic-picture-frames",
  "//www.frameusa.com/in-depth-picture-frames",
  "//www.frameusa.com/corporate-picture-frames",
  "//www.frameusa.com/product/3360/glass-plastic-cleaner",
  "//www.frameusa.com/cbg-picture-frames",
  "//www.frameusa.com/corporate-thin-picture-frames",
  "//www.frameusa.com/economy-picture-frames",
  "//www.frameusa.com/product/26/cafe-beveled-picture-frames",
  "//www.frameusa.com/unfinished-picture-frames",
  "//www.frameusa.com/black-leather-picture-frames",
  "//www.frameusa.com/americana-picture-frames",
  "//www.frameusa.com/cafe-picture-frames",
  "//www.frameusa.com/baroque-picture-frames",
  "//www.frameusa.com/product/1726/floater-frames",
  "//www.frameusa.com/shadow-box-elite-picture-frames",
  "//www.frameusa.com/shadow-box-showcase-picture-frames",
  "//www.frameusa.com/corporate-wide-picture-frames",
  "//www.frameusa.com/colori-medium-picture-frames",
  "//www.frameusa.com/product/15/barnwood-picture-frames",
  "//www.frameusa.com/corporate-rounded-picture-frames",
  "//www.frameusa.com/product/96/value-added-picture-frames",
  "//www.frameusa.com/product/1218/affordable-picture-frames",
  "//www.frameusa.com/american-value-thin-picture-frames",
  "//www.frameusa.com/clean-cut-picture-frames",
  "//www.frameusa.com/product/29/cafe-wide-picture-frames",
  "//www.frameusa.com/coffee-bean-picture-frames",
  "//www.frameusa.com/cafe-rounded-picture-frames",
  "//www.frameusa.com/shadow-box-frames/petg-acrylic",
  "//www.frameusa.com/decorate-it-picture-frames",
  "//www.frameusa.com/product/3463/petg-acrylic",
  "//www.frameusa.com/black-picture-frames",
  "//www.frameusa.com/brown-leather-picture-frames",
  "//www.frameusa.com/grande-picture-frames",
  "//www.frameusa.com/modern-living-picture-frames",
  "//www.frameusa.com/shadow-box-boxers-picture-frames",
  "//www.frameusa.com/colori-large-picture-frames",
  "//www.frameusa.com/verona-wide-picture-frames",
  "//www.frameusa.com/product/3426/barnwood-frame-serving-tray",
  "//www.frameusa.com/abstract-picture-frames",
  "//www.frameusa.com/barnwood-appalachian-picture-frames",
  "//www.frameusa.com/product/127/barnwood-farmhouse-picture-frames",
  "//www.frameusa.com/frame-samples",
  "//www.frameusa.com/american-value-medium-picture-frames",
  "//www.frameusa.com/bistro-picture-frames",
  "//www.frameusa.com/economy-gold-picture-frames",
  "//www.frameusa.com/american-value-large-picture-frames",
  "//www.frameusa.com/napoleon-picture-frames",
  "//www.frameusa.com/simply-gold-picture-frames",
  "//www.frameusa.com/rustic-picture-frames",
  "//www.frameusa.com/american-hardwoods-dark-walnut-picture-frames",
  "//www.frameusa.com/american-hardwoods-wheat-oak-picture-frames",
  "//www.frameusa.com/executive-picture-frames",
  "//www.frameusa.com/product/3354/ez-hinge-hardware",
  "//www.frameusa.com/voluptuous-picture-frames",
  "//www.frameusa.com/tuxedo-picture-frames",
  "//www.frameusa.com/rustic-wide-picture-frames",
  "//www.frameusa.com/carbon-fiber-picture-frames",
  "//www.frameusa.com/verona-narrow-picture-frames",
  "//www.frameusa.com/modena-picture-frames",
  "//www.frameusa.com/state-room-picture-frames",
  "//www.frameusa.com/product/3041/barnwood-signature-series",
  "//www.frameusa.com/product/3088/barnwood-shadowbox-picture-frames",
  "//www.frameusa.com/old-world-picture-frames",
  "//www.frameusa.com/victorian-picture-frames",
  "//www.frameusa.com/presidential-picture-frames",
  "//www.frameusa.com/antique-royal-picture-frames",
  "//www.frameusa.com/product/102/weathered-picture-frames",
  "//www.frameusa.com/product/74/new-world-profile-1-picture-frames",
  "//www.frameusa.com/product/75/new-world-profile-2-picture-frames",
  "//www.frameusa.com/olympic-picture-frames",
  "//www.frameusa.com/american-value-silver-picture-frames",
  "//www.frameusa.com/stainless-picture-frames",
  "//www.frameusa.com/hardware-bronze-picture-frames"
]

module.exports.default = routes