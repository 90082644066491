import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 30px;
  display:inline-block;
  vertical-align:middle;
  width: calc(${props => props.responsive ? '100%' : (props.width || '100px')} - 60px);
  box-sizing: border-box;
  .slider-x {
    position:relative;
    height:20px;
    background:#ddd;
    .value {
      height:20px;
      background:#1B45D5;
    }
    .handle {
      position: absolute;
      top:-5px;
      left:-5px;
      height:30px;
      width:30px;
      margin-left:-15px;
      background:#1B45D5;
      border:3px solid #ddd;
      border-radius: 50%;
      color:white;
      cursor:pointer;
    }
  }
`

export class Slider extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      percentage: (this.props.initialValue / (this.props.max || 100)) * 100
    }
    this.handleSlide = this.slide.bind(this);
    this.handleEndSlide = this.endSlide.bind(this);
  }

  startSlide(e) {
    this.startX = e.pageX
    document.addEventListener('mousemove', this.handleSlide);
    document.addEventListener('mouseup', this.handleEndSlide);
  }

  endSlide(e) {
    document.removeEventListener('mousemove', this.handleSlide);
    document.removeEventListener('mouseup', this.handleEndSlide);
  }

  getValue() {
    let percent = this.state.percentage;
    let min = this.props.min ? this.props.min : 0;
    let max = this.props.max ? this.props.max : 100;
    return ((max - min) * (percent / 100)) + min
  }

  getPercentage() {
    if(this.props.value){
      let min = this.props.min ? this.props.min : 0;
      let max = this.props.max ? this.props.max : 100;
      return(( this.props.value / (max - min) ) * 100);
    } else {
      return this.state.percentage;
    }
  }

  slide(e) {
    this.slider.focus()
    let box = this.slider.getBoundingClientRect();
    let min = box.left;
    let max = box.right;
    let value = e.pageX - box.left;
    let width = box.right - box.left;
    value = value > width ? width : value;
    value = value < 0 ? 0 : value;
    let percent = (value / width) * 100;
    this.setState({percentage: percent}, () => {
      if(this.props.onChange){
        this.props.onChange(this.getValue());
      }
    });
  }


  render(){
    const {responsive, className} = this.props
    return(
      <Container responsive={responsive} className={'slider-container '+(className || '') }>
        <div className='slider-x' ref={div => this.slider = div}>
          <div style={{width: this.getPercentage()+'%'}} className='value' />
          <div className='handle material-icons' ref={div => this.handle = div}
            onMouseDown={e => {this.startSlide(e)}}
            style={{left:this.getPercentage() + '%'}}
          >{this.props.icon ? this.props.icon : ''}</div>
        </div>
      </Container>
    )
  }
}


// if(module.hot) module.hot.accept()
