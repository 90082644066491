import React from 'react'
import Stub from 'pages/Stub'
import { apiGet, getGlobal, Modal, apiPost, Snip, Collapsible } from 'launchpad'
import { NavLink } from 'react-router-dom'
import Hero from '_shared/partial-hero/Hero';
import Page from '_shared/layout-page/Page';
import styled from 'styled-components';
import { findProducts, ProductImage, loadCategory, Category, BreadCrumbs } from 'product_tools'
import { refreshRoutes } from 'config/routes'
import { EditForm } from 'pages/admin/ProductManager'
import { ProductGrid } from 'templates/Search'
import { Link } from 'widgets'

const Container = styled.div`
  .description.container {
    margin: 30px auto 0px;
    @media(max-width: 812px) {
      display: none;
    }
  }
  .description-xs.container {
    margin: 30px auto 10px;
    @media(min-width: 813px) {
      display: none;
    }
  }

  .search-header {
    display: flex;
    align-items: center;
    .bread-crumbs {
      margin-right: auto;
    }
  }
  .product-grid {
    padding-top: 0px;
    @media(max-width: 812px) {
      padding-bottom: 20px
    }
  }
  @media(min-width: 813px) {
    .mobile-collapse {
      display: none;
    }
  }
  .mobile-collapse {
    padding-bottom: 50px;
    h3 {
      padding: 10px;
      padding-right: 30px;
      margin-top: 0px;
      color: white;
      font-size: 18px;
      background-color: rgb(38, 63, 143);
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: white;
    }
  }
`

export default class ProductCategory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: new Category(),
      new: false,
      mobile_menu: ''
    }
  }

  componentDidMount() {
    loadCategory(this.props.category.id).then(category => {
      category.registerListener(this)
      this.setState({ category })
    })
  }

  componentWillUnmount() {
    this.state.category.unregisterListener(this)
  }

  submit = (update) => {
    update.category = this.props.category.id
    apiPost('series', update)
    this.setState({new: false})
    refreshRoutes()
  }

  render(){
    const { subcategory } = this.props
    const { category } = this.state
    const products = category.getChildren(subcategory)

    const gridProps = {products, category, subcategory}

    const pageID = 'category-'+category._id
    return <Page id={subcategory ? `${pageID}-subcategory-${subcategory.name}` : pageID}>
      <Container>
        <Hero half page={pageID}>
          <h1>{subcategory ? subcategory.name : category.name}</h1>
        </Hero>
        {getGlobal('is_admin') && <div className='text-right'>
          <button className='btn' onClick={()=>this.setState({new: true})}>
            <span className='material-icons'>add</span> Add Product Series
          </button>
          <Modal open={this.state.new} onClose={()=>this.setState({new: false})}>
            <EditForm overrideSubmit={this.submit}/>
          </Modal>
        </div>}
        <div className='container description hidden-xs'>
          <Snip key={'intro'+category._id} name='intro-description' lorem={44} />
        </div>
        <div className='container'>
          <div className='search-header'>
            <BreadCrumbs links={[{link: subcategory ? category.url : '', label: category.name}, subcategory && {label: subcategory.name}].filter(x=>!!x)} />
          </div>
          <ProductGrid {...gridProps} hideFas={!category || !category.hasFas} />
        </div>
        <div class="mobile-collapse">
        <h3 onClick={()=>this.setState({mobile_menu: this.state.mobile_menu == 'details' ? '' : 'details'})}>Details</h3>
        <Collapsible open={this.state.mobile_menu == 'details'}>
          <div className='description'>
          <div className='container description-xs'>
            <Snip key={'intro'+category._id} name='intro-description' lorem={44} />
          </div>
          </div>
        </Collapsible>
        </div>
      </Container>
    </Page>
  }
}
