import React from 'react'
import { apiGet, apiPost, getGlobal, setGlobal, Snip } from 'launchpad'
import styled from 'styled-components'
import Slideshow from '_shared/partial-slideshow/Slideshow';
import theme from 'styles/theme.js';

const activeColor = theme.colors.blue.dark;

const ItemListContainer = styled.div`
  display: flex;
  padding: 40px 0;
  .list {
    box-shadow: 26px 0 26px -12px rgba(100, 111, 121, 0.06);
    position:relative;
    h3 {
      margin-bottom: 40px;
      padding-right: 30px;
    }

    ul, ol {
      padding-left: 0;
      position: relative;
    }
    li {
      display: block;
      padding: 10px;
      padding-right: 30px;
      position: relative;
      cursor: pointer;
      padding-left: 0;
      .number {
        min-width: 20px;
        display: inline-block;
      }
      .indicator-bar {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4px;
        border-radius: 2px;
        background: ${activeColor};
        opacity: 0;
        transition: opacity .5s;
      }
      &.active {
        color: ${activeColor};
        .indicator-bar {
          opacity: 1;
        }
      }
    }
    .side-bar {
      position: absolute;
      top: 0;
      right: 0;
      width: 4px;
      border-radius: 3px;
      background: #eee;
      height: 100%;
    }

    .indicator-bar {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 4px;
      border-radius: 2px;
      background: ${activeColor};
      transition: opacity .5s;
      z-index: 2;
      min-height: 4px;
      transition: all .3s cubic-bezier(0.875, -0.170, 0.235, 1.260);
    }
  }
  .items {
    .item-container {
      padding: 0 30px;
    }
  }

  &.collapsed {
    display: block;
    ul, ol {
      border-bottom: 1px solid #ddd;
      padding: 15px;
    }
    .heading {
      padding: 15px;
    }
    li {
      display: block;
      padding:5px;
      margin-bottom: 5px;
      margin-right: 10px;
    }
    .side-bar {
      display: none;
    }
    .list {
      box-shadow: 0 26px 26px -12px rgba(100, 111, 121, 0.06)
    }
    .items {
      .item-container {
        padding: 0;
      }
    }
  }
`


export default class ItemList extends React.Component {
  state = {
    'activeItem': 0
  }

  componentDidMount() {
    // using this to re-render after refs are set
    this.setState({activeItem: 1})
    window.addEventListener('resize', () => this.checkSize());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.checkSize())
  }

  sizeTimer = null;

  checkSize = () => {
    if(this.sizeTimer) clearTimeout(this.sizeTimer)
    this.sizeTimer = setTimeout(() => {
      this.forceUpdate()
      // have to check twice so positioning works properly
      setTimeout(()=>this.forceUpdate(), 100)
      this.sizeTimer = null;
    }, 100)
  }

  render() {
    if(!this.tabs) this.tabs = {}
    const activeTab = this.tabs[this.state.activeItem]
    const collapsed = this.container && this.container.offsetWidth < 1000;

    let sizeProp, size, locProp, loc, fixedProp;
    if(activeTab){
      if(collapsed){
        sizeProp = 'width'
        fixedProp = 'height'
        size = activeTab.offsetWidth - 20
        locProp = 'top'
        loc = activeTab.offsetTop + activeTab.offsetHeight
      } else {
        sizeProp = 'height'
        fixedProp = 'width'
        size = activeTab.offsetHeight
        locProp = 'top'
        loc = activeTab.offsetTop
      }
    }

    let indicatorStyle = (loc || loc === 0) ? {[locProp]: loc + 'px', [sizeProp]: size + 'px', [fixedProp]: '4px'} : {}
    if(collapsed && activeTab) indicatorStyle.left = activeTab.offsetLeft + 10


    return <ItemListContainer className={this.props.className + (collapsed ? ' collapsed' : '')} innerRef={el => this.container = el}>
      <div className='list' style={this.props.fluid ? {} : {flexBasis: '33%'}}>
        <div className='side-bar' />
        {this.props.title
          ? <h3 className='heading'>{this.props.title}</h3>
          : <Snip className='heading' component='h3' page={this.props.page} name={this.props.name + '-heading'} />
        }
        <ol>
          {this.props.children.map((child, i) => {
            return <li key={i}
              className={this.state.activeItem == i + 1 ? 'active' : ''}
              onClick={() => this.setState({ activeItem: i + 1 })}
              ref={el => this.tabs[i + 1] = el}
                   >
              { this.props.numbered && <span className='number'>{i + 1 + '. '}</span> }
              { child.props.label || <Snip inline allowClick page={this.props.page} name={'tab_label_'+i} /> }
              {/* <div className='indicator-bar' /> */}
            </li>
          }
          )}
          <div className='indicator-bar' style={indicatorStyle} />
        </ol>
      </div>

      <div className='items' style={this.props.fluid ? {flex: 1} : {flexBasis: '66%', minWidth: '66%'}}>
        <Slideshow slide={this.state.activeItem}>
          {this.props.children.map((child, i) => {
            return <div className='item-container' key={i}>
              {child}
            </div>
          })}
        </Slideshow>
      </div>
    </ItemListContainer>
  }

}
