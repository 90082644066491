import React from 'react'
import styled from 'styled-components'
import { onEnterOrSpaceDownClick } from '../../utils'

const Container = styled.div`
  .add-new {
    cursor: pointer;
  }
`

export const Repeater = props => {
    const newId = props.items.length + 1;
    return <Container className='repeater' {...props}>
      {props.items.map((i, idx) => <div className='repeater-item' key={`${i.id}${idx}`}>
        {props.renderItem(i, idx)}
      </div>)}
      {(!props.max || props.items.length < props.max) && <div className='add-new'
      tabIndex={0}
      onClick={() => props.onAddItem(newId)}
      onKeyDown={onEnterOrSpaceDownClick}
      >
        <i className='fa fa-plus'/> {props.newLabel || 'Add New Item'}
      </div>}
    </Container>
}
