//npx jest cart-tools/salesTax.test
import {getSetting, setSetting} from "launchpad";

const TAX_SETTINGS_KEY = 'sales_taxes_by_state'

//https://taxfoundation.org/data/all/state/2024-sales-taxes/
export const DEFAULT_SALES_TAXES_BY_STATE = [
  {"state": "Alabama", "code": "AL", "rate": 4.00},
  {"state": "Alaska", "code": "AK", "rate": 0.00},
  {"state": "Arizona", "code": "AZ", "rate": 5.60},
  {"state": "Arkansas", "code": "AR", "rate": 6.50},
  {"state": "California", "code": "CA", "rate": 7.25},
  {"state": "Colorado", "code": "CO", "rate": 2.90},
  {"state": "Connecticut", "code": "CT", "rate": 6.35},
  {"state": "Delaware", "code": "DE", "rate": 0.00},
  {"state": "Florida", "code": "FL", "rate": 6.00},
  {"state": "Georgia", "code": "GA", "rate": 4.00},
  {"state": "Hawaii", "code": "HI", "rate": 4.00},
  {"state": "Idaho", "code": "ID", "rate": 6.00},
  {"state": "Illinois", "code": "IL", "rate": 6.25},
  {"state": "Indiana", "code": "IN", "rate": 7.00},
  {"state": "Iowa", "code": "IA", "rate": 6.00},
  {"state": "Kansas", "code": "KS", "rate": 6.50},
  {"state": "Kentucky", "code": "KY", "rate": 6.00},
  {"state": "Louisiana", "code": "LA", "rate": 4.45},
  {"state": "Maine", "code": "ME", "rate": 5.50},
  {"state": "Maryland", "code": "MD", "rate": 6.00},
  {"state": "Massachusetts", "code": "MA", "rate": 6.25},
  {"state": "Michigan", "code": "MI", "rate": 6.00, "enabled": true},
  {"state": "Minnesota", "code": "MN", "rate": 6.875},
  {"state": "Mississippi", "code": "MS", "rate": 7.00},
  {"state": "Missouri", "code": "MO", "rate": 4.225},
  {"state": "Montana", "code": "MT", "rate": 0.00},
  {"state": "Nebraska", "code": "NE", "rate": 5.50},
  {"state": "Nevada", "code": "NV", "rate": 6.85},
  {"state": "New Hampshire", "code": "NH", "rate": 0.00},
  {"state": "New Jersey", "code": "NJ", "rate": 6.625},
  {"state": "New Mexico", "code": "NM", "rate": 4.875},
  {"state": "New York", "code": "NY", "rate": 4.00},
  {"state": "North Carolina", "code": "NC", "rate": 4.75},
  {"state": "North Dakota", "code": "ND", "rate": 5.00},
  {"state": "Ohio", "code": "OH", "rate": 5.75},
  {"state": "Oklahoma", "code": "OK", "rate": 4.50},
  {"state": "Oregon", "code": "OR", "rate": 0.00},
  {"state": "Pennsylvania", "code": "PA", "rate": 6.00},
  {"state": "Rhode Island", "code": "RI", "rate": 7.00},
  {"state": "South Carolina", "code": "SC", "rate": 6.00},
  {"state": "South Dakota", "code": "SD", "rate": 4.20},
  {"state": "Tennessee", "code": "TN", "rate": 7.00},
  {"state": "Texas", "code": "TX", "rate": 6.25},
  {"state": "Utah", "code": "UT", "rate": 6.10},
  {"state": "Vermont", "code": "VT", "rate": 6.00},
  {"state": "Virginia", "code": "VA", "rate": 5.30},
  {"state": "Washington", "code": "WA", "rate": 6.50},
  {"state": "West Virginia", "code": "WV", "rate": 6.00},
  {"state": "Wisconsin", "code": "WI", "rate": 5.00},
  {"state": "Wyoming", "code": "WY", "rate": 4.00},
  {"state": "District of Columbia", "code": "DC", "rate": 6.00},
]

export const getSalesTaxSettings = () => {
  let salesTaxByState = getSetting(TAX_SETTINGS_KEY);
  if (!salesTaxByState || salesTaxByState.length < 1) salesTaxByState = DEFAULT_SALES_TAXES_BY_STATE;
  return salesTaxByState;
}

export const setSalesTaxSettings = (salesTax) => {
  setSetting({[TAX_SETTINGS_KEY]: salesTax});
}

export const getSalesTaxRecordByState = (country, state) => {
  if (!country || country.toUpperCase() !== 'US') return;
  if (!state) return;
  const salesStatesByStates = getSalesTaxSettings();
  const stateRate = salesStatesByStates.filter((s)=>s.enabled && s.code.toUpperCase() === state.toUpperCase())
  if (stateRate.length === 1) return stateRate[0];
}

export const getSalesTaxRateByState = (country, state) => {
  const salesTaxRecord = getSalesTaxRecordByState(country, state);
  if (salesTaxRecord) return salesTaxRecord.rate;
}

export const calcSalesTax = (country, state, price) => {
  const salesTaxRate = getSalesTaxRateByState(country, state);
  if (salesTaxRate) return Math.round(price * salesTaxRate)/100;
}
