import React, {Component} from 'react'
import { getGlobal, helpers, getSetting, apiGet, apiPost, Loading, confirm,
  DataStore, Modal, Snip } from 'launchpad'
import {NavLink} from 'react-router-dom';
import styled from 'styled-components'
import { getUpload, getSlug } from '_helpers'
import { Table } from 'sections'
export { customFrameParams, DAFParams, MDParams, getCustomPrice, getDAF, MatData, getMatDesign, getMat,
  getGlazingOptions, getBackingOptions, matOptions, getMatData, setMatData, MatDesignerData, getMatDesignerData, setMatDesignerData, cornerOption, FilterColorData, getFilterColorData, setFilterColorData } from './data'
import { Product } from './product'
import HelpButton from '../components/widgets/HelpButton';
export { Product, loadProduct, ProductContainer, relLink, isFas } from './product'
export { Category, loadCategory } from './category'



export const getSizeLabel = (l,w) => {
  l = parseFloat(l)
  w = parseFloat(w)
  return `${l ? l+'"' : ''}${l && w ? ' x ' : ''}${w ? w+'"' : ''}`
}


const blue = `rgb(38, 63, 143)`


const BCC = styled.div`
  display: flex;
  .crumb {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    .fa {
      margin-left: 10px;
    }
  }
`
export const BreadCrumbs = ({links}) => <BCC className='bread-crumbs'>
  <div className='crumb'>
    <NavLink to='/products'>Products</NavLink>
    {links.length && <span className='fa fa-chevron-right'/>}
  </div>
  {links.map((x, i) => {
    const url = x.link && x.link.startsWith('/') ? x.link : '/'+x.link
    if(x.label) return <div key={i+x.label} className='crumb'>
      {x.link ? <NavLink to={url}>{x.label}</NavLink> : x.label}
      {i < links.length - 1 && <span className='fa fa-chevron-right'/>}
    </div>
  })}
</BCC>



const SMC = styled.div`
  color: white;
  background-color: ${blue};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-size: 14px;
  border-radius: 50%;
  position: relative;
  bottom: 3px;
  cursor: pointer;
`

const SModal = styled.div`
`

export class SizeInfo extends Component {
  state = {open: false}
  render(){
    return <SMC className='size'>
      <HelpButton simple={true} onClick={()=>this.setState({open: !this.state.open})}/>
      <Modal open={this.state.open} onClose={()=>this.setState({open: false})}>
        <SModal>
          <Snip component='h3' default='<h3>Frame Sizes</h3>' page='frame-size-modal' name='heading'/>
          <Snip placeholder='Table description' page='frame-size-modal' name='table-description'/>
          <Table page='product-detail' name='frame-sizes' defaultOptions = {{rows: 6, cols: 3}} />
        </SModal>
      </Modal>
    </SMC>
  }
}

const ShipModal = styled.div`
`

export class ShippingInfo extends Component {
  state = {open: false}
  render(){
    return <SMC className='shipping-info'>
      <HelpButton simple={true} onClick={()=>this.setState({open: !this.state.open})}/>
      <Modal open={this.state.open} onClose={()=>this.setState({open: false})}>
        <SModal>
          <Snip component='h3' default='<h3>Shipping Information</h3>' page='shipping-info-modal' name='heading'/>
          <Snip placeholder='Table description' page='shipping-info-modal' name='heading'/>
          <Table page='shipping-info-modal' name='shipping-info' defaultOptions = {{rows: 6, cols: 3}} />
        </SModal>
      </Modal>
    </SMC>
  }
}







// ******************************************
//   product search/filtering
// ******************************************

export const getProducts = () => {
  return getGlobal('product_list')
}

export const getCategories = () => {
  const cat_map = getGlobal('category_map') || {}
  let cats = Object.keys(cat_map).map(x => {
    return cat_map[x]
  })
  cats.sort((a, b) => a.name > b.name ? 1 : -1)
  return cats
}

const contains = (superset, subset, all) => {
  for(let x; x < subset.length; x++){
    if(!superset.includes(subset[x])) return all ? false : true
  }
  return all ? true : false
}


export const findProducts = (options) => {
  let p = getProducts() || []
  options = options || {}
  p = p.filter(x => x.visible !== false)
  const { name, colors, lengths, widths, total, limit, category, query, _id,
    category_name, searchOnly } = options

  if(category == 'best-sellers'){
    const valid = getSetting('DAF_best_sellers') || []
    return valid.map(x => p.find(y => y._id == x)).filter(x=>!!x)
  }

  if(_id) return p.find(x => x._id == _id)

  if(name) p = p.filter(x => helpers.fuzzyMatch(name, `${x.name} ${x.category.name}`.normalize('NFD').replace(/[\u0300-\u036f]/g, "")))
  if(colors) p = p.filter(x => contains(x.colors, colors))
  if(lengths) p = p.filter(x => contains(x.lengths, lengths))
  if(widths) p = p.filter(x => contains(x.widths, widths))
  if(category) p = p.filter(x => x.category._id == category)
  if(category_name) p = p.filter(x => x.category.name == category_name)
  if(searchOnly) p = p.filter(x => x.searchable && x.category.searchable)
  if((options.checkFas && window.location.search.includes('fasOnly')) || options.fasOnly) {
    p = p.filter(x => x.is_fas)
  }

  if(total) p = p.slice(0, total)
  // alias
  if(limit) p = p.slice(0, limit)

  // if(options.sort == 'alphabetical'){
  //   p.sort((a, b) => return )
  // }

  return p
}

export const getSkus = async () => {
  let products = findProducts();
  //let p = this.products;
  //console.log([])
  let p = products.map((product) => {
    console.log(product);
    let t = getProduct(product._id);
    console.log(t);
    skus = product.getSkus();
    console.log(skus);
    return 1;
  })
  
  return [{_id: ''},{_id: ''},{_id: ''}]
  //let p = loadProduct()

}

export const findallforCoupon = () => {
  let products = findProducts();
  //let categories = getCategories();
  //console.log("Categories", categories);
  return products;
}





export const getProductImage = (code) => {
  if(!code) return ''
  const base = 'https://frameimages.sfo2.cdn.digitaloceanspaces.com/'
  if(code.includes('frameusa.com/')){
    code = code.split('frameusa.com/')[1]
  }
  if(code.includes(base)) return code
  if(code.startsWith('/') && !code.includes('E:\\')) return base + code.slice(1)
  if(!code) return ''
  code = base + code.replace(`\\store\\8x10\\`, '8x10/')
  code = code.replace(/\/\//g, '/').replace('https:/', 'https://').split('"')[0]
  return code
}


const ProductImageContainer = styled.div`
  position: relative;
  background: white center no-repeat;
  background-size: contain;
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
  .tools {
    background: rgba(255,255,255,.7);
    position: absolute;
    color: black;
    bottom: ${props => props.toolPosition == 'top' ? '100%' : '5px'};
    right: ${props => props.toolPosition == 'left' ? '100%' : '5px'};
    padding: 2px;
    display: flex;
    flex-direction: ${props => {
      const rowSet = ['top', 'bottom']
      return rowSet.includes(props.toolPosition) ? 'row' : 'column'
    }};
    opacity: 0;
    transition: opacity .3s;
    margin: 0;
    button {
      &:not(:last-child){
        margin-bottom: 3px;
      }
      margin: 0;
      background: none;
    }
  }
  &:hover {
    .tools {
      opacity: 1;
    }
  }
`

export class ProductImage extends React.Component {
  state = {
    imageLoading: false
  }

  get type() {
    let {type} = this.props
    if(!type) type = 'thumbnail'
    return type.includes('image') || type == 'color_preview' ? type : `${type}_image`
  }

  get filename() {
    const {product, color, category, seriesName, series, subcategoryName, thumbnailPrefix} = this.props
    if(product) return getSlug(`${product.name}_${color}`).replace(/ /g, '-')
    return getSlug((thumbnailPrefix || '') + `${category.name}` + (subcategoryName ? `_${subcategoryName}-Subcategory` : `_${seriesName || series.name}`))
  }

  get folder() {
    const typeMap = {
      preview_image: '8x10',
      corner_image: 'LC',
      profile_image: 'PF'
    }
    const {product} = this.props
    if(product) return typeMap[this.type]
    return 'thumbnails'
  }

  setImage = () => {
    const {product, color, category, series, subcategory} = this.props
    getUpload(image => {
      if(this.props.onSetImage) {
        this.props.onSetImage(series, image)
      } else {
        if(product){
          product.updateColorImage(color, this.type, image.url)
        } else if(category) {
          category.setImage(series, subcategory, image.url)
        }
      }
    }, {bucket: 'frameimages', folder: this.folder, filename: this.filename})
  }

  remove = () => {
    const {product, color, type, series, subcategory, category} = this.props
    const del = (fn) => confirm('Are you sure you want to remove this image? This can\'t be undone!', fn)
    if(this.props.onSetImage){
      del(() => this.props.onSetImage(series, ''))
    } else {
      if(product){
        del(() => product.updateColorImage(color, this.type, ''))
      } else {
        del(() => category.setImage(series, subcategory, ''))
      }
    }
  }

  products = {}

  getProduct = id => {
    if(!this.products[id]) {
      this.products[id] = new Product(findProducts({_id: id}))
      this.products[id].registerListener(this)
    }
    return this.products[id]
  }

  render() {
    const {product, color, type, className, series, subcategory, category, imageData} = this.props
    let bg = {}
    if(imageData && imageData[series]) {
      bg = {backgroundImage: `url(${imageData[series].url})`}
    } else {
      bg = product ? product.getBackground(color, this.type) : category.getBackground(series, subcategory)
    }


    // TODO: need to re-enable cdn routes, but we'll need cache invalidation on upload first
    bg.backgroundImage = bg.backgroundImage ? bg.backgroundImage.replace('.cdn.digital', '.digital') : undefined
    return <ProductImageContainer {... this.props} className={`${className} product-image`} style={bg} product-image>
      {getGlobal('is_admin') && <div className='tools' onClick={e=>{e.stopPropagation(); e.preventDefault()}}>
        {bg.backgroundImage && <button className='material-icons' onClick={() => this.remove()}>delete</button>}
        <button className='material-icons' onClick={() => this.setImage()}>image</button>
      </div>}
      {/* <Loading active={this.state.imageLoading == c}/> */}
    </ProductImageContainer>
  }
}
