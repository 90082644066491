import React from 'react';
import Hero from '_shared/partial-hero/Hero';
import {Snip, helpers, Page} from 'launchpad';

export default class Stub extends React.Component {
  render() {
    const heading = this.props.heading || helpers.getTitle(window.location.pathname.substr(1).replace(/\//g, ' > '))
    return (
      <Page id='stub'>
        <Hero className='half' id='stub-hero'>
          <h1>{heading}</h1>
          <h3>Lorem Ipsum Dolor Sit Amet</h3>
        </Hero>
        <section className='container padded'>
          <h1>{heading}</h1>
          <p className='lorem'></p>
        </section>
      </Page>
    );
  }
}
