import React from "react";
import ReactDOM from "react-dom";

const defaultProps = {
  style: {},
  clientId: "sb",
  customerId: "",
  options: {
    currency: "USD"
  },
  items: [],
};

const dividePriceSafelyToInt = (price, quantity) => {
  return Math.round(parseFloat(price) * 100 / parseInt(quantity));
};

export class PayPalButton extends React.Component {
  state = {isSdkReady: false};

  static defaultProps = defaultProps

  componentDidMount() {
    if(window !== undefined && window.paypal === undefined) {
      this.addPaypalSdk();
    }
    else if(this.props.onButtonReady) {
      this.props.onButtonReady();
    }
  }

  addPaypalSdk = () => {
    const { options, onButtonReady, clientId } = this.props;
    //const options = this.props.options || this.defaultProps.options
    const queryParams = [];

    const params = Object.assign({}, defaultProps.options, options, {clientId})

    // replacing camelCase with dashes
    Object.keys(params).forEach(k => {
      const name = k.split(/(?=[A-Z])/).join("-").toLowerCase();
      queryParams.push(`${name}=${params[k]}`);
    });

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?${queryParams.join("&")}`;
    script.async = true;
    script.onload = () => {
      this.setState({ isSdkReady: true });
      if (onButtonReady) {
        onButtonReady();
      }
    };
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.");
    };

    document.body.appendChild(script);
  }

  createOrder(data, actions) {
    const { currency, summary, options, orderParameters, items, shippingAddress, customerId } = this.props

    const currencyCode = currency || (options && options.currency) || 'USD';
    let subtotalInt = 0;

    const patchedItems = items.map((item) => {
      const itemSinglePriceInt = dividePriceSafelyToInt(item.price, item.qty);
      subtotalInt += itemSinglePriceInt * item.qty;

      let itemName = (!item.custom && item.display_name) || (item.is_Mat && item.is_DAF) ? item.matDescription : item.full_description || item.name;
      
      // Max length is 127 characters
      if (itemName.length > 127) { 
        itemName = `${itemName.substring(0, 124)}...`;
      }
      
      return {
        name: itemName,
        sku: item.sku,
        quantity: item.qty,
        unit_amount: {
          currency_code: currencyCode,
          value: (itemSinglePriceInt / 100).toFixed(2),
        },
      };
    });

    const shippingInt = Math.round(parseFloat(summary.shipping) * 100);
    const discountInt = Math.round(parseFloat(summary.discount) * 100);
    const taxInt = Math.round(parseFloat(summary.tax) * 100);
    const totalInt = shippingInt + subtotalInt + taxInt - discountInt;

    const defaults = {
      purchase_units: [{
        amount: {
          currency_code: currencyCode,
          value: (totalInt / 100).toFixed(2),
          breakdown: {
            item_total: {
              currency_code: currencyCode,
              value: (subtotalInt / 100).toFixed(2),
            },
            shipping: {
              currency_code: currencyCode,
              value: (shippingInt / 100).toFixed(2),
            },
            tax_total: {
              currency_code: currencyCode,
              value: (taxInt / 100).toFixed(2),
            },
            discount: {
              currency_code: currencyCode,
              value: (discountInt / 100).toFixed(2),
            },
          },
        },
        shipping: {
          address: shippingAddress,
        },
        items: patchedItems,
        description: customerId,
      }],
    };

    return actions.order.create(Object.assign(defaults, orderParameters || {}));
  }

  onApprove(data, actions) {
    return actions.order
      .capture()
      .then((details) => {
        if (this.props.onSuccess) {
          // console.log(data, details)
          const paymentID = details.purchase_units[0].payments.captures[0].id
          return this.props.onSuccess(details, data, Object.assign(data, {paymentID}));
        }
      })
      .catch((err) => {
        console.error(err)
        if (this.props.onError) {
          return this.props.onError(err);
        }
      });
  }

  render() {
      const {
          summary,
          createOrder,
          style,
      } = this.props;
      const { isSdkReady } = this.state;

      if (!isSdkReady && window.paypal === undefined) {
          return null;
      }

      const Button = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
      });

      return (
          <Button
              {...this.props}
              createOrder={
                summary && !createOrder
                    ? (data, actions) => this.createOrder(data, actions)
                    : (data, actions) => createOrder(data, actions)
              }
              onApprove={(data, actions) => this.onApprove(data, actions)}
              style={style}
          />
      );
  }
}
