import React from 'react'
import {default as CB} from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import { history } from 'app'
import Slider from '_shared/partial-slideshow/Slideshow';

export const Slideshow = Slider
export { Repeater } from './Repeater'
export { PayPalButton } from './Paypal'
export { Icon } from './Icon'
export { Collapsible } from './Collapsible'

import ReactSelect from 'react-select'

// consolidating widgets originally exported from 'launchpad'
export { Input } from 'launchpad'
export {default as CheckMark, WarningIcon} from 'launchpad/admin/widgets/CheckMark'


// Material UI Checkbox with style fixes

const CBContainer = styled.div`
  > span, > label > span {
    display: inline-block;
    margin: 5px;
    padding: 0;
  }
`
export const CheckBox = (props) => <CBContainer className={`${props.className} check-box`}>
  {props.label
    ? <label>{props.label}<CB {...props}/></label>
    : <CB {...props}/>
  }
</CBContainer>




// Easy to remember alias, may expand later

export const Link = (props) => {
  const p = Object.assign({}, props)
  if(props.to){
    p.href = props.to
  }
  if(p.href && (p.href.startsWith('/') || !p.href.includes('.'))) {
    p.onClick = e => {
      e.preventDefault()
      history.push(p.href)
      if(props.onClick) props.onClick(e)
    }
  }
  return <a {...p}/>
}




const PBContainer = styled.div`
  border: 1px solid #ddd;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  height: 17px;
  display: block;
  .lp_progress-bar {
    background: #0062cc;
    height: 15px;
    margin: 0;
    border-radius: 2px;
    display: block;
    transition: width .3s;
  }
`
export const ProgressBar = props => <PBContainer className='lp_progress'>
  <div className='lp_progress-bar' style={{width: `${props.progress}%`}} />
</PBContainer>



// Qty selector widget

const QContainer = styled.div`
  display: inline-flex;
  margin: 0;
  > button {
    padding: 7px;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .value {
    padding: 7px 0;
    width: 40px;
    border: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
  }
  .increment, .decrement {
    padding: 7px 10px;
    cursor: pointer;
  }
  .decrement {
    border-radius: 4px 0 0 4px;
  }
  .increment {
    border-radius: 0 4px 4px 0;
  }

  &.disabled {
    pointer-events: none;
    .fa {
      color: #bbb;
    }
  }
`

export class Qty extends React.Component {
  add = (amt) => {
    const { max, onMin, onMax, onChange } = this.props
    let min = this.props.min || 0
    let value = this.props.value + amt
    if(max) {
      if(value > max && onMax) onMax(value)
      value = Math.min(max, value)
    }
    if(min || min == 0){
      if(value < min && onMin) onMin(value)
      value = Math.max(0, min, value)
    }
    onChange(value)
  }

  change = (e) => {
    const { onError, value, onChange } = this.props
    const num = e.target.value
    const v = parseInt(num)
    if(num && isNaN(v)) {
      if(onError) onError()
    } else {
      if(!num) {
        onChange('')
      } else {
        this.add(v - value)
      }
    }
  }

  render() {
    let { value, onChange, disabled } = this.props
    value = parseInt(value)
    return <QContainer aria-label='Quantity controls' role='group' tabIndex={0} className={`qty-selector ${disabled ? 'disabled' : ''}`}>
      <button aria-label='Decrease quantity' className='decrement decrease-product fa fa-minus' onClick={()=>this.add(-1)} />
      <input aria-label='Quantity' className='value' onChange={this.change} value={isNaN(value) ? '' : value} />
      <button aria-label='Increase quantity' className='increment increase-product fa fa-plus' onClick={()=>this.add(1)} />
    </QContainer>
  }
}


// Select that uses react-select, but lets you just use <option>s like you normally would
export const Select = props => {
  let ops = []
  const {isDisabled, ...rest} = props;
  let {children} = rest
  if(!Array.isArray(props.children)) children = [children]

  const readOptions = children => {
    children.filter(x=>!!x).forEach(x => {
      if(Array.isArray(x)) {
        readOptions(x)
      } else {
        ops.push({value: x.props.value == undefined ? x.props.children : x.props.value, label: x.props.children})
      }
    })
  }

  readOptions(children)

  const isMulti = props.multi || props.isMulti
  const onChange = (v) => {
    if(props.onChange){
      if(isMulti){
        props.onChange(v.map(v => v.value))
      } else {
        props.onChange(v.value)
      }
    }
  }
  const value = (props.multi || props.isMulti)
    ? ops.filter(x => (props.value || []).map(x => x.value || x).includes(x.value))
    : ops.find(x => (!x.value && !props.value) || x.value == props.value)
  return <ReactSelect isDisabled={isDisabled} {...props} isMulti={isMulti} options={ops} value={value} onChange={onChange} className={`react-select ${props.className || ''}`}/>
}



// if(module.hot) module.hot.accept()
