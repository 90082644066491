import React from 'react'
import styled from 'styled-components'
import { findProducts, ProductImage, Category } from 'product_tools'
import { NavLink } from 'react-router-dom'
import { setGlobal, getGlobal, Page, Paginate, helpers, confirm, apiPost } from 'launchpad'
import { history } from 'app'
import Select from 'react-select'
import { Link, Icon } from 'widgets'
import * as GTMEvents from '../utils/GTMEvents';


function buildProductURL(category, product) {
  const urlParams = new URLSearchParams()

  if (window.location.search.includes('fasOnly')) {
    urlParams.set('fasOnly', 1)
  }
  if (product.extra && product.extra.color) {
    urlParams.set('color', product.extra.color)
  }
  if (product.extra && product.extra.size) {
    urlParams.set('size', product.extra.size)
  }

  const qs = urlParams.toString()
  return `${!category.url.startsWith('/') ? '/' : ''}${category.url}/${product.url}${qs ? `?${qs}` : ''}`
}

const SearchStyles = styled.div`
  position: relative;
  .search-input {
    position: relative;
    input {
      display: block;
      width: 100%;
      padding: 10px;
      border: 0;
      background: #e7e7e7;
    }
    .fa, .svg-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #777;
    }
  }

  .suggestions {
    position: absolute;
    z-index: 999;
    width: 100%;
    top: 100%;
    left: 0;
    background: white;
    .suggestion {
      display: block;
      //border-bottom: 1px solid #ddd;
      padding: 0px;
      div {
        padding: 10px;
      }
      hr {
        color: #ddd;
        margin: 0;
      }
    }
  }
`

export default class SearchBar extends React.Component {
  state = {
    query: '',
    showSuggestions: false,
    suggestions: []
  }

  search = e => {
    const query = e.target.value
    if(query.length > 0){
      this.setState({
        query,
        showSuggestions: true,
        suggestions: findProducts({total: 7, name: query, searchOnly: true})
      })
    } else {
      this.setState({showSuggestions: false, query})
    }
  }

  submit = e => {
    const {query} = this.state
    const go = () => {
      this.setState({showSuggestions: false})
      setGlobal({searchQuery: query})
      history.push('/search')
    }
    if(e && e.key){
      if(e.key == 'Enter') {
        go()
      }
    } else {
      go()
    }
  }

  endSearch = () => {
    setTimeout(()=>this.setState({showSuggestions: false}), 500)
  }

  render() {
    const {query, showSuggestions, suggestions} = this.state
    return <SearchStyles className='search-bar'>
      <div className='search-input'>
        <input ref={i => this.input = i}
          onFocus={this.search}
          onBlur={this.endSearch}
          title={this.props.placeholder}
          placeholder={this.props.placeholder}
          value={query}
          onChange={this.search}
          onKeyPress={this.submit}
        />
        {/* <span onClick={this.submit} className='fa fa-search' /> */}
        <Icon name='search' onClick={this.submit}/>
      </div>
      {showSuggestions && <div className='suggestions'>
        {suggestions.map(x => <NavLink className='suggestion' to={`/${x.category.url}/${x.url}`}>
          <div>{x.name}</div>
          <hr />
        </NavLink>)}
      </div>}
    </SearchStyles>
  }
}


const ProductGridContainer = styled.div`
  padding-bottom: 80px;
  padding-top: 80px;
  .filter-container {
    padding: 15px 0;
    .showing {
      margin-top: 15px;
    }
    .filters {
      display: flex;
      align-items: center;

      .filter {
        display: flex;
        padding: 5px 10px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        .filter-input {
          flex: 1;
        }
        button {
          border: 1px solid #ddd;
          border-radius: 5px;
          padding: 0 14px;
          pointer-events: none;
          opacity: 0;
          &.shown {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }
    @media (max-width: 812px){
      .filters {
        display: block;
        .filter {
          padding: 5px 0;
        }
      }
    }
    .clear-filter, .clear-filter:not([href]):not([tabindex]) {
      display: inline-block;
      padding: 5px 10px;
      margin-left: 10px;
      background: #bbb;
      border-radius: 3px;
      color: white;
      cursor: pointer;
    }
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    .product {
      width: calc(33% - 12px);
      flex-shrink: 0;
      flex-grow: 0;
      margin-bottom: 20px;
      &:nth-child(3n - 1) {
        margin-left: 20px;
        margin-right: 20px;
      }
      &:last-child {
        margin-right: auto;
      }
      @media (max-width: 812px){
        &:nth-child(3n - 1) {
          margin-left: 0;
          margin-right: 0;
        }
        &:nth-child(2n+1) {
          margin-right: 10px;
        }
        &:nth-child(2n) {
          margin-left: 10px;
        }
        width: calc(50% - 10px);
        display: flex;
      }
      .product-container {
        position:relative;
        bottom: 0px;
        transition: bottom .3s, box-shadow .3s;
        box-shadow: 0px 0px 0px rgba(0,0,0,.03);
        display: block;
        border:1px solid #ddd;
        padding: 0;
        width: 100%;
        .image {
          padding-bottom: 70%;
          background-color: #efefef;
        }
        .info {
          padding: 20px;
        }
        .sort-tools {
          position: absolute;
          top: 10px;
          right: 10px;

        }
        .sort-button, .fa-remove.remove{
          background: rgba(255, 255, 255, .4);
          padding: 5px;
          color: #333;
          opacity: 0;
          transition: opacity .3s;
        }
        &:hover {
          .sort-button, .fa-remove.remove {
            opacity: 1;
          }
          bottom: 2px;
          box-shadow: -4px 4px 0px rgba(0,0,0,.03);
        }
        .fa-remove.remove {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }

`

// storage for category filters
let activeFilters = {}

export class ProductGrid extends React.Component {
  state = {
    availableFilters: {},
    filters: {},
    calculateFilters: true
  }

  categories = {}

  getCategory = (category) => {
    if(!this.categories[category._id]){
      this.categories[category._id] = new Category(category)
    }
    return this.categories[category._id]
  }

  componentDidUpdate = (prevProps) => {
    if(
      this.props.query != this.state.query
      || (prevProps.products && this.props.products && prevProps.products.length != this.props.products.length)
      || prevProps.category && !prevProps.category._id && this.props.category._id
    ) {
      this.setState({query: this.props.query, calculateFilters: true}, () => this.paginate.setPage(1))
    }

    const { category } = this.props

    if(category && category._id && Object.keys(this.state.filters).length == 0) {
      const filters = category && activeFilters[category._id]
      if(filters && Object.keys(filters).length > 0) this.setState({filters})
    }
  }

  filterResults = (results) => {
    const {filters} = this.state
    return results.filter(x => {
      const {color, size, category, profile} = filters

      if(color && !x.color_groups.join('').includes(color)) return false
      if(size && !x.sizes.includes(size)) return false
      if(category && x.category.name != category) return false
      if(profile && !x.profiles.includes(profile)) return false
      return true
    })
  }

  setFilters = (results) => {
    if(this.state.calculateFilters){
      let availableFilters = {
        category: [],
        color: [],
        size: [],
        profile: []
      }
      for(let result of results){
        //console.log("result",result);
        result && result.category && result.category.name ? availableFilters.category.push(result.category.name): "";
        let colors = []
        if(result && result.color_groups) {
          for(let color of result.color_groups) {
            if(color){
              // console.log(color)
              for(let c of color.split(/,/g)){
                if(c && !colors.includes(c)){
                  // console.log(c)
                  colors.push(c.replace(/\W/g, ''))
                }
              }
            }
          } 
        }
        availableFilters.color = availableFilters.color.concat(colors)

        // TODO: uncomment this line to allow size filtering, hiding for now
        //availableFilters.size = availableFilters.size.concat(result.sizes)
        if(result && result.profiles)
          availableFilters.profile = availableFilters.profile.concat(result.profiles.filter(x=>!!x))
        else
          availableFilters.profile = availableFilters.profile
      }
      for(let prop of ['category', 'color', 'size', 'profile']){
        availableFilters[prop] = [... new Set(availableFilters[prop])]
        availableFilters[prop].sort((a, b) => {
          if(prop == 'profile') return helpers.parseFraction(a) - helpers.parseFraction(b)
          const l1 = parseInt(a.split('x')[0])
          const l2 = parseInt(b.split('x')[0])
          if(!isNaN(l2)) {
            return l1 - l2
          }
          return a > b ? 1 : -1
        })
      }
      availableFilters.size = availableFilters.size.filter(x => {
        const [l, w] = x.split('x')
        return (parseInt(l) && parseInt(w))
      })
      this.setState({availableFilters, calculateFilters: false})
      //console.log(availableFilters)
    }
  }

  getItemPage = ({ page }) => {
    const perPage = 9
    const { filters } = this.state
    const { products, category, subcategory, query } = this.props
    const q = {name: query, category: category && category._id, searchOnly: true, checkFas: true}
    let results = ((Object.keys(filters).length == 0) && products) || findProducts(q) || []
    //this.setFilters(results)
    results = this.filterResults(results)
    this.setFilters(results)
    const matches = results.length
    const pages = Math.ceil(matches / perPage)
    const slicedResults = results.slice((page-1)*perPage, (page)*perPage)
    GTMEvents.viewItemList(slicedResults);
    const data = {matches, page, perPage, pages, results: slicedResults}
    //console.log(data)
    return data
  }

  setFilter = (obj) => {
    // let { filters } = this.state
    // filters = Object.assign(filters, obj)
    // console.log(filters)
    const {category} = this.props
    const currentFilters = this.state.filters
    let core = category ? {category: category.name} : {}
    const filters = Object.assign({}, currentFilters, obj, core)
    if(category) {
      activeFilters[category._id] = filters
    }
    this.setState({ filters, calculateFilters: true }, () => this.paginate.setPage(1))
  }

  refreshProducts = async () => {
    if(this.props.category) await this.props.category.reload()
    this.paginate.reload()
  }

  getSortedList = () => {
    return this.props.products ? this.props.products.map(x => x.subcategory || x._id) : []
  }

  pushSortOrder = (order, p) => {
    if(this.props.subcategory){
      apiPost(`/category/${p.category._id}/set-sorting/${this.props.subcategory.name}`, {order}).then(this.refreshProducts)
    } else {
      apiPost(`/category/${p.category._id}/set-sorting`, {order}).then(this.refreshProducts)
    }
  }

  sortUp = (e, p) => {
    e.stopPropagation()
    e.preventDefault()
    const order = ([p.subcategory || p._id]).concat(this.getSortedList().filter(x => x != p.subcategory && x != p._id))
    confirm('Are you sure you want to move this item to the top of the list?', () => {
      this.pushSortOrder(order, p)
    })
  }

  sortDir = (e, p, dir) => {
    e.stopPropagation()
    e.preventDefault()
    const list = this.getSortedList()
    let idx = 0
    const id = (p.subcategory || p._id)
    list.forEach((item, index) => {
      if(item == id){
        idx = index + dir
      }
    })
    const spliced = list.filter(x => x !== id)
    spliced.splice(Math.max(idx, 0), 0, id)
    this.pushSortOrder(spliced, p)
  }

  get showString(){
    const {filters, query} = this.state
    const {color, size, category, profile} = filters
    const showing = this.getItemPage(1).matches
    const filter = color || size || profile
    const filterString = [size, profile, color].filter(x =>!!x).map(x=>getLabel(x)).join(', ')
    return `Showing ${showing} result${showing == 1 ? '' : 's'} ${query ? `for "${query}"` : ''}
    ${category ? `from the "${category}" category` : ''}${category && filter ? ', ' : ''}
    ${filterString ? `available in ${filterString}` : ''} `
  }

  render() {
    const { products, category, subcategory, query, hideFas } = this.props
    const { availableFilters, filters } = this.state
    const filter = filters.color || filters.size || (!category && filters.category) || filters.profile

    const showFas = !hideFas && !window.location.search.includes('fasOnly')
    const showAll = !hideFas && window.location.search.includes('fasOnly')
    let productList = [];
    return <ProductGridContainer className='product-grid' innerRef={g => this.grid = g}>
      <div className='filter-container'>
        {!this.props.hideFilters && <div className='filters'>
          {Object.keys(availableFilters).filter(x => availableFilters[x].length > 1).map(key => {
            const options = availableFilters[key]
            return <Filter key={key} onChange={this.setFilter} options={options} type={key} value={filters[key]}/>
          })}
          {(showFas || showAll) && <div className='filter'>
            {showFas && <Link href='?fasOnly'>Show In Stock Items</Link>}
            {showAll && <Link href='?'>Show All Items</Link>}
          </div>}
        </div>}
        {(query || filter) && <p className='showing'>{this.showString}
          {filter && <a className='clear-filter' onClick={()=>this.setFilter({color: '', size: '', category: '', profile: ''})}>
            Clear Filters <span className='fa fa-close'/>
          </a>}
        </p>}
      </div>

      <Paginate
        ref={p => this.paginate = p}
        hideTools
        paginatePosition='bottom'
        getResults={this.getItemPage}
        onPageChange={p => this.grid.scrollIntoView()}
        renderResults={items => {
        return <div role='list' className='product-grid'>
          {items.map(p => {
            if(p) {
              const c = (category || this.getCategory(p.category))
              const url = buildProductURL(c, p)
              return <div role='listitem' className='product' key={p._id}>
                <NavLink key={p.url} to={url} className='product-container'>
                  <ProductImage
                    thumbnailPrefix={this.props.thumbnailPrefix}
                    onSetImage={this.props.onSetImage}
                    imageData={this.props.imageData}
                    backgroundColor='white'
                    className='image'
                    category={c}
                    series={p._id}
                    seriesName={p.name}
                    subcategoryName={p.subcategory}
                  />
                  <div className='info'>
                    <div className='title'>{p.name}</div>
                    <div className='price'>{p.price}</div>
                  </div>
                  { getGlobal('is_admin') && category && <div className='sort-tools'>
                    <div className='sort-button fas fa-angle-double-up' onClick={e => this.sortUp(e, p)} />
                    <div className='sort-button fas fa-angle-up' onClick={e => this.sortDir(e, p, -1)} />
                    <div className='sort-button fas fa-angle-down' onClick={e => this.sortDir(e, p, 1)} />
                  </div> }
                  {this.props.onRemove && getGlobal('is_admin') && <div className='remove fas fa-remove' onClick={e => {
                    e.preventDefault();
                    this.props.onRemove(p)
                  }} />}
                </NavLink>
              </div>
            }
            
          })}
        </div>
      }} />
    </ProductGridContainer>
  }
}


const getLabel = (value) => {
  if(value.includes('"')) return value
  let label = helpers.getTitle(value)
  let [l, w] = (value || '').split('x')
  if(!isNaN(parseInt(l))) label = `${l}" x ${w}"`
  return label
}

class Filter extends React.Component {

  componentDidMount(){
    setTimeout(()=>this.forceUpdate(), 200)
  }
  render() {
    const { value, options, type } = this.props

    const label = value ? getLabel(value) : <span style={{color: '#bbb'}}>{`Filter by ${type}...`}</span>
    return <div className='filter' style={options.length > 1 ? {flex: 1} : {}}>
      {options.length > 1 && [
        <Select
          key='input'
          aria-label={`Filter by ${type}`}
          className='filter-input'
          onChange={ v => this.props.onChange({[type]: v.value}) }
          value={ { value,  label } }
          options = {options.map(v => { return {value: v, label: getLabel(v)} })}
        />,
        <button
          key='clear'
          aria-label={`Clear ${type}`}
          className={`${value && 'shown'} fa fa-times`}
          onClick={()=>this.props.onChange({[type]: ''})}
        />
      ]}
    </div>
  }
}


const SearchPageStyles = styled.div`

`

export class SearchPage extends React.Component {
  render() {
    const query = getGlobal('searchQuery') || ''
    return <Page id='search-results'>
      <SearchPageStyles className='container'>
        <ProductGrid query={query} />
      </SearchPageStyles>
    </Page>
  }
}

// if(module.hot) module.hot.accept()
