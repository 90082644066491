import { getGlobal, getSetting } from 'launchpad'
import { getDAFP } from 'product_tools/data'

let freightPrices = null
let loadingFreight = false
let freightAttempts = 3

export const loadFreightPrices = async () => {
  if(!getGlobal('freightPrices') && !loadingFreight && freightAttempts < 3) {
    loadingFreight = true
    attempts += 1
    setGlobal({freightPrices: await apiGet('/get-freight-cost')})
  }
  setTimeout(()=>loadingFreight = false, 1000)
  return getGlobal('freightPrices')
}


// get packing skus from server (if available and loaded) or return defaults
export const getOversizeSkus = () => {
  loadFreightPrices()
  let prices = {
    large: {sku: '74135', price: 3},
    huge: {sku: '74136', price: 5}
  }
  return Object.assign(prices, getGlobal('freightPrices') || {})
}

export const getOversize = (ui) => {
  let item = {
    price: 0,
    sku: ''
  }

  // skip looking up unless needed
  if(ui < 50) return item

  loadFreightPrices()
  const prices = getOversizeSkus()

  if(ui >= 50 && ui < 60){
    return prices.large
  } else {
    return prices.huge
  }
}


// export const defaultShippingRates = {
//   zone_1_percentage: 22,
//   zone_2_percentage: 22,
//   zone_3_percentage: 23,
//   zone_4_percentage: 24,
//   zone_5_percentage: 24,
//   tier_1_cutoff: 25,
//   tier_1_rate: 10,
//   tier_2_cutoff: 50,
//   tier_2_rate: 12.5,
//   tier_3_cutoff: 75,
//   tier_3_rate: 15,
//   tier_4_cutoff: 100,
//   tier_4_rate: 17.5,
//   tier_5_cutoff: 125,
//   tier_5_rate: 20,
//   tier_6_cutoff: 150,
//   tier_6_rate: 22.5,
//   tier_7_cutoff: 175,
//   tier_7_rate: 27.5,
//   tier_8_cutoff: 200,
//   tier_8_rate: 30,
//   tier_9_cutoff: 225,
//   tier_9_rate: 35,
//   tier_10_cutoff: 249.99,
//   tier_10_rate: 40,
// }

export const defaultShippingRates = {
  zone_1_percentage: 22,
  zone_2_percentage: 22,
  zone_3_percentage: 23,
  zone_4_percentage: 24,
  zone_5_percentage: 24,

  // tier_1_cutoff: 25,
  // tier_1_rate: 10,
  // tier_2_cutoff: 50,
  // tier_2_rate: 12.5,
  // tier_3_cutoff: 75,
  // tier_3_rate: 15,
  // tier_4_cutoff: 100,
  // tier_4_rate: 17.5,
  // tier_5_cutoff: 125,
  // tier_5_rate: 20,
  // tier_6_cutoff: 150,
  // tier_6_rate: 22.5,
  // tier_7_cutoff: 175,
  // tier_7_rate: 27.5,
  // tier_8_cutoff: 200,
  // tier_8_rate: 30,
  // tier_9_cutoff: 225,
  // tier_9_rate: 35,
  // tier_10_cutoff: 249.99,
  // tier_10_rate: 40,

  // New Shipping Tier 19-02-2020
  tier_1_cutoff: 20.01,
  tier_1_rate: 10,
  tier_2_cutoff: 40,
  tier_2_rate: 12.5,
  tier_3_cutoff: 60,
  tier_3_rate: 15,
  tier_4_cutoff: 80,
  tier_4_rate: 17.5,
  tier_5_cutoff: 100,
  tier_5_rate: 20,
  tier_6_cutoff: 120,
  tier_6_rate: 22.5,
  tier_7_cutoff: 140,
  tier_7_rate: 25,
  tier_8_cutoff: 160,
  tier_8_rate: 27.5,
  tier_9_cutoff: 180,
  tier_9_rate: 30,
  tier_10_cutoff: 200,
  tier_10_rate: 32.5,
  tier_11_cutoff: 220,
  tier_11_rate: 35,
  tier_12_cutoff: 240,
  tier_12_rate: 37.5,
  tier_13_cutoff: 299.99,
  tier_13_rate: 40
  // tier_14_cutoff: 249.99,
  // tier_14_rate: 40,
}

const getRate = (key) => {
  return getSetting(`shipping_`+key) || defaultShippingRates[key]
}

// export const getShippingCost = (cart, subtotal) => {
//   const {shipping_state} = cart
//   const items = cart.getItems()
//   if(items.length == 1) {
//     if(items[0].charity) {
//       return 0;
//     }
//   }
//   if(!cart.isWholesale()){
//     //for(let x of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
//     for(let x of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13]) {
//       if(subtotal < getRate(`tier_${x}_cutoff`)) return getRate(`tier_${x}_rate`)
//     }
//     return 0
//   } else {
//     let rate = getRate('zone_5_percentage')
//     const zones = [
//       {
//         rate: getRate('zone_1_percentage'),
//         states: ['WV', 'OH', 'KY', 'IN'],
//       },
//       {
//         rate: getRate('zone_2_percentage'),
//         states: ['WI', 'VA', 'TN', 'SC', 'PA', 'NY', 'NC', 'MS', 'MO', 'MN', 'MI', 'LA', 'IL', 'IA', 'GA', 'AR', 'AL', 'NJ', 'MD', 'DE']
//       },
//       {
//         rate: getRate('zone_3_percentage'),
//         states: ['VT', 'TX', 'SD', 'RI', 'OK', 'NH', 'NE', 'ND', 'ME', 'MA', 'KS', 'FL', 'CT']
//       },
//       {
//         rate: getRate('zone_4_percentage'),
//         states: ['AZ', 'CA', 'CO', 'ID', 'MT', 'NM', 'NV', 'OR', 'UT', 'WA', 'WY']
//       }
//     ]
//     for(let z of zones) {
//       if(z.states.includes(shipping_state)) rate = z.rate
//     }
//     return subtotal * (rate / 100)
//   }
// }


// Changes for HAWAII and ALASKA Shipping 05-03-2020



export const getShippingCost = (cart, subtotal) => {
  //console.log('subtotalsubtotal111',subtotal)
  const {shipping_state} = cart
  const items = cart.getItems()
  if(items.length == 1) {
    if(items[0].charity) {
      return 0;
    }
  }
  if(!cart.isWholesale()){
    if(shipping_state == 'AK'){
      if(subtotal >= 0 && subtotal <= 100){
        return 25
      } else if(subtotal >= 100.01 && subtotal <= 250){
        return subtotal * (60 / 100);
      } else {
        return subtotal * (30 / 100);
      }
     
    } 
    else if (shipping_state == 'HI'){

      if(subtotal >= 0 && subtotal <= 49){
        return 25
      } else if(subtotal >= 49.01 && subtotal <= 99){
        return subtotal * (60 / 100);
      } 
      else if(subtotal >= 99.01 && subtotal <= 249){
        return subtotal * (50 / 100);
      } else {
        return subtotal * (40 / 100);
      }
    }
    else {
      for(let x of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13]) {
        if(subtotal < getRate(`tier_${x}_cutoff`)) return getRate(`tier_${x}_rate`)
      }
      return 0
    }
  

  } else {
    //console.log('subtotalsubtotaldddd11',shipping_state,subtotal)
    let rate = getRate('zone_5_percentage')
    const zones = [
      {
        rate: getRate('zone_1_percentage'),
        states: ['WV', 'OH', 'KY', 'IN'],
      },
      {
        rate: getRate('zone_2_percentage'),
        states: ['WI', 'VA', 'TN', 'SC', 'PA', 'NY', 'NC', 'MS', 'MO', 'MN', 'MI', 'LA', 'IL', 'IA', 'GA', 'AR', 'AL', 'NJ', 'MD', 'DE']
      },
      {
        rate: getRate('zone_3_percentage'),
        states: ['VT', 'TX', 'SD', 'RI', 'OK', 'NH', 'NE', 'ND', 'ME', 'MA', 'KS', 'FL', 'CT']
      },
      {
        rate: getRate('zone_4_percentage'),
        states: ['AZ', 'CA', 'CO', 'ID', 'MT', 'NM', 'NV', 'OR', 'UT', 'WA', 'WY']
      }


    ]
    for(let z of zones) {
      if(z.states.includes(shipping_state)) rate = z.rate
    }
    if(shipping_state == 'AK'){
      if(subtotal >= 0 && subtotal <= 100){
        return 25
      } else if(subtotal >= 101 && subtotal <= 250){
        return subtotal * (60 / 100);
      } else {
        return subtotal * (30 / 100);
      }
     
    } 
    else if (shipping_state == 'HI'){

      if(subtotal >= 0 && subtotal <= 49){
        return 25
      } else if(subtotal >= 49.01 && subtotal <= 99){
        return subtotal * (60 / 100);
      } 
      else if(subtotal >= 99.01 && subtotal <= 249){
        return subtotal * (50 / 100);
      } else {
        return subtotal * (40 / 100);
      }

    }
    else {
      return subtotal * (rate / 100)
    }
   
  }
}
