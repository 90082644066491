import React from 'react'
import {Collapsible, helpers} from 'launchpad'
import styled from 'styled-components'

const Container = styled.div`
  padding: 10px;
  background: #444;
  color: #eee;
  box-shadow: 0 0 12px 2px rgba(0,0,0,.3) inset;
  text-align: center;
  position: relative;

  .close-panel {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    padding-bottom: 10px;
  }
  input {
    margin-right: 30px;
  }
  .option-group {
    padding: 10px;

    input {
      background: #777;
      font-size: 18px;
      border-radius: 3px;
      border: 0;
      padding: 5px 7px;
      flex: 1;
    }

    select {
      background: #777;
      font-size: 18px;
      border-radius: 3px;
      border: 0;
      padding: 5px 7px;
      flex: 1;
    }

    .row-label {
      font-weight: bold;
      margin-right: 20px;
    }
    >div:not(.option-row) {
      display: flex;
      align-items: center;
      max-width: 1040px;
      margin: 15px auto;
    }
    .option-row {
      display: flex;
      margin: 5px auto;
      max-width: 1100px;

      > div {
        flex: 1;
        display: flex;
        input {
          flex: 1
        }
      }
    }
    label {
      display: flex;
      align-items: center;
    }
  }
  .radio-row {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
    label {
      margin: 0;
      cursor: pointer;
      padding: 10px 20px;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      transition: border .3s, background .3s;
      &:hover, &.active {
        background-color: rgba(255,255,255,.1);
        border-left: 2px solid #ddd;
        border-right: 2px solid #ddd;
      }
    }
  }
  .inner {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .product-selector {
    position: relative;
    width: 100%;
    input {
      width: 100%;
    }
    .results {
      color: #555;
      top: auto;
      bottom: 100%;
    }
  }
`

const ContainerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 25vh;
  z-index: 90;
`


export const LayoutSelector = (props) => {
  return <div className='layout-selector option-group radio-row'>
    <span className='row-label'>Choose a layout:</span>
    {(props.options || []).map(l => {
      return <label key={l} className={`${(props.value == l) && 'active'}`} onClick={() => props.onChange(l)}>{helpers.getTitle(l)}</label>
    })}
  </div>
}





let optionSets = []
let container = null

export class OptionsContainer extends React.Component {
  componentDidMount(){
    container = this
  }
  render() {
    return <ContainerContainer id='optionSet_container'>
      {optionSets.map(m => {
        return <div key={m.id} className={'options-wrapper '+m.optionSet.props.id} > {m.optionSet._render()} </div>
      })}
    </ContainerContainer>

  }
}

let optionsId = 1

export class OptionsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      display: 'none',
    }
  }

  componentDidMount() {
    optionsId++
    optionSets.push({id: optionsId, optionSet: this})
  }

  componentWillUnmount() {
    optionSets = optionSets.filter(m => m.optionSet != this)
    if(container){
      container.forceUpdate()
    }
  }

  // componentWillReceiveProps(props) {
  //   if(props.open != this.state.open){
  //     if(props.open){
  //       this.show();
  //     } else {
  //       this.hide();
  //     }
  //   }
  // }

  componentDidUpdate(oldProps) {
    if(container) container.forceUpdate()
    if(!oldProps.open && this.props.open){
      optionSets.forEach(o => {
        o.optionSet.hide()
      })
      this.setState({open: true})
    }
    if(oldProps.open && !this.props.open){
      this.hide()
    }
  }

  update(state) {
    this.setState(state)
  }

  hide = () => {
    if(this.state.open){
      this.setState({open: false});
      if(this.props.onClose){
        this.props.onClose()
      }
    }
  }

  _render() {
    return <Collapsible open={this.state.open}>
      <Container>
        <div className='material-icons close-panel' onClick={this.hide}>close</div>
        <div className='inner'>
          {this.props.children}
        </div>
      </Container>
    </Collapsible>
  }

  render() {
    return <div className='option-placeholder' />
  }
}


const SectionContainer = styled.div`
  box-sizing: border-box;
  &.editing {
    border: 5px dashed rgba(0, 100, 255, .7);
    z-index: 2;
  }
  .edit-button {
    text-align: right;
  }
`

export class Section extends React.Component {
  render(){
    const cn = this.props.className
    const { editing } = this.props
    return <SectionContainer {...this.props} className={`${cn} ${editing ? 'editing' : ''}`}>
      {this.props.children}
    </SectionContainer>
  }
}

// if(module.hot) module.hot.accept()
