import {getGlobal, setGlobal, apiGet, apiPost} from 'launchpad'
import { getProductImage, getCustomPrice } from './index'
import { getCFP } from './data'


export const isFas = x => {
  const w = x.warehouse || ''
  return x.sku.toLowerCase().startsWith('fas') || w.toLowerCase() == 'fas'
}

const defaultRetailModifier = 149

// classes to translate product quirks into easy to use methods

let updateTimer = null
const pushUpdate = () => {
  clearTimeout(updateTimer)
  updateTimer = setTimeout(() => {
    //getGlobal('app').forceUpdate()
  }, 100)
}

export class ProductContainer {
  constructor(source) {
    this.reset(source)
  }

  cache = {}
  listeners = []
  // to allow for placeholder categories
  subcategories = []

  reset = (data) => {
    if(!this.source) this.source = {}
    if(data && !data._id) return
    if(data) this.source = data
    Object.keys(this.source).forEach(key => {
      if(key != 'category'){
        this[key] = this.source[key]
      }
    })
    this.cache = {}
    this.syncListeners()
  }

  syncListeners = () => {
    if(this.listeners.length){
      //this.listeners.forEach(l => l.forceUpdate())
      this.listeners.forEach(l => l.setState({listenerTimestamp: Date.now()}))
    } else {
      pushUpdate()
    }
  }

  registerListener = (component) => {
    if(!this.listeners.includes(component)) this.listeners.push(component)
  }

  unregisterListener = (component) => {
    this.listeners = this.listeners.filter(c=>c!=component)
  }

  reload = async () => {
    return await this.update({})
  }

  resolvers = []
  _updateData = {}
  scheduledUpdate = null;

  update = (obj) => {
    this.syncing = true
    this.syncListeners()
    clearTimeout(this.scheduledUpdate)
    Object.assign(this._updateData, {_id: this.source._id}, obj)
    return new Promise((resolve, reject) => {
      this.resolvers.push(resolve)
      this.scheduledUpdate = setTimeout(() => {
        const update = apiPost(this.collection, this._updateData).then(data => {
          this.syncing = false
          this.syncListeners()
          this.resolvers.forEach(fn => {
            fn(data)
          })
          this.resolvers = []
          this._updateData = {}
          this.reset(data)
        })
      }, 300)
    }).catch(e => reject(e))
  }
}


export const relLink = (l) => {
  return l.startsWith('/') ? l : `/${l}`
}



export class Product extends ProductContainer {
  collection = 'series'

  // placeholder values for when product is empty
  category = {}
  skus = []
  priceMap = {}

  get fasOnly() {
    const qs = window.location.search
    return qs.includes('fasOnly')
  }

  getSkus = () => {
    //return this.skus.filter(sku => {
      let skus = this.skus.filter(sku => {
      if(!sku || !sku.sku) return false
      const fas = isFas(sku)
      if(this.fasOnly && !fas) return false
      if(!sku.visible) return false
      const inventory = this.priceMap[sku.sku] ? this.priceMap[sku.sku].inventory : sku.inventory
      if(fas) {
        if(inventory < 1) {
          return false
        }
      }
      // hide sku if size is 0x0 and sku isn't custom, and category isn't 'Accessories',
      // TODO: we should find a better way to deal with "sizeless" products
      if(!(sku.width || sku.length) && !(sku.sku && sku.sku.toLowerCase().startsWith('c')) && sku.category.name != 'Accessories') return false
      return true
    }).map(sku => {
      const details = this.priceMap[sku.sku]
      if(details){
        sku.price = details.price
        sku.inventory = details.inventory
      }
      return sku
    })
    //console.log("SKUS", skus);
    return skus;
  }

  load = async (id) => {
    const product = await apiGet('/series/'+id)
    this.reset(product)
    this.category = product.category
  }

  loadData = () => {
    return new Promise((resolve, reject) => {
      let completed = 0
      // const check = () => {
      //   completed += 1
      //   if(completed == 1) {
      //   //if(completed == 2){
      //     this.reset()
      //     return resolve()
      //   }
      // }
      this.priceMap = {}
      // apiGet('/product-data/'+this.source._id).then(p => {
      //   this.priceMap = p
      //   check()
      // })
      apiGet('/skus/'+this.source._id).then(s => {
        this.skus = s
        this.reset()
        resolve()
        //check()
      })
    })
  }


  get colorMap() {
    return this.color_map || {}
  }

  get colors() {
    // once server-side cache updating is integrated with sku save we can do:
    //return this.product.colors || [...new Set(this.skus.map(x => x.color))]

    if(!this.cache.colors) this.cache.colors = [...new Set(this.skus.map(x => x.color))]
    return this.cache.colors
  }

  set colors(colors) {
    // ignore for now
  }

  get imageMap() {
    if(!this.cache.imageMap) {
      let imageMap = {}
      const colorMap = this.colorMap
      const types = ['color_preview', 'profile_image', 'corner_image', 'preview_image']
      this.skus.forEach(sku => {
        if(!imageMap[sku.color]){
          imageMap[sku.color] = {}
          types.forEach(type => {
            imageMap[sku.color][type] = this.getImageFromSku(sku, type)
          })
        }
      })
      this.cache.imageMap = imageMap
    }
    return this.cache.imageMap
  }

  getBackground = (color, type) => {
    const img = this.getImage(color, type)
    if(!img || img.startsWith('#')) return {backgroundColor: img}
    return {backgroundImage: `url(${img})`}
  }

  checkType = (color, type) => {
    if(type == 'color_preview' && !(this.colorMap[color] && this.colorMap[color][type])) type = 'corner_image'
    return type
  }

  getImage = (color, type) => {
    const rawColor = color
    color = (color || '').replace(/\./g, '')
    type = this.checkType(color, type)
    if(this.colorMap[color] && this.colorMap[color][type]) return this.colorMap[color][type]
    if(this.colorMap[rawColor] && this.colorMap[rawColor][type]) return this.colorMap[rawColor][type]
    if(this.imageMap[color] && this.imageMap[color][type]) return this.imageMap[color][type]
    return this.imageMap[rawColor] && this.imageMap[rawColor][type]
  }

  getPriceTable = (item) => {
    return getPriceTable(this, item)
  }

  getCartItem = async (skus, qty, shellOnly, foamCore) => {
    const fas = skus.find(x => isFas(x))
    const standard = skus.find(x => !isFas(x))
    const custom = skus.find(x => x.sku.toLowerCase().startsWith('c'))
    let cartData = {
      sku: standard && standard.sku,
      price: standard && (this.priceMap[standard.sku] ? this.priceMap[standard.sku].price : standard.price),
      retailModifier: standard && (this.priceMap[standard.sku] ? this.priceMap[standard.sku].retail_modifier : defaultRetailModifier),
      description: standard && standard.name || fas && fas.name,

      fasSku: fas && fas.sku,
      fasPrice: fas && (this.priceMap[fas.sku] ? this.priceMap[fas.sku].price : fas.price),
      fasRetailModifier: fas && (this.priceMap[fas.sku] ? this.priceMap[fas.sku].retail_modifier : defaultRetailModifier),
      fasInventory: 0,
      fasDescription: fas && fas.name,

      qty: parseInt(qty),
      thumbnail: this.getImage((standard || fas).color, 'preview_image'),
      name: this.name,
      display_name: standard && standard.display_name || fas && fas.display_name,
      color: (standard || fas).color,
      width: (standard || fas).width,
      length: (standard || fas).length,
      series: skus[0].series || {_id: this._id}
    }

    if(custom) {
      cartData.custom = true
      cartData.shellOnly = shellOnly
      cartData.foamCore = foamCore
    }

    if(fas) {
      //const inventoryMap = await apiPost('/check-inventory', {skus: [fas.sku]})
      //console.log(inventoryMap)
      const details = this.priceMap[fas.sku] || fas
      cartData.fasInventory = details.inventory || 0
    }

    return cartData
  }

  getImageFromSku = (sku, type) => {
    type = this.checkType(sku.color, type)
    return getProductImage((this.colorMap[sku.color] && this.colorMap[sku.color][type]) || sku[type])
  }

  updateColorImage = (color, type, url) => {
    color = (color || '').replace(/\./g, '')
    const obj = {[type]: url}
    const updatedColor = this.colorMap[color] ? Object.assign({}, this.colorMap[color], obj) : obj
    const colorData = Object.assign({}, this.colorMap, {[color]: updatedColor})
    this.update({color_map: colorData})
  }

  getSkuPrice = (sku, options) => {
    const c = getGlobal('activeCustomer')
    options = options || {}
    const s = Object.assign({}, (this.priceMap[sku.sku] || {price: sku.price, inventory: sku.inventory, retail_modifier: defaultRetailModifier}))
    if(sku && sku.sku && sku.sku.toLowerCase().startsWith('c')){
      const {qty, l, w, shellOnly, foamCore} = options
      s.price = getCustomPrice(qty, l, w, s.price, shellOnly, foamCore) / qty
    }
    if(s) {
      if(options.wholesale || (c && c.wholesaleAccount)) {
        return s.price
      } else {
        //console.log("SPrice", s.price * (s.retail_modifier / 100) )
        return (s.price * (s.retail_modifier / 100))
      }
    }
  }

  setColorPriority = (color) => {
    const colors = this.color_sorting || []
    this.update({color_sorting: ([color]).concat(colors.filter(x=>x!=color))})
  }
}


export const getPriceTable = (product, item) => {
  const tier1 = getCFP('smallBulkCutOff')
  const tier2 = getCFP('medBulkCutOff')
  const tier3 = getCFP('largeBulkCutOff')
  if(item && item.custom) {
    const getPrice = qty => product.getSkuPrice(item, {qty, l: item.length, w: item.width, shellOnly: item.shellOnly, foamCore: item.foamCore})
    return {
      base: getPrice(1),
      tier0: {min: 1, max: tier1 - 1, price: getPrice(1)},
      tier1: {min: tier1, max: tier2, price: getPrice(tier1)},
      tier2: {min: tier2, max: tier3, price: getPrice(tier2)},
      tier3: {min: tier3, price: getPrice(tier3)}
    }
  }
}




// use this to load a product object with skus
export const loadProduct = async (id) => {
  const p = new Product()
  await p.load(id)
  return p
}

 if(module.hot) module.hot.accept()
