import { DataStore } from 'launchpad'

const couponSchema = {
  code: 'text',
  type: {type: 'select', options: ['percent', 'fixed']},
  amount: 'number',
  usercount: 'number',
  items: {type: 'select'},
  productype:{type: 'select', options: ['Category', 'Product', 'SKU','FAS']},
}

export const Coupons = DataStore('coupons', couponSchema)

export const getCoupon = code => Coupons.getAll().find(x => {
 //console.log('getCoupon',x.code)
 return x.code == code
})

export const validateCoupon = (cart, subtotal) => {
  const coupon = cart.coupon
  //console.log('validateCoupon',coupon,cart)
  if(!coupon) return {valid: false}
  subtotal = subtotal || cart.subtotal
  const min = (coupon && parseFloat(coupon.minimum_order)) || 0
  const today = (new Date()).getTime()
  //console.log("Testtttttt Today", today);
  let expDate = coupon && coupon.expiration ? new Date(coupon.expiration) : null
  if(expDate != null) {
    expDate.setHours(23);
    expDate.setMinutes(59);
    expDate.setSeconds(59);
  }
  //console.log("Testtttttt ExpDate", expDate);
  const startDate = coupon && coupon.start_date ? new Date(coupon.start_date) : null
  //console.log("Testtttttt startDate", startDate);
  const expiration = expDate ? `${expDate.getMonth() + 1}/${expDate.getDate()}/${expDate.getFullYear()}` : ''
  const start = startDate ? `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}` : ''
  const expired = expDate && today > expDate.getTime()
  //console.log("Testtttttt expired", expired);
  const started = !startDate || today > startDate.getTime()
  const validCustomers = coupon.customers == 'all-customers' || (cart.isWholesale() ? (coupon.customers == 'wholesale') : (coupon.customers != 'wholesale'))
  return {
    valid: subtotal > min && !expired && started && validCustomers,
    expired,
    expiration,
    started,
    start,
    invalidCustomerType: !validCustomers ? coupon.customers : '',
    subtotalMargin: Math.max(0, min - subtotal)
  }
}
