
// AUTOMATICALLY GENERATED -- see src/images/icon_list.js

export const icons = {
  'photo-camera': require('images/FUSA_icons/004-photo-camera.svg'),
  'watch': require('images/FUSA_icons/005-watch.svg'),
  'handbag': require('images/FUSA_icons/006-handbag.svg'),
  'diamond-ring': require('images/FUSA_icons/007-diamond-ring.svg'),
  'dress': require('images/FUSA_icons/008-dress.svg'),
  'shirt': require('images/FUSA_icons/009-shirt.svg'),
  'auction': require('images/FUSA_icons/010-auction.svg'),
  'online-shop-10': require('images/FUSA_icons/011-online-shop-10.svg'),
  'filter': require('images/FUSA_icons/012-filter.svg'),
  'calculator': require('images/FUSA_icons/013-calculator.svg'),
  'barcode-1': require('images/FUSA_icons/014-barcode-1.svg'),
  'barcode': require('images/FUSA_icons/015-barcode.svg'),
  'exchange': require('images/FUSA_icons/016-exchange.svg'),
  'bitcoin': require('images/FUSA_icons/017-bitcoin.svg'),
  'yen': require('images/FUSA_icons/018-yen.svg'),
  'euro': require('images/FUSA_icons/019-euro.svg'),
  'dollar': require('images/FUSA_icons/020-dollar.svg'),
  'safebox': require('images/FUSA_icons/021-safebox.svg'),
  'search': require('images/FUSA_icons/022-search.svg'),
  'wallet': require('images/FUSA_icons/023-wallet.svg'),
  'money': require('images/FUSA_icons/024-money.svg'),
  'smartwatch': require('images/FUSA_icons/025-smartwatch.svg'),
  'payment-method-2': require('images/FUSA_icons/026-payment-method-2.svg'),
  'payment-method-1': require('images/FUSA_icons/027-payment-method-1.svg'),
  'payment-method': require('images/FUSA_icons/028-payment-method.svg'),
  'invoice-3': require('images/FUSA_icons/029-invoice-3.svg'),
  'invoice-2': require('images/FUSA_icons/030-invoice-2.svg'),
  'invoice-1': require('images/FUSA_icons/031-invoice-1.svg'),
  'invoice': require('images/FUSA_icons/032-invoice.svg'),
  'delivery': require('images/FUSA_icons/033-delivery.svg'),
  'credit-card-4': require('images/FUSA_icons/034-credit-card-4.svg'),
  'credit-card-3': require('images/FUSA_icons/035-credit-card-3.svg'),
  'credit-card-2': require('images/FUSA_icons/036-credit-card-2.svg'),
  'credit-card-1': require('images/FUSA_icons/037-credit-card-1.svg'),
  'laptop': require('images/FUSA_icons/038-laptop.svg'),
  'online-shop-9': require('images/FUSA_icons/039-online-shop-9.svg'),
  'buy': require('images/FUSA_icons/040-buy.svg'),
  'online-shop-8': require('images/FUSA_icons/041-online-shop-8.svg'),
  'online-shop-7': require('images/FUSA_icons/042-online-shop-7.svg'),
  'online-shop-6': require('images/FUSA_icons/043-online-shop-6.svg'),
  'sales': require('images/FUSA_icons/044-sales.svg'),
  'online-shop-5': require('images/FUSA_icons/045-online-shop-5.svg'),
  'online-shop-4': require('images/FUSA_icons/046-online-shop-4.svg'),
  'online-shop-3': require('images/FUSA_icons/047-online-shop-3.svg'),
  'online-shop-2': require('images/FUSA_icons/048-online-shop-2.svg'),
  'shopping-bag-3': require('images/FUSA_icons/049-shopping-bag-3.svg'),
  'shopping-bag-2': require('images/FUSA_icons/050-shopping-bag-2.svg'),
  '24-hours-1': require('images/FUSA_icons/051-24-hours-1.svg'),
  'delivery-truck-3': require('images/FUSA_icons/052-delivery-truck-3.svg'),
  'delivery-truck-2': require('images/FUSA_icons/053-delivery-truck-2.svg'),
  'cloud-computing': require('images/FUSA_icons/054-cloud-computing.svg'),
  'online-shop-1': require('images/FUSA_icons/055-online-shop-1.svg'),
  'new': require('images/FUSA_icons/056-new.svg'),
  'shop': require('images/FUSA_icons/057-shop.svg'),
  'chat': require('images/FUSA_icons/058-chat.svg'),
  'placeholder-1': require('images/FUSA_icons/059-placeholder-1.svg'),
  'headset': require('images/FUSA_icons/060-headset.svg'),
  'telephone-1': require('images/FUSA_icons/061-telephone-1.svg'),
  '24-hours': require('images/FUSA_icons/062-24-hours.svg'),
  'like': require('images/FUSA_icons/063-like.svg'),
  'rating': require('images/FUSA_icons/064-rating.svg'),
  'coupon': require('images/FUSA_icons/065-coupon.svg'),
  'shopping-bag-1': require('images/FUSA_icons/066-shopping-bag-1.svg'),
  'delivery-truck-1': require('images/FUSA_icons/067-delivery-truck-1.svg'),
  'shopping-cart-1': require('images/FUSA_icons/068-shopping-cart-1.svg'),
  'discount-1': require('images/FUSA_icons/069-discount-1.svg'),
  'discount': require('images/FUSA_icons/070-discount.svg'),
  'worldwide': require('images/FUSA_icons/071-worldwide.svg'),
  'email': require('images/FUSA_icons/072-email.svg'),
  'placeholder': require('images/FUSA_icons/073-placeholder.svg'),
  'delivery-truck': require('images/FUSA_icons/074-delivery-truck.svg'),
  'package-2': require('images/FUSA_icons/075-package-2.svg'),
  'package-1': require('images/FUSA_icons/076-package-1.svg'),
  'shopping-bag': require('images/FUSA_icons/077-shopping-bag.svg'),
  'box-3': require('images/FUSA_icons/078-box-3.svg'),
  'box-2': require('images/FUSA_icons/079-box-2.svg'),
  'box-1': require('images/FUSA_icons/080-box-1.svg'),
  'box': require('images/FUSA_icons/081-box.svg'),
  'fragile': require('images/FUSA_icons/082-fragile.svg'),
  'umbrella': require('images/FUSA_icons/083-umbrella.svg'),
  'package': require('images/FUSA_icons/084-package.svg'),
  'trolley-1': require('images/FUSA_icons/085-trolley-1.svg'),
  'trolley': require('images/FUSA_icons/086-trolley.svg'),
  'telephone': require('images/FUSA_icons/087-telephone.svg'),
  'shopping-basket-1': require('images/FUSA_icons/088-shopping-basket-1.svg'),
  'credit-card': require('images/FUSA_icons/089-credit-card.svg'),
  'shopping-basket': require('images/FUSA_icons/090-shopping-basket.svg'),
  'key': require('images/FUSA_icons/091-key.svg'),
  'shield': require('images/FUSA_icons/092-shield.svg'),
  'tag': require('images/FUSA_icons/093-tag.svg'),
  'gift': require('images/FUSA_icons/094-gift.svg'),
  'list-1': require('images/FUSA_icons/095-list-1.svg'),
  'list': require('images/FUSA_icons/096-list.svg'),
  'clipboard': require('images/FUSA_icons/097-clipboard.svg'),
  'basketball': require('images/FUSA_icons/098-basketball.svg'),
  'online-shop': require('images/FUSA_icons/099-online-shop.svg'),
  'shopping-cart': require('images/FUSA_icons/100-shopping-cart.svg')
}