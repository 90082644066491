
// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information
  
import React from 'react'
import Loadable from 'react-loadable'
import loadable from '@loadable/component'

export const pageList = [
  "AllProducts",
  "BulkOrder",
  "Cart",
  "Catalog",
  "Checkout",
  "Contact",
  "CustomerService",
  "DesignAFrame/DesignAFrame",
  "GeneralLayout",
  "Home",
  "InStock",
  "Login",
  "MatDesigners/MatDesigners",
  "OrderReview",
  "ResetPassword",
  "Reviews",
  "Terms",
  "UserDashboard",
  "Wholesale",
  "WholesaleRegistration",
  "admin/AdminLogin",
  "admin/CategoryManager",
  "admin/CouponManagement",
  "admin/CustomPriceManager",
  "admin/CustomerManager",
  "admin/DesignAFrame",
  "admin/EmailManager",
  "admin/Importer",
  "admin/MatDesigner",
  "admin/OrderManager",
  "admin/PageManager",
  "admin/ProductManager",
  "admin/SKUManager",
  "admin/SalesTaxManager",
  "admin/ShippingManager",
  "admin/UserManager"
];

export const pageIds = [
  {
    "url": "/products",
    "id": "all-products",
    "title": "All Products"
  },
  {
    "url": "/bulk-order",
    "id": "bulk-order",
    "title": "Bulk Order"
  },
  {
    "url": "/cart",
    "id": "cart",
    "title": "Cart"
  },
  {
    "url": "/catalog",
    "id": "catalog",
    "title": "Catalog"
  },
  {
    "url": "/checkout",
    "id": "checkout",
    "title": "Checkout"
  },
  {
    "url": "/contact",
    "id": "contact",
    "title": "Contact"
  },
  {
    "url": "/customer-service",
    "id": "customer-service",
    "title": "Customer Service"
  },
  {
    "url": "/design-a-frame",
    "id": "design-a-frame",
    "title": "Design A Frame"
  },
  {
    "url": "/generallayout",
    "id": "general",
    "title": "General"
  },
  {
    "url": "/home",
    "id": "home",
    "title": "Home"
  },
  {
    "url": "/in-stock",
    "id": "in-stock",
    "title": "In Stock"
  },
  {
    "url": "/login",
    "id": "login",
    "title": "Login"
  },
  {
    "url": "/mat-designers",
    "id": "mat-designers",
    "title": "Mat Designers"
  },
  {
    "url": "/order-review",
    "id": "order-review",
    "title": "Order Review"
  },
  {
    "url": "/reset-password",
    "id": "reset-password",
    "title": "Reset Password"
  },
  {
    "url": "/reviews",
    "id": "reviews",
    "title": "Reviews"
  },
  {
    "url": "/terms",
    "id": "terms",
    "title": "Terms"
  },
  {
    "url": "/user-dashboard",
    "id": "user-dashboard",
    "title": "User Dashboard"
  },
  {
    "url": "/wholesale",
    "id": "wholesale-landing",
    "title": "Wholesale Landing"
  },
  {
    "url": "/wholesale-registration",
    "id": "wholesale-registration",
    "title": "Wholesale Registration"
  },
  {
    "url": "/admin/user-register",
    "id": "admin-user-login",
    "title": "Admin User Login"
  },
  {
    "url": "/admin/categories",
    "id": "category-manager",
    "title": "Category Manager"
  },
  {
    "url": "/admin/coupons",
    "id": "coupon-manager",
    "title": "Coupon Manager"
  },
  {
    "url": "/admin/custom-prices",
    "id": "custom-price-manager",
    "title": "Custom Price Manager"
  },
  {
    "url": "/admin/customers",
    "id": "customer-manager",
    "title": "Customers"
  },
  {
    "url": "/admin/design-a-frame",
    "id": "design-a-frame-manager",
    "title": "Design A Frame Manager"
  },
  {
    "url": "/admin/emails",
    "id": "email-manager",
    "title": "Emails"
  },
  {
    "url": "/admin/importer",
    "id": "product-importer",
    "title": "Product Importer"
  },
  {
    "url": "/admin/mat-designer",
    "id": "mat-designer-manager",
    "title": "Mat Designer Manager"
  },
  {
    "url": "/admin/orders",
    "id": "order-manager",
    "title": "Orders"
  },
  {
    "url": "/admin/pages",
    "id": "page-manager",
    "title": "Custom Pages"
  },
  {
    "url": "/admin/products",
    "id": "product-manager",
    "title": "Product Manager"
  },
  {
    "url": "/admin/skus",
    "id": "sku-manager",
    "title": "SKU Manager"
  },
  {
    "url": "/admin/sales-tax-manager",
    "id": "tax-manager",
    "title": "Sales Tax Manager"
  },
  {
    "url": "/admin/shipping-manager",
    "id": "shipping-manager",
    "title": "Shipping Manager"
  },
  {
    "url": "/admin/users",
    "id": "user-manager",
    "title": "Users"
  }
];

export const pageMap = {
  "directory": "/",
  "children": [
    {
      "title": " All Products",
      "href": "AllProducts"
    },
    {
      "title": " Bulk Order",
      "href": "BulkOrder"
    },
    {
      "title": " Cart",
      "href": "Cart"
    },
    {
      "title": " Catalog",
      "href": "Catalog"
    },
    {
      "title": " Checkout",
      "href": "Checkout"
    },
    {
      "title": " Contact",
      "href": "Contact"
    },
    {
      "title": " Customer Service",
      "href": "CustomerService"
    },
    {
      "directory": "DesignAFrame",
      "children": [
        {
          "title": " Design A Frame",
          "href": "DesignAFrame/DesignAFrame"
        }
      ]
    },
    {
      "title": " General Layout",
      "href": "GeneralLayout"
    },
    {
      "title": " Home",
      "href": "Home"
    },
    {
      "title": " In Stock",
      "href": "InStock"
    },
    {
      "title": " Login",
      "href": "Login"
    },
    {
      "directory": "MatDesigners",
      "children": [
        {
          "title": " Mat Designers",
          "href": "MatDesigners/MatDesigners"
        }
      ]
    },
    {
      "title": " Order Review",
      "href": "OrderReview"
    },
    {
      "title": " Reset Password",
      "href": "ResetPassword"
    },
    {
      "title": " Reviews",
      "href": "Reviews"
    },
    {
      "title": " Terms",
      "href": "Terms"
    },
    {
      "title": " User Dashboard",
      "href": "UserDashboard"
    },
    {
      "title": " Wholesale",
      "href": "Wholesale"
    },
    {
      "title": " Wholesale Registration",
      "href": "WholesaleRegistration"
    },
    {
      "directory": "admin",
      "children": [
        {
          "title": " Admin Login",
          "href": "admin/AdminLogin"
        },
        {
          "title": " Category Manager",
          "href": "admin/CategoryManager"
        },
        {
          "title": " Coupon Management",
          "href": "admin/CouponManagement"
        },
        {
          "title": " Custom Price Manager",
          "href": "admin/CustomPriceManager"
        },
        {
          "title": " Customer Manager",
          "href": "admin/CustomerManager"
        },
        {
          "title": " Design A Frame",
          "href": "admin/DesignAFrame"
        },
        {
          "title": " Email Manager",
          "href": "admin/EmailManager"
        },
        {
          "title": " Importer",
          "href": "admin/Importer"
        },
        {
          "title": " Mat Designer",
          "href": "admin/MatDesigner"
        },
        {
          "title": " Order Manager",
          "href": "admin/OrderManager"
        },
        {
          "title": " Page Manager",
          "href": "admin/PageManager"
        },
        {
          "title": " Product Manager",
          "href": "admin/ProductManager"
        },
        {
          "title": " S K U Manager",
          "href": "admin/SKUManager"
        },
        {
          "title": " Sales Tax Manager",
          "href": "admin/SalesTaxManager"
        },
        {
          "title": " Shipping Manager",
          "href": "admin/ShippingManager"
        },
        {
          "title": " User Manager",
          "href": "admin/UserManager"
        }
      ]
    }
  ]
};

export const pageProps = {
  "AllProducts": {
    "id": "all-products",
    "launchpad_url": "products",
    "url": "/products"
  },
  "BulkOrder": {
    "id": "bulk-order",
    "launchpad_url": "bulk-order",
    "url": "/bulk-order"
  },
  "Cart": {
    "id": "cart",
    "url": "/cart"
  },
  "Catalog": {
    "id": "catalog",
    "launchpad_url": "catalog",
    "url": "/catalog"
  },
  "Checkout": {
    "id": "checkout",
    "url": "/checkout"
  },
  "Contact": {
    "id": "contact",
    "url": "/contact"
  },
  "CustomerService": {
    "id": "customer-service",
    "className": "customer-service-page",
    "launchpad_title": "Customer Service",
    "launchpad_url": "/customer-service",
    "url": "/customer-service"
  },
  "DesignAFrame/DesignAFrame": {
    "id": "design-a-frame",
    "launchpad_url": "design-a-frame",
    "url": "/design-a-frame"
  },
  "GeneralLayout": {
    "id": "general",
    "className": "general-page",
    "url": "/generallayout"
  },
  "Home": {
    "id": "home",
    "url": "/home"
  },
  "InStock": {
    "id": "in-stock",
    "launchpad_url": "in-stock",
    "url": "/in-stock"
  },
  "Login": {
    "id": "login",
    "className": "about-page",
    "url": "/login"
  },
  "MatDesigners/MatDesigners": {
    "id": "mat-designers",
    "launchpad_url": "mat-designers",
    "url": "/mat-designers"
  },
  "OrderReview": {
    "id": "order-review",
    "className": "container",
    "launchpad_url": "/order-review",
    "url": "/order-review"
  },
  "ResetPassword": {
    "id": "reset-password",
    "className": "about-page",
    "launchpad_url": "reset-password",
    "launchpad_title": "Reset Password",
    "url": "/reset-password"
  },
  "Reviews": {
    "id": "reviews",
    "launchpad_url": "reviews",
    "url": "/reviews"
  },
  "Terms": {
    "id": "terms",
    "launchpad_url": "terms",
    "url": "/terms"
  },
  "UserDashboard": {
    "id": "user-dashboard",
    "className": "container",
    "launchpad_url": "/user-dashboard",
    "url": "/user-dashboard"
  },
  "Wholesale": {
    "id": "wholesale-landing",
    "launchpad_url": "wholesale",
    "url": "/wholesale"
  },
  "WholesaleRegistration": {
    "id": "wholesale-registration",
    "launchpad_url": "wholesale-registration",
    "url": "/wholesale-registration"
  },
  "admin/AdminLogin": {
    "id": "admin-user-login",
    "className": "about-page",
    "launchpad_url": "admin/user-register",
    "launchpad_type": "admin",
    "url": "/admin/user-register"
  },
  "admin/CategoryManager": {
    "id": "category-manager",
    "className": "padded",
    "launchpad_title": "Category Manager",
    "launchpad_url": "admin/categories",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "list",
    "url": "/admin/categories"
  },
  "admin/CouponManagement": {
    "id": "coupon-manager",
    "className": "padded",
    "launchpad_title": "Coupon Manager",
    "launchpad_url": "admin/coupons",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "Order Tools",
    "launchpad_type": "admin",
    "launchpad_icon": "barcode",
    "url": "/admin/coupons"
  },
  "admin/CustomPriceManager": {
    "id": "custom-price-manager",
    "className": "padded",
    "launchpad_title": "Custom Price Manager",
    "launchpad_url": "admin/custom-prices",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "dollar",
    "url": "/admin/custom-prices"
  },
  "admin/CustomerManager": {
    "id": "customer-manager",
    "className": "padded",
    "launchpad_title": "Customers",
    "launchpad_url": "admin/customers",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "Order Tools",
    "launchpad_type": "admin",
    "launchpad_icon": "user",
    "url": "/admin/customers"
  },
  "admin/DesignAFrame": {
    "id": "design-a-frame-manager",
    "className": "padded",
    "launchpad_title": "Design A Frame Manager",
    "launchpad_url": "admin/design-a-frame",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "palette",
    "url": "/admin/design-a-frame"
  },
  "admin/EmailManager": {
    "id": "email-manager",
    "className": "padded",
    "launchpad_title": "Emails",
    "launchpad_url": "admin/emails",
    "launchpad_admin_panel": "true",
    "launchpad_type": "admin",
    "launchpad_icon": "envelope",
    "url": "/admin/emails"
  },
  "admin/Importer": {
    "id": "product-importer",
    "className": "padded",
    "launchpad_title": "Product Importer",
    "launchpad_url": "admin/importer",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "download",
    "url": "/admin/importer"
  },
  "admin/MatDesigner": {
    "id": "mat-designer-manager",
    "className": "padded",
    "launchpad_title": "Mat Designer Manager",
    "launchpad_url": "admin/mat-designer",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "palette",
    "url": "/admin/mat-designer"
  },
  "admin/OrderManager": {
    "id": "order-manager",
    "className": "padded",
    "launchpad_title": "Orders",
    "launchpad_url": "admin/orders",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "Order Tools",
    "launchpad_user_access": "[\"admin\"]",
    "launchpad_type": "admin",
    "launchpad_icon": "dollar",
    "url": "/admin/orders"
  },
  "admin/PageManager": {
    "id": "page-manager",
    "className": "padded",
    "launchpad_title": "Custom Pages",
    "launchpad_url": "admin/pages",
    "launchpad_type": "admin",
    "launchpad_admin_panel": "true",
    "launchpad_icon": "list",
    "url": "/admin/pages"
  },
  "admin/ProductManager": {
    "id": "product-manager",
    "className": "padded",
    "launchpad_title": "Product Manager",
    "launchpad_url": "admin/products",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "shopping-cart",
    "url": "/admin/products"
  },
  "admin/SKUManager": {
    "id": "sku-manager",
    "className": "padded",
    "launchpad_title": "SKU Manager",
    "launchpad_url": "admin/skus",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "product tools",
    "launchpad_type": "admin",
    "launchpad_icon": "barcode",
    "url": "/admin/skus"
  },
  "admin/SalesTaxManager": {
    "id": "tax-manager",
    "className": "padded",
    "launchpad_title": "Sales Tax Manager",
    "launchpad_url": "admin/sales-tax-manager",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "Order Tools",
    "launchpad_type": "admin",
    "launchpad_icon": "palette",
    "url": "/admin/sales-tax-manager"
  },
  "admin/ShippingManager": {
    "id": "shipping-manager",
    "launchpad_title": "Shipping Manager",
    "launchpad_url": "admin/shipping-manager",
    "launchpad_admin_panel": "true",
    "launchpad_admin_group": "Order Tools",
    "launchpad_type": "admin",
    "launchpad_icon": "box",
    "url": "/admin/shipping-manager"
  },
  "admin/UserManager": {
    "id": "user-manager",
    "className": "padded",
    "launchpad_title": "Users",
    "launchpad_url": "admin/users",
    "launchpad_admin_panel": "true",
    "launchpad_type": "admin",
    "launchpad_icon": "user",
    "url": "/admin/users"
  }
};

export const pageLinks = pageIds.map(page => page.url)

export const pageComponents = {
    "AllProducts": loadable(() => import("pages/AllProducts.jsx")),
    "BulkOrder": loadable(() => import("pages/BulkOrder.jsx")),
    "Cart": loadable(() => import("pages/Cart.jsx")),
    "Catalog": loadable(() => import("pages/Catalog.jsx")),
    "Checkout": loadable(() => import("pages/Checkout.jsx")),
    "Contact": loadable(() => import("pages/Contact.jsx")),
    "CustomerService": loadable(() => import("pages/CustomerService.jsx")),
    "DesignAFrame/DesignAFrame": loadable(() => import("pages/DesignAFrame/DesignAFrame.jsx")),
    "GeneralLayout": loadable(() => import("pages/GeneralLayout.jsx")),
    "Home": loadable(() => import("pages/Home.jsx")),
    "InStock": loadable(() => import("pages/InStock.jsx")),
    "Login": loadable(() => import("pages/Login.jsx")),
    "MatDesigners/MatDesigners": loadable(() => import("pages/MatDesigners/MatDesigners.jsx")),
    "OrderReview": loadable(() => import("pages/OrderReview.jsx")),
    "ResetPassword": loadable(() => import("pages/ResetPassword.jsx")),
    "Reviews": loadable(() => import("pages/Reviews.jsx")),
    "Terms": loadable(() => import("pages/Terms.jsx")),
    "UserDashboard": loadable(() => import("pages/UserDashboard.jsx")),
    "Wholesale": loadable(() => import("pages/Wholesale.jsx")),
    "WholesaleRegistration": loadable(() => import("pages/WholesaleRegistration.jsx")),
    "admin/AdminLogin": loadable(() => import("pages/admin/AdminLogin.jsx")),
    "admin/CategoryManager": loadable(() => import("pages/admin/CategoryManager.jsx")),
    "admin/CouponManagement": loadable(() => import("pages/admin/CouponManagement.jsx")),
    "admin/CustomPriceManager": loadable(() => import("pages/admin/CustomPriceManager.jsx")),
    "admin/CustomerManager": loadable(() => import("pages/admin/CustomerManager.jsx")),
    "admin/DesignAFrame": loadable(() => import("pages/admin/DesignAFrame.jsx")),
    "admin/EmailManager": loadable(() => import("pages/admin/EmailManager.jsx")),
    "admin/Importer": loadable(() => import("pages/admin/Importer.jsx")),
    "admin/MatDesigner": loadable(() => import("pages/admin/MatDesigner.jsx")),
    "admin/OrderManager": loadable(() => import("pages/admin/OrderManager.jsx")),
    "admin/PageManager": loadable(() => import("pages/admin/PageManager.jsx")),
    "admin/ProductManager": loadable(() => import("pages/admin/ProductManager.jsx")),
    "admin/SKUManager": loadable(() => import("pages/admin/SKUManager.jsx")),
    "admin/SalesTaxManager": loadable(() => import("pages/admin/SalesTaxManager.jsx")),
    "admin/ShippingManager": loadable(() => import("pages/admin/ShippingManager.jsx")),
    "admin/UserManager": loadable(() => import("pages/admin/UserManager.jsx")),
}

//if(module.hot) module.hot.accept()

// ===============  AUTOMATICALLY GENERATED  =======================
// Any edits made to this file will be overwritten on the next build
// See scripts/build-scripts/index-pages.js for more information
  