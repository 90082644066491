/*
----------------
index.jsx
----------------
This is the entry point for our app.

Webpack starts with this file and pulls in all imports to create the bundle.js.
*/
import "babel-polyfill"
import React from 'react';
import App, {history} from './app';
import {launchpadInit} from 'launchpad';
import { run_preflight } from 'launchpad/devops/pre-flight'

// do a sanity check before proceeding
run_preflight();

launchpadInit(<App />, 'root', {history: history});

if(module.hot) module.hot.accept()
