import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  .path {
    stroke:#a3ec7b;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out;
  }

  .circle-path {
    stroke:#a3ec7b;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash2 1s ease-in-out;
  }

  @keyframes dash {
    0% {stroke-dashoffset: -1000;}
    100% {stroke-dashoffset: 0;}
  }

  @keyframes dash2 {
    0% {stroke-dashoffset: 1000;}
    100% {stroke-dashoffset: 0;}
  }
`

export default class Checkmark extends React.Component {
  render(){
    return(
      <Container className={`animated-checkmark ${this.props.className}`} style={this.props.style}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2">
        <circle className="circle-path primary-stroke" fill="none" strokeWidth="8" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"/>
        <polyline className="path primary-stroke" fill="none" strokeWidth="11" strokeLinecap="round" strokeMiterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4 "/>
        </svg>
      </Container>
    )
  }
}



const WarningContainer = styled.div`
  .path, .dot {
    stroke:#dadc00;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out;
  }

  .circle-path {
    stroke:#dadc00;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: dash2 1s ease-in-out;
  }

  @keyframes dash {
    0% {stroke-dashoffset: -500;}
    100% {stroke-dashoffset: 0;}
  }

  @keyframes dash2 {
    0% {stroke-dashoffset: 1000;}
    100% {stroke-dashoffset: 0;}
  }
`

export class WarningIcon extends React.Component {
  render(){
    return(
      <WarningContainer className={`animated-checkmark ${this.props.className}`} style={this.props.style}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 161.2 161.2" enableBackground="new 0 0 161.2 161.2">
        {/* <circle className="circle-path primary-stroke" fill="none" strokeWidth="8" strokeMiterlimit="10" cx="80.6" cy="80.6" r="62.1"/> */}
        <polyline className='circle-path primary-stroke' fill="none" strokeWidth="10" strokeLinejoin="round" strokeMiterlimit="10" strokeLinecap="round" points="150,130, 10,130, 80.6,20, 150,130, 10,130"/>
        <polyline className="path primary-stroke" fill="none" strokeWidth="15" strokeLinecap="round" strokeMiterlimit="10" points="80.6,85 80.6,55"/>
        <polyline className="dot primary-stroke" fill="none" strokeWidth="18" strokeLinecap="round" strokeMiterlimit="10" points="80.6,108 80.6,108"/>
        </svg>
      </WarningContainer>
    )
  }
}
