import React from 'react';
import {getGlobal, setGlobal} from 'launchpad';


let modals = []
let container = null

export class ModalContainer extends React.Component {
  componentDidMount(){
    container = this
  }
  render() {
    return <div id='modal_container'>
      {modals.map(m => {
        return <div key={m.id} className={'modal-wrapper '+m.modal.props.id} > {m.modal._render()} </div>
      })}
    </div>
  }
}

let modalId = 1

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      display: 'none',
    }
  }

  componentDidMount() {
    modalId++
    modals.push({id: modalId, modal: this})
  }

  componentWillUnmount() {
    modals = modals.filter(m => m.modal != this)
    if(container){
      container.forceUpdate()
    }
  }

  componentWillReceiveProps(props) {
    if(props.open != this.state.open){
      if(props.open){
        this.show();
      } else {
        this.hide();
      }
    }
  }

  componentDidUpdate() {
    if(container) container.forceUpdate()
  }

  update(state) {
    this.setState(state)
  }

  show() {
    //clearTimeout(this.timer)
    //this.setState({display: 'flex'});
    setTimeout(() => this.setState({open: true}), 10);

  }

  hide() {
    if(this.state.open){
      this.setState({open: false});
      this.timer = setTimeout(() => this.setState({display: 'none'}), 500)
      if(this.props.onClose){
        this.props.onClose()
      }
    }
  }

  _render() {
    let header = [];
    if(this.props.title){
      header.push(<h3 key='title'>{this.props.title}</h3>)
    }
    header.push(<button key='close' onClick={() => this.hide()} className='material-icons'>close</button>)

    return <div id={'modal_'+this.props.id}
        className={'modal' + (this.state.open ? '' : ' closed') }
        //style={{display: this.state.display}}
        onClick={() => this.hide()}>

        <div className='modal-container'>
          <div className='modal-body' onClick={e => {e.stopPropagation()}} style={this.props.modalBodyStyle}>
            <div className='modal-header'>
              {header}
            </div>
            <div className='modal-content'>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
  }

  render() {
    return <div className='modal-placeholder' />
  }
}
