import {apiPost, notify} from 'launchpad'

const prod = process.env.environment == 'production'
const authConfig = require('global_config').default.authnet[prod ? 'prod' : 'dev']
// Authnet creds
// TODO: maybe move to .env
const apiLoginID = authConfig.login
const clientKey = authConfig.client
console.log("prod",prod);
const s = document.createElement('script');
s.type = 'text/javascript'
s.src = prod ? 'https://js.authorize.net/v1/Accept.js' : 'https://jstest.authorize.net/v1/Accept.js'
//s.src = 'https://js.authorize.net/v1/Accept.js'
s.charset = 'utf-8'
document.head.appendChild(s)

export const startPayment = (cardData, cart) => {
  const amount = cart.getSummary().total
  return new Promise(async (resolve, reject) => {
    await cart.updateData({})
    const fail = (e) => {
      if(e && e.error && e.error.transactionResponse) {
        console.log("Test Test Test test", e )
        //  notify(`Sorry, something seems to have gone wrong processing your payment! Please double check your information and try again`, {type: 'error'})
          notify(e.error.transactionResponse.errors[0].errorText,{type:'error'})
      } else {
        notify(`Sorry, something seems to have gone wrong processing your payment! Please double check your information and try again`, {type: 'error'})
      }
      return reject()
    }

    var authData = {apiLoginID, clientKey}
    console.log("apiLoginID", apiLoginID);
    console.log("clientKey", clientKey);
    if(window.Accept) {
      window.Accept.dispatchData({ authData, cardData }, resp => {
        console.log("RESP", resp);
        if(resp.messages.resultCode == 'Error'){    
          // TODO: authnet api weirdness, wasn't necessary at first, but now preflight OPTIONS request causes this error and needs to be ignored
          // We should revisit this at some point since it could swallow legitimate errors
          if(resp.messages.message[0].code == 'E_WC_14') return

          resp.messages.message.forEach(m => console.error(m.text))
          fail(resp)
        } else if(resp.opaqueData){
          apiPost(`make-payment/${cart._id}`, { opaqueData: resp.opaqueData, amount, email: cart.email, gRecaptchaResponse: cardData.gRecaptchaResponse }).then(item => {
            console.log(item);
            if(item.error) {
              reject(item.error)
              return fail(item)
            }
            resolve(item)
          }).catch(e => fail(e))
        }
      })
    } else {
      notify(`Sorry, it looks like we're unable to process your payment right now, please try again later`, {type: 'warning'})
      console.log('auth.net accept.js not connected')
    }
  })
}
