// TODO: might move some of these to .env to prevent accidental misconfiguration

// TODO: wire these up per-environment
module.exports.default = {
  authnet: {
    dev: {
      login: '533QsfzHvp37',
      transaction: '9R8V9r48Txnv4TZm',
      client: '45s34Y3rwv7H4tn8dMYxSU29ZYzMrLpXZXGU9rGKKGz7tjJj6h2zEg7yvuT6YQzn'
    },
    // Old FrameUSA
    // prod: {
    //   login: '7p7tp4CC2L',
    //   transaction: '86Ce9v5Q9gW2RtEe',
    //   client: '8hJurL5ZWaXUfD3882XN74x9pYmyEVfp62tQBtRA2ntQfCYuyA2Cp63w99ve8H2P'
    // }
    // Craig Frames
    prod: {
      login: '5K7H92hk29',
      transaction: '6q6D4dwUvJM538GQ',
      client: '2J5R5C2Y2aGQfctw9HvzJ7k2XpaLc5YJNNn64C6z8g8ApCKu4rp236kbjeV9GRcf'
    }
  },
  paypal: {
    dev: 'AZwqhTsBOyY_R3VQrJYPgqsFQRI12-HC38QO8llvBVA9QK1UDju-Ms0rZZDjk_yeKANYAFAYE6G-Zl6w',
    prod: 'AdUX2Crxc2kblWnhGrKSuhWni3pLzRGiT5WBCzfvQpEc_ezWdVjueDSCweQ8YJ8reTsoLLXIqansafFS'
  }
}


// BE CAREFUL WITH THESE
// please don't touch unless you're sure you know what you're doing
module.exports.cartConfig = {
  testMode: false, // false for production
  skipAuthnet: false, // false for production
  skipGP: false // false for production
}

// if(module.hot) module.hot.accept()
