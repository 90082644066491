import React from 'react';
import { Snip, getGlobal, setGlobal, Input, Modal, DataStore, CheckBox,
  apiGet, apiPost, confirm, Paginate, ItemForm, notify, deleteDoc } from 'launchpad';
import Page from '_shared/layout-page/Page';
import { NavLink } from 'react-router-dom';
import Hero from '_shared/partial-hero/Hero';
import {history} from 'app';
import {confirmDelete} from '_helpers';
import styled from 'styled-components';
import { refreshRoutes } from 'config/routes'
import * as _helpers from '_helpers'
import { getCategories } from 'product_tools'



const Container = styled.div`
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  h2 {
    margin-bottom: 40px;
    display: flex;
    button {
      margin-left: auto;
      font-size: 16px
      display: inline-flex;
      align-items: center;
    }
  }
  .page-list {
    width: 100%;
  }
  .tools {
    min-width: 70px;
    display: block;
    button {
      margin-right: 10px;
      background: none;
    }
  }

  .toggle {
    cursor: pointer;
  }
  .danger-button {
    color: #811;
  }
`

const EditContainer = styled.div``

export class EditForm extends React.Component {
  render = () => {
    const props = {
      schema: {
        name: 'text',
        category: 'options',
        subcategories: 'list',
        url: 'text',
        visible: 'boolean',
        searchable: 'boolean',
        DAFVisible: 'boolean'
      }
    }
    return <ItemForm {...props} {...this.props} />
  }
}


export default class ProductManager extends React.Component {
  state = {
    new: false,
    newSubcategories: []
  }

  componentDidMount() {
    if(!getGlobal('is_admin')) {
      window.location.href = '/';
    }
  }

  onUpdate = (oldState, newState) => {
    const cat = getCategories().find(x => x._id == newState.category)
    if(cat) this.setState({newSubcategories: cat.subcategories})
    return newState
  }

  submit = (update) => {
    apiPost('series', update)
    this.setState({edit: null, new: false})
    this.paginate.reload()
    refreshRoutes()
  }

  remove = s => {
    confirm('Are you sure you want to delete this Product Series?', () => {
      deleteDoc('series', s._id).then(data => {
        if(data && data.error){
          notify(data.error, {type: 'error'})
        } else {
          this.paginate.reload()
        }
      })
    })
  }

  deleteSeries = (c) => {
    confirm('Are you sure you want to delete this Product Series?', () => {
      setTimeout(()=>{
        confirm('This will erase all skus associated with this series, are you sure you want to continue?', () => {
          apiPost('admin/delete-series/'+c._id).then(data => {
            if(data.error) {
              notify(data.error, {type: 'error'})
            } else {
              notify('Series Deleted', {type: 'success'})
              refreshRoutes()
              this.paginate.reload()
            }
          })
        })
      }, 300)
    })
  }


  render(){
    const {edit} = this.state
    return <Page id='product-manager' className='padded'>
      <Container>
        <button className='btn' onClick={()=>this.setState({new: true})}><span className='material-icons'>add</span> Add Series</button>
        <Paginate ref={p => this.paginate = p} collection='series' columns={s => {
          const catSlug = s.category && s.category.url
          const categoryUrl = `/${s.category && (catSlug || s.category.name.toLowerCase().replace(/ /g, '-'))}`
          const seriesUrl = `${categoryUrl}/${s.url}`
          const visibleProps = {onClick: () => this.submit({_id: s._id, visible: !s.visible})}
          const searchableProps = {onClick: () => this.submit({_id: s._id, searchable: !s.searchable})}
          const searchable = s.category && s.searchable && s.category.searchable
          const categories = getCategories()
          return {
            name: s.name && s.category && (s.visible && s.category.visible ? <NavLink to={seriesUrl}>{s.name}</NavLink> : <span className='muted'>{s.name} (hidden)</span>),
            category: s.category && (
              s.category.visible
                ? <NavLink to={categoryUrl}>{s.category.name} {!searchable && '(unsearchable)'}</NavLink>
                : <span className='muted'>{s.category.name} (hidden) {!searchable && '(unsearchable)'}</span>
            ),
            url: s.url,
            skus: s.total_skus,
            subcategories: s.subcategories ? s.subcategories.join(', ') : '',
            visible: s.visible ? <span {...visibleProps} className='toggle fa fa-check' /> : <span {...visibleProps} className='toggle fa fa-ban' />,
            searchable: <span className={`${!searchable && 'muted'}`}>
              {s.searchable ? <span {...searchableProps} className='toggle fa fa-check' /> : <span {...searchableProps} className='toggle fa fa-ban' />}
            </span>,
            tools: <span id={s._id} key={s._id} className='tools'>
              <button className='material-icons' onClick={()=>this.setState({edit: s})}>edit</button>
              {s.total_skus == 0 && <button className='material-icons' onClick={()=>this.remove(s)}>delete</button>}
              {s.total_skus > 0 && <button className='material-icons danger-button' onClick={()=>this.deleteSeries(s)}>delete</button>}
            </span>
          }
        }} />
        <Modal open={edit} onClose={()=>this.setState({edit: null})}>
          <EditForm
            item={edit}
            selectOptions={{
              subcategories: edit && edit.category.subcategories.map(x => {return {label: x, value: x}}),
              category: edit && getCategories().map(x => {return {label: x.name, value: x._id}})
            }}
            onUpdate={this.onUpdate}
            overrideSubmit={this.submit} />
        </Modal>

        <Modal open={this.state.new} onClose={()=>this.setState({new: false})}>
          <EditForm
            resetOnSubmit
            selectOptions={{
              subcategories: this.state.newSubcategories.map(x => {return {label: x, value: x}}),
              category: getCategories().map(x => {return {label: x.name, value: x._id}})
            }}
            onUpdate={this.onUpdate}
            overrideSubmit={this.submit} />
        </Modal>
      </Container>
    </Page>
  }
}


// launchpad_title: Product Manager
// launchpad_url: admin/products
// launchpad_admin_panel: true
// launchpad_admin_group: product tools
// launchpad_type: admin
// launchpad_icon: shopping-cart
